import React, { Fragment, useEffect, useState, useRef } from "react";
import Styles from "./InfoModal.module.css";
import classnames from "classnames";
import channelIcon from "../../../../assets/images/icons/channelIcon.svg";
import userIcon from "../../../../assets/images/icons/user.svg";
import calenderIcon from "../../../../assets/images/icons/calenderIcon.svg";
import clockIcon from "../../../../assets/images/icons/clock.svg";
import priorityIcon from "../../../../assets/images/icons/interliningIcon.svg";
import categoryIcon from "../../../../assets/images/icons/categoryIcon.svg";
import infoIcon from "../../../../assets/images/icons/infoIcon.svg";
import commentIcon from "../../../../assets/images/icons/commentIcon.svg";
import defaultUserIcon from "../../../../assets/images/icons/defaultUserIcon.svg";
import sendCommentIcon from "../../../../assets/images/icons/sendComment.svg";
import uploadIcon from "../../../../assets/images/icons/upload_icon.svg";
import attachment from "../../../../assets/images/icons/attachmentIcon.svg";
import addIcon from "../../../../assets/images/icons/addIcon_plus.svg";
import optionsIcon from "../../../../assets/images/icons/panelOptionsIcon.svg";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import utils from "../../../../utils";
import deleteIcon from "../../../../assets/images/icons/trashIcon.svg";
import crossIcon from "../../../../assets/images/icons/crossIcon.svg";
import trashRedIcon from "../../../../assets/images/icons/trashRedIcon.svg";
import { addTaskAttachment } from "../../../../reducers/taskReducer";
import { addBugAttachment } from "../../../../reducers/bugReducer";
function InfoModal(props) {
  let infoData = props?.infoModalData?.value ? props.infoModalData.value : {};
  let dispatch = useDispatch();
  let [addCommentText, changeAddCommentText] = useState("");
  const scrollableRef = useRef(null);

  let addTaskCommentResult = useSelector(
    (state) => state.taskReducer.addComment
  );

  let [userSelectedAttachments, changeUserSelectedAttachments] = useState([]);

  useEffect(() => {
    scrollToBottom();
  }, [infoData]);

  useEffect(() => {
    if (userSelectedAttachments.length == 0) {
      document.getElementById("fileInputInfoBox").value = "";
    }
    fetchPreviewElements();
  }, [userSelectedAttachments]);

  useEffect(() => {
    document.getElementById("fileInputInfoBox").value = "";
    let parentScrollElement = document.getElementById("dashboardLayout");
    const handleScroll = () => {
      const element = document.getElementById("infoModalContainer");
      const elementOffsetTop = element && element.offsetTop;

      const windowScrollTop = parentScrollElement.scrollTop;

      element.style.marginTop = windowScrollTop + "px";
    };

    parentScrollElement.addEventListener("scroll", handleScroll);
    return () => {
      parentScrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e?.key && e.key == "Escape") {
        if (props.infoModalData.show) {
          props.changeInfoModalState();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  });

  function statusUI(status) {
    if (status) {
      status = status.toLowerCase();
      switch (status) {
        case "completed":
          return {
            textColor: "#0D7B4D",
            backgroundColor: "#DDFFE9",
          };
        case "pending":
          return {
            textColor: "#FF1E1E",
            backgroundColor: "#FFF5F5",
          };
      }
    }
  }

  function getPriorityTextInfo(priorityDigit) {
    if (priorityDigit) {
      switch (parseInt(priorityDigit)) {
        case 1:
          return { title: "low", color: "#00FF00" };
        case 2:
          return { title: "medium", color: "#FFD700" };
        case 3:
          return { title: "high", color: "#FF0000" };
      }
    }
  }

  function handleFileInput(e) {
    if (e.target.files.length > 0) {
      let fileArr = [...userSelectedAttachments];
      for (let i = 0; i < e.target.files.length; i++) {
        let isAlreadySelected = false;
        fileArr.forEach((fileSelectedOb) => {
          if (fileSelectedOb.name == e.target.files[i].name) {
            isAlreadySelected = true;
          }
        });
        if (!isAlreadySelected) {
          fileArr.push(e.target.files[i]);
        }
      }
      changeUserSelectedAttachments(fileArr);
    }
  }

  function fetchPreviewElements() {
    var imagePreview = document.getElementById("infoBoxAttachmentPreview");
    if (userSelectedAttachments.length > 0) {
      imagePreview.innerHTML = "";
      userSelectedAttachments.forEach((file) => {
        let fileReader = new FileReader();
        fileReader.onload = function (e) {
          var divSection = document.createElement("div");
          divSection.setAttribute("class", Styles.previewImageBox);

          var delIconDiv = document.createElement("div");
          delIconDiv.setAttribute("class", Styles.previewDeleteIconWrapper);
          delIconDiv.onclick = () => {
            removeAttachment(file);
          };

          var deleteImg = document.createElement("img");
          deleteImg.src = trashRedIcon;
          deleteImg.setAttribute("class", Styles.previewDeleteIcon);
          delIconDiv.append(deleteImg);

          var img = document.createElement("img");
          img.src = e.target.result;

          img.style.maxWidth = "100%";

          divSection.appendChild(img);
          divSection.appendChild(delIconDiv);
          // Clear previous previews and append the new image element
          imagePreview.appendChild(divSection);
        };

        // Read the file as a data URL
        fileReader.readAsDataURL(file);
      });
    } else {
      imagePreview.innerHTML = "";
    }
  }

  function removeAttachment(file) {
    if (file) {
      let newFileArr = [];
      userSelectedAttachments.forEach((attachedFiles) => {
        if (attachedFiles?.name != file?.name) {
          newFileArr.push(attachedFiles);
        }
      });

      changeUserSelectedAttachments(newFileArr);
    }
  }

  function addAttachment() {
    if (infoData?.id) {
      let info_id = infoData.id;

      if (
        userSelectedAttachments &&
        userSelectedAttachments.length > 0 &&
        info_id
      ) {
        let addOb = { file: userSelectedAttachments };
        if (props.type == "task") {
          addOb.task_id = info_id;
          dispatch(addTaskAttachment(addOb));
        } else {
          addOb.bug_id = info_id;
          dispatch(addBugAttachment(addOb));
        }
        document.getElementById("fileInputInfoBox").value = "";
        changeUserSelectedAttachments([]);
      }
    }
  }
  function getLoadingUi() {
    return (
      <Fragment>
        <div className={Styles.header}>
          <div
            style={{
              width: "2.5em",
              height: "2.5em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
          <div
            style={{
              width: "2.5em",
              height: "0em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
          <div
            style={{
              width: "2.5em",
              height: "1em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
          <div
            style={{
              width: "5.5em",
              height: "1em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
        </div>
        <div className={Styles.horizontalLine}></div>
        <div className={Styles.body}>
          <div className={Styles.infoBoxWrapper}>
            <div className={Styles.infoBox}>
              <img src={channelIcon} alt="channel" />
              <p>channel:</p>
              <div
                style={{
                  width: "2.5em",
                  height: "1em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
            <div className={Styles.infoBox}>
              <img
                src={userIcon}
                alt="user"
                style={{
                  width: "0.575em",
                }}
              />
              <p>created by:</p>
              <div
                style={{
                  width: "2.5em",
                  height: "1em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
            <div className={Styles.infoBox}>
              <img src={calenderIcon} alt="calender" />
              <p>assigned on:</p>
              <div
                style={{
                  width: "2.5em",
                  height: "1em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
            <div className={Styles.infoBox}>
              <img src={clockIcon} alt="clock" />
              <p>due date:</p>
              <div
                style={{
                  width: "2.5em",
                  height: "1em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
            <div className={Styles.infoBox}>
              <img src={priorityIcon} alt="priority" />

              <p>priority:</p>
              <div
                style={{
                  width: "2.5em",
                  height: "1em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
            <div className={Styles.infoBox}>
              <img src={categoryIcon} alt="category" />
              <p>category:</p>
              <div
                style={{
                  width: "2.5em",
                  height: "1em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
          </div>
          <div className={Styles.section}>
            <div className={Styles.sectionColumn}>
              <div className={Styles.sectionInfoBox}>
                <div className={Styles.sectionInfoBoxHeader}>
                  <img src={infoIcon} alt="desc" />
                  <p>description</p>
                </div>
                <div className={Styles.horizontalLine}></div>
                <div className={Styles.sectionInfoBoxBody}>
                  <div
                    style={{
                      width: "7.5em",
                      height: "1em",
                      borderRadius: "20em",
                      background: "var(--lightGrey)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className={Styles.sectionColumn}>
              <div className={Styles.sectionInfoBox}>
                <div className={Styles.sectionInfoBoxHeader}>
                  <img src={commentIcon} alt="desc" />
                  <p>comments</p>
                </div>
                <div className={Styles.horizontalLine}></div>
                <div
                  className={classnames(
                    Styles.sectionInfoBoxBody,
                    Styles.commentInfoBoxBody
                  )}
                >
                  <div
                    style={{
                      width: "10.5em",
                      height: "1em",

                      borderRadius: "20em",
                      background: "var(--lightGrey)",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "10.5em",
                      height: "1em",
                      borderRadius: "20em",
                      marginBottom: "1em",
                      marginTop: "1em",
                      background: "var(--lightGrey)",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "10.5em",
                      height: "1em",
                      borderRadius: "20em",
                      background: "var(--lightGrey)",
                    }}
                  ></div>
                </div>
                <div className={Styles.addCommentBox}>
                  <input
                    type="text"
                    placeholder="Add new comment"
                    className={Styles.addNewCommentTextBox}
                  />
                  <img src={sendCommentIcon} alt="send" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  const scrollToBottom = () => {
    if (scrollableRef.current) {
      const element = scrollableRef.current;
      element.scrollTop = element.scrollHeight;
    }
  };

  return (
    <div
      className={Styles.mainContainer}
      style={{ visibility: props.show ? "visible" : "hidden" }}
      id="infoModalContainer"
      onClick={(e) => {
        if (e.target.id == "infoModalContainer") {
          props.changeInfoModalState(false);
        }
      }}
    >
      <div
        className={classnames(
          Styles.container,
          props.show ? Styles.showContainer : ""
        )}
      >
        {props.isTaskInfoLoading || props.isBugInfoLoading ? (
          getLoadingUi()
        ) : (
          <Fragment>
            <div className={Styles.header}>
              <img
                src={defaultUserIcon}
                className={Styles.assignorAvater}
                alt="img"
              />
              <p className={Styles.id}>{infoData?.id ?? ""}</p>
              <p
                className={Styles.infoText}
                dangerouslySetInnerHTML={{
                  __html:
                    props.type == "task"
                      ? utils.processText(infoData?.task_text, {
                          getMemberObFromUserid: props.getMemberObFromUserid,
                        }) ?? ""
                      : utils.linkify(infoData?.bug_text, {
                          getMemberObFromUserid: props.getMemberObFromUserid,
                        }) ??
                        "" ??
                        "",
                }}
              ></p>
              <div
                style={{
                  width: "4.375em",
                  padding: "0.375em 0",
                  backgroundColor: statusUI(
                    props.type == "task"
                      ? infoData?.task_status
                      : infoData?.bug_status
                  )?.backgroundColor,
                  borderRadius: "0.625em",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontSize: "0.625rem",
                    fontWeight: "700",
                    color: statusUI(
                      props.type == "task"
                        ? infoData?.task_status
                        : infoData?.bug_status
                    )?.textColor,
                    textTransform: "capitalize",
                  }}
                >
                  {props.type == "task"
                    ? infoData?.task_status
                    : infoData?.bug_status}
                </p>
              </div>
              {props.assigneeUI(infoData ?? {}, "infoModal")}
              {props.tagUI(infoData ?? {}, "infoModal")}
              {props.optionsUI(infoData ?? {}, "infoModal")}
            </div>
            <div className={Styles.horizontalLine}></div>
            <div className={Styles.body}>
              <div className={Styles.infoBoxWrapper}>
                <div className={Styles.infoBox}>
                  <img src={channelIcon} alt="channel" />
                  <p>channel:</p>
                  <p>
                    {props.getChannelNameFromId(infoData?.channel_id) ??
                      "Unassigned"}
                  </p>
                </div>
                <div className={Styles.infoBox}>
                  <img
                    src={userIcon}
                    alt="user"
                    style={{
                      width: "0.575em",
                    }}
                  />
                  <p>created by:</p>
                  <p>{infoData?.created_by?.name}</p>
                </div>
                <div className={Styles.infoBox}>
                  <img src={calenderIcon} alt="calender" />
                  <p>assigned on:</p>
                  <p>
                    {infoData?.created_at
                      ? moment(infoData.created_at).format("MMMM DD")
                      : "Unassigned"}
                  </p>
                </div>
                <div className={Styles.infoBox}>
                  <img src={clockIcon} alt="clock" />
                  <p>due date:</p>
                  <p>
                    {infoData?.due_date
                      ? moment(infoData.due_date).format("MMMM DD")
                      : "Unassigned"}
                  </p>
                </div>
                <div className={Styles.infoBox}>
                  <img src={priorityIcon} alt="priority" />

                  <p>priority:</p>
                  {infoData?.priority ? (
                    <p
                      style={{
                        color: getPriorityTextInfo(infoData.priority).color,
                      }}
                    >
                      {getPriorityTextInfo(infoData.priority).title}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className={Styles.infoBox}>
                  <img src={categoryIcon} alt="category" />
                  <p>category:</p>
                  {infoData?.category_id ? (
                    <p>{props.getCategoryNameFromId(infoData.category_id)}</p>
                  ) : (
                    <p> unassigned</p>
                  )}
                </div> */}
              </div>
              <div className={Styles.section}>
                <div className={Styles.sectionColumn}>
                  <div className={Styles.sectionInfoBox}>
                    <div className={Styles.sectionInfoBoxHeader}>
                      <img src={infoIcon} alt="desc" />
                      <p>description</p>
                    </div>
                    <div className={Styles.horizontalLine}></div>
                    <div className={Styles.sectionInfoBoxBody}>
                      <textarea
                        rows={10}
                        placeholder={
                          (props.type == "task"
                            ? infoData?.task_desc
                            : infoData?.bug_desc) ??
                          "Add a more detailed description..."
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className={Styles.sectionColumn}>
                  <div className={Styles.sectionInfoBox}>
                    <div className={Styles.sectionInfoBoxHeader}>
                      <img src={commentIcon} alt="desc" />
                      <p>
                        comments{" "}
                        <span style={{ color: "var(--black)" }}>
                          (
                          {infoData?.comments?.length
                            ? infoData.comments.length
                            : 0}
                          )
                        </span>
                      </p>
                    </div>
                    <div className={Styles.horizontalLine}></div>
                    <div
                      className={classnames(
                        Styles.sectionInfoBoxBody,
                        Styles.commentInfoBoxBody
                      )}
                      ref={scrollableRef}
                    >
                      {infoData?.comments?.map((commentData, index) => {
                        return (
                          <div className={Styles.commentBox}>
                            <img src={defaultUserIcon} alt="user" />
                            <p className={Styles.userName}>
                              {commentData?.user?.name
                                ? commentData?.user?.name
                                : "unnamed"}
                              :
                            </p>
                            <p
                              className={Styles.commentText}
                              style={{ width: "70%" }}
                            >
                              {commentData?.text}
                            </p>
                            <img
                              src={deleteIcon}
                              alt="remove"
                              onClick={() => {
                                props.removeComment(
                                  infoData.id,
                                  commentData.id
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className={Styles.addCommentBox}>
                      <input
                        type="text"
                        placeholder="Add new comment"
                        value={addCommentText}
                        onChange={(e) => {
                          changeAddCommentText(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            if (addCommentText) {
                              props.addComment(infoData.id, addCommentText);
                              changeAddCommentText("");
                            }
                          }
                        }}
                        className={Styles.addNewCommentTextBox}
                      />
                      <img
                        src={sendCommentIcon}
                        alt="send"
                        onClick={() => {
                          if (addCommentText) {
                            props.addComment(infoData.id, addCommentText);
                            changeAddCommentText("");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className={Styles.sectionInfoBox}>
                    <div className={Styles.sectionInfoBoxHeader}>
                      <img src={attachment} alt="desc" />
                      <p>
                        attachments{" "}
                        <span style={{ color: "var(--black)" }}>
                          (
                          {infoData?.attachments
                            ? infoData.attachments.length
                            : 0}
                          )
                        </span>
                      </p>
                    </div>
                    <div className={Styles.horizontalLine}></div>
                    <div className={Styles.sectionInfoBoxBody}>
                      {infoData?.attachments &&
                        infoData.attachments.map((attachmentOb, index) => {
                          let attachmentInfo = attachmentOb.users;
                          return (
                            <div className={Styles.attachmentBox}>
                              <div className={Styles.attachmentInfo}>
                                <img
                                  src={
                                    attachmentInfo?.avatar ?? defaultUserIcon
                                  }
                                  alt="user"
                                />
                                <p className={Styles.userName}>
                                  {attachmentInfo?.name ?? "User"}:{" "}
                                </p>
                                <p className={Styles.attachmentText}>
                                  {attachmentInfo?.files &&
                                    attachmentInfo.files.map((fileOb, id) => {
                                      return `${
                                        fileOb.filename
                                      }(${utils.convertUnit(fileOb.size)}),`;
                                    })}
                                </p>
                              </div>
                              <div className={Styles.attachmentImagesBox}>
                                {attachmentInfo?.files &&
                                  attachmentInfo.files.map((fileOb, id) => {
                                    return (
                                      <img
                                        src={fileOb?.url ?? ""}
                                        alt="attachment"
                                      />
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                    <div className={Styles.addAttachmentBox}>
                      <div className={Styles.addAttachmentButtonWrapper}>
                        <div
                          onClick={() => {
                            document
                              .getElementById("fileInputInfoBox")
                              ?.click();
                          }}
                          className={Styles.addAttachmentButton}
                        >
                          <img src={addIcon} alt="add" />
                          <p>Add New</p>
                        </div>

                        <input
                          type="file"
                          id="fileInputInfoBox"
                          onChange={handleFileInput}
                          style={{ display: "none" }}
                          multiple
                          accept=".jpg, .jpeg, .png, .mp4, .mp3, .csv, .docx, .txt, .pdf, .svg, .gif"
                        />
                        <img
                          src={uploadIcon}
                          alt="send"
                          className={Styles.sendAttachmentIcon}
                          onClick={addAttachment}
                        />
                      </div>
                      <div
                        className={Styles.attachmentPreview}
                        id="infoBoxAttachmentPreview"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default InfoModal;
