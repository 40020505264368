import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let uptimeApi = {};

uptimeApi.getDowntimeList = (limit) => {
  const formData = new FormData();
  formData.append("limit", limit);

  return axios.post(`${config.url}/webmaster/downtime-list`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

uptimeApi.getDowntimeListGraph = (id, chartTime) => {
  const formData = new FormData();
  formData.append("domain_id", id);
  formData.append("chartTime", chartTime);
  return axios.post(`${config.url}/webmaster/downtime-graph`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

uptimeApi.getDowntime = (domain) => {
  const formData = new FormData();
  formData.append("domain", domain);
  return axios.post(`${config.url}/webmaster/downtime`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

uptimeApi.removeDowntime = (domain_id) => {
  const formData = new FormData();
  formData.append("domain_id", domain_id);
  return axios.post(`${config.url}/webmaster/downtime-remove`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

uptimeApi.saveDowntime = (domain) => {
  const formData = new FormData();
  formData.append("domain", domain);
  formData.append("tag", "Cloud");
  return axios.post(`${config.url}/webmaster/downtime-store`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};
export default uptimeApi;
