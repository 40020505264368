import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import loginApi from "../api/loginApi";

let initialState = {
  loggingIn: false,
  loginData: null,
  loginError: null,
  sendMagicPinLoading: false,
  sendMagicPinError: null,
  sendMagicPinData: null,
  confirmMagicPinLoading: false,
  confirmMagicPinError: null,
  confirmMagicPinData: null,
  slackRedirect: null,
  slackRedirectLoading: false,
  slackAuth: null,
  slackAuthLoading: false,
  slackSignin: null,
  slackSigninLoading: false,
  slackConnectLoading: false,
  slackConnectError: null,
  slackConnect: null,
  slackConnectConfirmation: null,
  slackConnectConfirmationError: null,
};

export const loginUsingEmailPass = createAsyncThunk(
  "auth/loginUsingEmailPass",
  async (authOb, { rejectWithValue }) => {
    const response = await loginApi.loginUsingEmailPass(
      authOb.email,
      authOb.password
    );
    if (response.data.status == "success") {
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const sendMagicPin = createAsyncThunk(
  "auth/sendMagicPin",
  async (email, { rejectWithValue }) => {
    const response = await loginApi.sendMagicPin(email);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const confirmMagicPin = createAsyncThunk(
  "auth/confirmMagicPin",
  async (pinOb, { rejectWithValue }) => {
    const response = await loginApi.confirmMagicPin(
      pinOb.confirmCode,
      pinOb.pin
    );
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const slackRedirect = createAsyncThunk(
  "auth/slack_redirect",
  async (data = {}, { rejectWithValue }) => {
    const response = await loginApi.slackRedirect();
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const slackAuthPoll = createAsyncThunk(
  "auth/slackAuthPoll",
  async (code, { rejectWithValue }) => {
    const response = await loginApi.slackAuthPoll(code);
    return response.data;
  }
);

export const signInUsingSlack = createAsyncThunk(
  "auth/signInUsingSlack",
  async (code, { rejectWithValue }) => {
    const response = await loginApi.signInUsingSlack(code);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const slackConnect = createAsyncThunk(
  "auth/slackConnect",
  async (data = {}, { rejectWithValue }) => {
    const response = await loginApi.slackConnect();
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const slackConnectConfirm = createAsyncThunk(
  "auth/slackConnectConfirm",
  async ({ code, state, error }, { rejectWithValue }) => {
    const response = await loginApi.slackConnectConfirmation({
      code,
      state,
      error,
    });
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const loginSlice = createSlice({
  name: "auth",
  initialState,

  extraReducers: (builder) => {
    //Login using email/pass
    builder.addCase(loginUsingEmailPass.pending, (state, action) => {
      state.loginError = null;
      state.loggingIn = true;
    });
    builder.addCase(loginUsingEmailPass.rejected, (state, action) => {
      state.loginError = action.payload.error;
      state.loggingIn = false;
    });
    builder.addCase(loginUsingEmailPass.fulfilled, (state, action) => {
      state.loggingIn = false;
      state.loginData = action.payload;
    });

    //send magic pin
    builder.addCase(sendMagicPin.pending, (state, action) => {
      state.sendMagicPinError = null;
      state.sendMagicPinLoading = true;
      state.sendMagicPinData = null;
      state.confirmMagicPinError = null;
    });
    builder.addCase(sendMagicPin.rejected, (state, action) => {
      state.sendMagicPinError = action.payload.error;
      state.sendMagicPinLoading = false;
      state.sendMagicPinData = null;
    });
    builder.addCase(sendMagicPin.fulfilled, (state, action) => {
      state.sendMagicPinLoading = false;
      state.sendMagicPinData = action.payload;
      state.sendMagicPinError = null;
    });

    //confirm magic pin
    builder.addCase(confirmMagicPin.pending, (state, action) => {
      state.confirmMagicPinError = null;
      state.confirmMagicPinLoading = true;
      state.loginData = null;
    });

    builder.addCase(confirmMagicPin.rejected, (state, action) => {
      state.confirmMagicPinError = action.payload.error;
      state.confirmMagicPinLoading = false;
      state.loginData = null;
    });
    builder.addCase(confirmMagicPin.fulfilled, (state, action) => {
      state.confirmMagicPinLoading = false;
      state.loginData = action.payload;
      state.confirmMagicPinError = null;
    });

    //slack Redirect
    builder.addCase(slackRedirect.pending, (state, action) => {
      state.loginError = null;
      state.slackRedirectLoading = true;
      state.slackRedirect = null;
    });

    builder.addCase(slackRedirect.rejected, (state, action) => {
      state.loginError = action.payload.error;
      state.slackRedirectLoading = false;
      state.slackRedirect = null;
    });
    builder.addCase(slackRedirect.fulfilled, (state, action) => {
      state.slackRedirectLoading = false;
      state.slackRedirect = action.payload;
      state.loginError = null;
    });

    builder.addCase(slackAuthPoll.pending, (state, action) => {
      state.loginError = null;
      state.slackAuthLoading = true;
      state.slackAuth = null;
    });

    builder.addCase(slackAuthPoll.rejected, (state, action) => {
      state.loginError = action.payload.error;
      state.slackAuthLoading = false;
      state.slackAuth = null;
    });
    builder.addCase(slackAuthPoll.fulfilled, (state, action) => {
      state.slackAuthLoading = false;
      state.slackAuth = action.payload;
      state.loginError = null;
    });
    builder.addCase(signInUsingSlack.pending, (state, action) => {
      state.loginError = null;
      state.slackSigninLoading = true;
      state.slackSignin = null;
    });

    builder.addCase(signInUsingSlack.rejected, (state, action) => {
      state.loginError = action.payload?.error;
      state.slackSigninLoading = false;
      state.slackSignin = null;
    });
    builder.addCase(signInUsingSlack.fulfilled, (state, action) => {
      state.slackSigninLoading = false;
      state.slackSignin = action.payload;
      state.loginError = null;
    });

    builder.addCase(slackConnect.pending, (state, action) => {
      state.slackConnectError = null;
      state.slackConnectLoading = true;
      state.slackConnect = null;
    });

    builder.addCase(slackConnect.rejected, (state, action) => {
      state.slackConnectError = action.payload.error;
      state.slackConnectLoading = false;
      state.slackConnect = null;
    });
    builder.addCase(slackConnect.fulfilled, (state, action) => {
      state.slackConnectLoading = false;
      state.slackConnect = action.payload;
      state.slackConnectError = null;
    });

    builder.addCase(slackConnectConfirm.pending, (state, action) => {
      state.slackConnectConfirmationError = null;
      state.slackConnectConfirmation = null;
    });

    builder.addCase(slackConnectConfirm.rejected, (state, action) => {
      state.slackConnectConfirmationError = action.payload?.error;

      state.slackConnectConfirmation = null;
    });
    builder.addCase(slackConnectConfirm.fulfilled, (state, action) => {
      state.slackConnectConfirmation = action.payload;
      state.slackConnectConfirmationError = null;
    });
  },
});

export const { login } = loginSlice.actions;
export default loginSlice.reducer;
