import React, { useState } from "react";
import Styles from "./Input.module.css";
import classNames from "classnames";
import visiblePasswordIcon from "../../assets/images/icons/visiblePassword_icon.svg";
import hiddenPasswordIcon from "../../assets/images/icons/hiddenPassword_icon.svg";
function Input(props) {
  let [showPassword, changeShowPassword] = useState(false);

  function render() {
    if (props.type == "password") {
      return (
        <div className={Styles.passwordFieldContainer}>
          <input
            onChange={props.handler}
            id={props.id}
            type={showPassword ? "text" : "password"}
            className={classNames(
              Styles.input,
              props.error == true ? Styles.inputError : ""
            )}
            placeholder={props.placeholder}
          />
          {showPassword ? (
            <img
              src={visiblePasswordIcon}
              alt="password"
              className={Styles.passwordDisplayElement}
              onClick={(e) => {
                changeShowPassword(false);
              }}
            />
          ) : (
            <img
              src={hiddenPasswordIcon}
              alt="password"
              className={Styles.passwordDisplayElement}
              onClick={(e) => {
                changeShowPassword(true);
              }}
            />
          )}
        </div>
      );
    } else {
      return (
        <input
          onChange={props.handler}
          onKeyPress={props.keyPressHandler ?? null}
          id={props.id}
          type={props.type}
          className={classNames(
            Styles.input,
            props.error == true ? Styles.inputError : ""
          )}
          placeholder={props.placeholder}
        />
      );
    }
  }

  return render();
}

export default Input;
