import React, { useEffect } from "react";
import Styles from "./Layout.module.css";
import BgEllipse from "../common/BgEllipse";
import MainHeader from "../common/MainHeader";
import Login from "./Login";
import AuthRedirect from './AuthRedirect'
import SigningIn from "./SigningIn";
import SlackConnect from "./SlackConnect/SlackConnect";
import WelcomePage from "./WelcomePage/WelcomePage";
import Launch from "./LaunchPage/Launch";
import bg_big_logo from "../../assets/images/bg_images/bg_big_logo.svg";
import { useLocation } from "react-router-dom";
import utils from "../../utils";
function Layout(props) {
  let location = useLocation();
  useEffect(() => {
    if (location?.pathname)
      document.title = utils.capitalize(location.pathname.split("/")[1]);
  }, [location.pathname]);

  function renderUi() {
    switch (props.page) {
      case "login":
        return <Login />;
        break;
      case "welcome":
        return <WelcomePage />;
        break;
      case "connect":
        return <SlackConnect />;
        break;
      case "launch":
        return <Launch />;
        break;
        case "authRedirect":
          return <AuthRedirect />;
          break;
      default:
        return <Login />;
    }
  }
  return (
    <div className={Styles.layoutContainer}>
      <MainHeader activePage={props.page} />
      <BgEllipse />
      <img
        src={bg_big_logo}
        alt="big_big_logo"
        className={Styles.layoutContainer__bigLogo}
      />
      <div className={Styles.layoutMain}>{renderUi()}</div>
    </div>
  );
}

export default Layout;
