import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let teamApi = {};

teamApi.getMembers = () => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("showBio", 1);
  formData.append("showOnlineStatus", 1);
  return axios.post(`${config.url}/teams/members`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

teamApi.getChannel = () => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/teams/channel-list`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

teamApi.getTeamList = () => {
  return axios.get(`${config.url}/teams/list`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

teamApi.getTeams = () => {
  return axios.get(`${config.url}/teams/available`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

teamApi.connectTeam = (teamId) => {
  const formData = new FormData();

  formData.append("team_id", teamId);
  return axios.post(`${config.url}/teams/connect`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

teamApi.disconnectTeam = (teamId) => {
  const formData = new FormData();

  formData.append("team_id", teamId);
  return axios.post(`${config.url}/teams/disconnect`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

teamApi.createDefaultTeam = (teamId) => {
  return axios.get(`${config.url}/teams/create-default-team`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

export default teamApi;
