import React, { useEffect, useState } from "react";
import Styles from "./Launch.module.css";
import rocket from "../../../assets/images/gifs/rocket.gif";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../reducers/profileReducer";
import { createDefaultTeam } from "../../../reducers/teamReducer";
import { useNavigate } from "react-router-dom";
import utils from "../../../utils";
import AlertBox1 from "../../common/AlertBox_1/AlertBox_1";
function Launch(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [alertState, changeAlertState] = useState({
    isVisible: false,
    message: "",
    type: "success",
  });
  const profileDataError = useSelector((state) => {
    return state.profileReducer.profileDataError;
  });

  const profileData = useSelector((state) => {
    return state.profileReducer.profileData;
  });

  const createNewTeamResult = useSelector((state) => {
    return state.teamReducer.createDefaultTeam;
  });

  useEffect(() => {
    if (createNewTeamResult?.team_id) {
      utils.setVariable("current_team", createNewTeamResult.team_id);
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    }
  }, [createNewTeamResult]);

  useEffect(() => {
    if (profileData) {
      if (profileData.teams.length > 0) {
        //TODO: Setting first team in array as current team for now.
        utils.setVariable("current_team", profileData.teams[0].team_id);
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      } else {
        dispatch(createDefaultTeam());
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (profileDataError && profileDataError.error) {
      if (profileDataError.error == "Invalid access_token") {
        utils.removeToken();
        navigate("/login");
      } else {
        changeAlertState({
          isVisible: true,
          message: "Something went wrong.Try again.",
        });
      }
    }
  }, [profileDataError]);

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  return (
    <div className={Styles.launchContainer}>
      <AlertBox1
        isVisible={alertState.isVisible}
        changeAlertState={changeAlertState}
        message={alertState.message}
        type={alertState.type}
      />
      <p className={Styles.launchText}>Launching your Magic Wand!</p>
      <img src={rocket} alt="launching" className={Styles.launchingGif} />
    </div>
  );
}

export default Launch;
