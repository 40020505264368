import React, { useEffect, useState, useContext } from "react";
import Styles from "./Profile.module.css";
import defaultUserIcon from "../../../../assets/images/icons/defaultUserIcon.svg";
import crossIcon from "../../../../assets/images/icons/crossIcon.svg";
import ToggleButton from "../../../Elements/ToggleButton";
import FormButton from "../../../Elements/FormButton";
import defaultWorkspaceIcon from "../../../../assets/images/icons/workspaceIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import utils from "../../../../utils";
import classnames from "classnames";
import Modal from "../../../common/Modal/Modal";
import { AlertContext } from "../../Layout";
import { getProfile, updateProfile } from "../../../../reducers/profileReducer";
import ErrorContainer from "../../../common/ErrorContainer";
function Profile(props) {
  let alert = useContext(AlertContext);
  let [twoFaToggle, changeTwoFaToggle] = useState(false);
  let [darkModeToggle, changeDarkModeToggle] = useState(false);
  let [userSkills, changeUserSkills] = useState([]);
  let [passwordChangeAlert, updatePassChangeAlert] = useState(null);
  let profileData = useSelector((state) => state.profileReducer.profileData);
  let profileError = useSelector(
    (state) => state.profileReducer.profileDataError
  );

  let updateProfileResult = useSelector(
    (state) => state.profileReducer.updateProfile
  );
  let updateProfileLoading = useSelector(
    (state) => state.profileReducer.updateProfileLoading
  );

  let [modalState, changeModalState] = useState({
    isActive: false,
    ui: null,
  });

  let [role, changeRole] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    if (profileError) {
      alert?.showAlert(profileError.error, "danger");
    }
  }, [profileError]);

  useEffect(() => {
    if (profileData) {
      changeUserSkills(profileData?.skills);
      let roleOb = profileData?.teams.map((teamData) => {
        return {
          teamId: teamData?.team_id ?? null,
          teamIcon: teamData?.icon_url ?? defaultWorkspaceIcon,
          role: teamData?.team_role,
        };
      });
      changeRole(roleOb);
    }
  }, [profileData]);

  useEffect(() => {
    if (updateProfileResult) {
      dispatch(getProfile());
      alert?.showAlert("Profile successully updated", "success");
    }
  }, [updateProfileResult]);

  function showModal(ui) {
    changeModalState({
      isActive: true,
      ui,
    });
  }

  function hideModal() {
    changeModalState({
      isActive: false,
      ui: null,
    });
  }

  let teamOb = utils.fetchTeamDataFromArray(
    profileData?.teams ?? [],
    utils.fetchVariable("current_team")
  );

  function getHeightOfParentElement() {
    let element = document.getElementById("settingsBody");
    let height = element?.style?.height;
    if (height) {
      height = parseInt(height.split("px")[0]);
    }
    return height;
  }

  function addSkill() {
    let userSkillsString = document.getElementById("userInput_skills")?.value;
    let skillsArr = [...userSkills, ...userSkillsString.split("\n")];
    skillsArr = Array.from(new Set(skillsArr));
    changeUserSkills(skillsArr);
    document.getElementById("userInput_skills").value = "";
    hideModal();
  }

  function removeSkill(skillToRemove) {
    let newSkillArr = userSkills.filter((skill) => {
      if (skill != skillToRemove) {
        return true;
      } else {
        return false;
      }
    });
    changeUserSkills(newSkillArr);
  }
  function showFormAlert(error) {
    updatePassChangeAlert(error);
    setTimeout(() => {
      updatePassChangeAlert("");
    }, 3000);
  }
  function addSkillUI() {
    return (
      <div className={Styles.addSkillContainer}>
        <p>Add Skills</p>
        <textarea
          id="userInput_skills"
          rows="5"
          placeholder={"Enter your skills one per line"}
        ></textarea>
        <FormButton
          type="style1"
          styles={{ padding: "0.5em" }}
          handler={() => {
            addSkill();
          }}
        >
          Submit
        </FormButton>
      </div>
    );
  }

  function changePassword() {
    let oldPassword = document.getElementById("userInput_oldPassword")?.value;
    let newPassword = document.getElementById("userInput_newPassword")?.value;
    let confirmOldPassword = document.getElementById(
      "userInput_confirmNewPassword"
    )?.value;
    if (oldPassword && newPassword && confirmOldPassword) {
      if (newPassword == confirmOldPassword) {
        hideModal();
        dispatch(
          updateProfile([
            {
              key: "password",
              value: oldPassword,
            },
            {
              key: "newpassword",
              value: newPassword,
            },
          ])
        );
      } else {
        showFormAlert("New and confirm passwords are not same");
      }
    } else {
      showFormAlert("Invalid Values");
    }
  }

  function changePasswordUi() {
    return (
      <div className={Styles.changePasswordContainer}>
        <p>Reset Password</p>
        <div className={Styles.changePasswordInputWrapper}>
          <ErrorContainer
            type="style1"
            state={{ error: passwordChangeAlert }}
          />
          <input
            type="password"
            placeholder="Old Password"
            id="userInput_oldPassword"
          />
          <input
            type="password"
            placeholder="New Password"
            id="userInput_newPassword"
          />
          <input
            type="password"
            placeholder="Confirm New Password"
            id="userInput_confirmNewPassword"
          />
        </div>
        <FormButton
          type="style1"
          styles={{ padding: "0.5em" }}
          handler={() => {
            changePassword();
          }}
        >
          Update
        </FormButton>
      </div>
    );
  }

  function saveChanges() {
    let name = document.getElementById("userInput_name")?.value;
    let phone = document.getElementById("userInput_phone")?.value;
    let about = document.getElementById("userInput_about")?.value;
    let skills = userSkills.join(",");
    let dataToUpdate = [];
    if (name) {
      dataToUpdate.push({ key: "name", value: name });
    }
    if (phone) {
      dataToUpdate.push({ key: "phone", value: phone });
    }
    if (about) {
      dataToUpdate.push({ key: "aboutme", value: about });
    }
    if (skills) {
      dataToUpdate.push({ key: "skills", value: skills });
    }

    if (dataToUpdate.length > 0) {
      dispatch(updateProfile(dataToUpdate));
    }
  }

  return (
    <div className={Styles.profileContainer}>
      <Modal
        show={modalState.isActive}
        parentId="settingsBody"
        changeModalState={hideModal}
        // styles={{
        //   marginTop: getHeightOfParentElement()
        //     ? getHeightOfParentElement() / 3
        //     : 0,
        // }}
      >
        {modalState.ui == "addSkill" ? addSkillUI() : ""}
        {modalState.ui == "password" ? changePasswordUi() : ""}
      </Modal>
      <p
        className={Styles.greyText1}
        style={{ gridColumn: "2", textAlign: "left" }}
      >
        This is what your coworkers see about you
      </p>
      <div
        className={Styles.userImageBox}
        style={{ gridColumn: "1", gridRow: "2" }}
      >
        <img src={teamOb?.avatar ?? defaultUserIcon} alt="user_icon" />
        <p>change</p>
      </div>
      <div
        className={Styles.infoContainer}
        style={{ gridColumn: "2", textAlign: "left" }}
      >
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>full name</p>
          <input
            type={"text"}
            name="name"
            defaultValue={profileData?.name}
            id="userInput_name"
          />
        </div>
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>email address</p>
          <input
            type={"text"}
            name="email"
            value={profileData?.email}
            id="userInput_email"
          />
        </div>
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>phone number</p>
          <input
            type={"text"}
            name="phone"
            defaultValue={profileData?.phone}
            id="userInput_phone"
          />
        </div>
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>role</p>
          {role.map((roleOb) => {
            return (
              <div className={Styles.roleInputWrapper}>
                <img src={roleOb.teamIcon} alt="team" />
                <input type={"text"} name="role" defaultValue={roleOb.role} />
              </div>
            );
          })}
        </div>
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>about me</p>
          <input
            type={"text"}
            name="aboutMe"
            defaultValue={profileData?.about_me ?? ""}
            id="userInput_about"
          />
        </div>
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>skills</p>
          <div className={Styles.skillWrapper}>
            {userSkills.map((skillOb, id) => {
              return (
                <div className={Styles.skillInfo}>
                  <p>{skillOb}</p>
                  <img
                    src={crossIcon}
                    alt="cross"
                    onClick={() => {
                      removeSkill(skillOb);
                    }}
                  />
                </div>
              );
            })}
            <div
              onClick={() => {
                showModal("addSkill");
              }}
              className={classnames(Styles.skillInfo, Styles.addSkillButton)}
            >
              <p>Add Skill</p>
            </div>
          </div>
        </div>
        <p className={Styles.infoHeading}>security</p>
        <div className={Styles.infoBox}>
          <p className={Styles.heading}>password</p>
          <div style={{ position: "relative" }}>
            <input type={"password"} name="password" value="****************" />
            <p
              style={{
                position: "absolute",
                top: 10,
                right: "0em",
                margin: 0,
                color: "var(--grey)",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={() => {
                showModal("password");
              }}
            >
              change
            </p>
          </div>
        </div>
        {/* <div className={Styles.toggleButtonBox}>
          <div
            style={{ cursor: "pointer", width: "fit-content" }}
            onClick={() => {
              changeTwoFaToggle(!twoFaToggle);
            }}
          >
            <ToggleButton isActive={twoFaToggle} />
          </div>

          <div className={Styles.toggleButtoText}>
            <p>Two-Factor Authtentication(2FA)</p>
            <p>Ensure extra security for your account with 2FA</p>
          </div>
        </div> */}
        {/* <p className={Styles.infoHeading}>preferences</p>
        <div className={Styles.toggleButtonBox}>
          <div
            style={{ cursor: "pointer", width: "fit-content" }}
            onClick={() => {
              changeDarkModeToggle(!darkModeToggle);
            }}
          >
            <ToggleButton isActive={darkModeToggle} />
          </div>
          <div className={Styles.toggleButtoText}>
            <p>dark mode</p>
            <p>Activate to experience dark side of BOTZA</p>
          </div>
        </div> */}
        <div>
          {" "}
          <p className={Styles.greyText1}>Signed up on 30 August, 2021</p>
        </div>
      </div>
      <div>
        <FormButton
          handler={() => {
            saveChanges();
          }}
          type={"style1"}
          styles={{ width: "10em", fontSize: "1em" }}
        >
          Save changes
        </FormButton>
      </div>
    </div>
  );
}

export default Profile;
