import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import utils from "../../utils";
function PrivateRoute(props) {
  let location = useLocation();
  useEffect(() => {
    document.title = utils.capitalize(location.pathname.split("/")[1]);
  }, [location.pathname]);

  function checkAuthentication() {
    if (utils.fetchToken()) {
      return true;
    } else {
      return false;
    }
  }
  return checkAuthentication() ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute;
