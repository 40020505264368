import React, { useEffect, useState, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import BgEllipse from "../common/BgEllipse";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import Styles from "./Layout.module.css";
import Dashboard from "./Dashboard/Dashboard";
import Panel from "./Panel/Panel";
import Webmaster from "./Webmaster/Webmaster";
import Settings from "./Settings/Settings";
import { getProfile } from "../../reducers/profileReducer";
import utils from "../../utils";
import AlertBox1 from "../common/AlertBox_1/AlertBox_1";

export const AlertContext = createContext();
function Layout(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [alertBox, changeAlertBox] = useState({
    isVisible: false,
    type: "success",
    message: "",
  });
  let profileData = useSelector((state) => {
    return state.profileReducer.profileData;
  });

  function showAlert(message, type) {
    changeAlertBox({
      message,
      type,
      isVisible: true,
    });
  }

  //if there is not profile data in logged in components, fetch
  useEffect(() => {
    if (profileData == null) {
      dispatch(getProfile());
    } else {
      //TODO: Setting first team in array as current team for now.
      //if there is no current team variable then make one
      if (!utils.fetchVariable("current_team")) {
        utils.setVariable("current_team", profileData.teams[0].team_id);
      }
    }
  }, [profileData]);

  useEffect(() => {
    let element = document.getElementById("dashboardLayout");
    element.style.height =
      parseInt(window.innerHeight - element.offsetTop) - 10 + "px";

    let panel = document.getElementById("panelBody");
    if (panel)
      panel.style.height =
        parseInt(window.innerHeight - element.offsetTop) - 30 + "px";
  }, []);

  function renderUi() {
    switch (props.type) {
      case "dashboard":
        return <Dashboard />;
      case "task-panel":
        return <Panel type="task" />;
      case "bug-panel":
        return <Panel type="bug" />;
      case "webmaster":
        return <Webmaster />;
      case "settings":
        return <Settings />;
      default:
        return <Dashboard />;
    }
  }

  return (
    <div className={Styles.layoutContainer}>
      <AlertBox1
        isVisible={alertBox.isVisible}
        type={alertBox.type}
        message={alertBox.message}
        changeAlertState={changeAlertBox}
      />
      <AlertContext.Provider value={{ showAlert }}>
        {" "}
        <div className={Styles.bgContainer1}>
          <BgEllipse />
        </div>
        <div className={Styles.bgContainer2}>
          <BgEllipse />
        </div>
        <Sidebar />
        <div className={Styles.mainContainer}>
          <div className={Styles.headerContainer}>
            <Header type={props.type} />
          </div>
          <div
            className={Styles.dashboardBody}
            id="dashboardLayout"
            style={{
              backgroundColor:
                props.type == "webmaster" || props.type == "settings"
                  ? "transparent"
                  : "var(--mainBackground)",
            }}
          >
            {renderUi()}
          </div>
        </div>
      </AlertContext.Provider>
    </div>
  );
}

export default Layout;
