import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bugApi from "../api/bugApi";

let initialState = {
  graphData: [],
  graphDataLoading: false,
  graphDataError: null,
  bugPanelError: null,
  bugStats: null,
  bugStatsLoading: false,
  bugList: [],
  bugListLoading: false,
  bugCategoryLoading: false,
  bugCategory: null,
  addbug: null,
  addbugLoading: false,
  editBug: null,
  editBugLoading: {
    id: null,
  },
  BugInfo: null,
  BugInfoLoading: {
    id: null,
  },
  pinBug: null,
  pinBugLoading: {
    id: null,
  },
  detachBug: null,
  detachBugLoading: {
    id: null,
  },
  deleteBugLoading: {
    id: null,
  },
  deleteBug: null,
  addCommentLoading: {
    id: null,
  },
  addComment: null,
  removeCommentLoading: {
    id: null,
  },
  removeComment: null,
  addBugAttachmentLoading: false,
  addBugAttachment: null,
};

export const getBugGraphData = createAsyncThunk(
  "bug/graphData",
  async (chartTime = "week", { rejectWithValue }) => {
    const response = await bugApi.getGraphData(chartTime);

    if (response.data.status == "success") {
      return response.data.graph;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getBugStats = createAsyncThunk(
  "bug/stats",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.getStats();
    if (response.data.status == "success") {
      return response.data.stats;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);
export const getBugList = createAsyncThunk(
  "bug/list",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.getList(data);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getBugCategoryList = createAsyncThunk(
  "bug/category",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.getCategories();
    if (response.data.status == "success") {
      return response.data.categories;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getBugTagList = createAsyncThunk(
  "bug/tags",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.getTags();
    if (response.data.status == "success") {
      return response.data.tags;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const addBug = createAsyncThunk(
  "bug/addBug",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.addBug(data);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const editBug = createAsyncThunk(
  "bug/editBug",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.editBug(data);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getBugInfo = createAsyncThunk(
  "bug/bugInfo",
  async (data = null, { rejectWithValue }) => {
    const response = await bugApi.getList(data);
    if (response.data.status == "success") {
      return response.data.bugs;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const pinBug = createAsyncThunk(
  "bug/pinBug",
  async (bug_id = null, { rejectWithValue }) => {
    const response = await bugApi.pinBug(bug_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const detachBug = createAsyncThunk(
  "bug/detachBug",
  async (bug_id = null, { rejectWithValue }) => {
    const response = await bugApi.detachBug(bug_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const deleteBug = createAsyncThunk(
  "bug/deleteBug",
  async (bug_id = null, { rejectWithValue }) => {
    const response = await bugApi.deleteBug(bug_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const addBugComment = createAsyncThunk(
  "bug/addBugComment",
  async ({ bug_id = null, commentText = null }, { rejectWithValue }) => {
    const response = await bugApi.addComment(bug_id, commentText);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const removeBugComment = createAsyncThunk(
  "bug/removeBugComment",
  async ({ bug_id = null, comment_id = null }, { rejectWithValue }) => {
    const response = await bugApi.removeComment(bug_id, comment_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const addBugAttachment = createAsyncThunk(
  "task/addBugAttachment",
  async ({ bug_id = null, file = null }, { rejectWithValue }) => {
    const response = await bugApi.addAttachment(bug_id, file);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const bugSlice = createSlice({
  name: "bug",
  initialState,

  extraReducers: (builder) => {
    //get graph data
    builder.addCase(getBugGraphData.pending, (state, action) => {
      state.graphDataLoading = true;
      state.graphData = null;
      state.graphDataError = null;
    });
    builder.addCase(getBugGraphData.rejected, (state, action) => {
      state.graphDataLoading = false;
      state.graphData = null;

      if (action.error) {
        state.graphDataError = { error: action.error.message };
      } else {
        state.graphDataError = action.payload;
      }
    });
    builder.addCase(getBugGraphData.fulfilled, (state, action) => {
      state.graphDataLoading = false;
      state.graphData = action.payload;
      state.graphDataError = null;
    });

    //get stats
    builder.addCase(getBugStats.pending, (state, action) => {
      state.bugStatsLoading = true;
      state.bugStats = null;
      state.bugPanelError = null;
    });
    builder.addCase(getBugStats.rejected, (state, action) => {
      state.bugStatsLoading = false;
      state.bugStats = null;

      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(getBugStats.fulfilled, (state, action) => {
      state.bugStatsLoading = false;
      state.bugStats = action.payload;
      state.bugPanelError = null;
    });

    //get list
    builder.addCase(getBugList.pending, (state, action) => {
      state.bugListLoading = true;
      state.bugList = null;
      state.bugPanelError = null;
    });
    builder.addCase(getBugList.rejected, (state, action) => {
      state.bugListLoading = false;
      state.bugList = null;

      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(getBugList.fulfilled, (state, action) => {
      state.bugListLoading = false;
      state.bugList = action.payload;
      state.bugPanelError = null;
    });

    //get categories
    builder.addCase(getBugCategoryList.pending, (state, action) => {
      state.bugCategoryLoading = true;
      state.bugCategory = null;
      state.bugPanelError = null;
    });
    builder.addCase(getBugCategoryList.rejected, (state, action) => {
      state.bugCategoryLoading = false;
      state.bugCategory = null;

      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(getBugCategoryList.fulfilled, (state, action) => {
      state.bugCategoryLoading = false;
      state.bugCategory = action.payload;
      state.bugPanelError = null;
    });

    //get tags
    builder.addCase(getBugTagList.pending, (state, action) => {
      state.getTagsLoading = true;
      state.bugTags = null;
      state.bugPanelError = null;
    });
    builder.addCase(getBugTagList.rejected, (state, action) => {
      state.getTagsLoading = false;
      state.bugTags = null;

      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(getBugTagList.fulfilled, (state, action) => {
      state.getTagsLoading = false;
      state.bugTags = action.payload;
      state.bugPanelError = null;
    });

    //add bug
    builder.addCase(addBug.pending, (state, action) => {
      state.addBugLoading = true;
      state.addBug = null;
      state.bugPanelError = null;
    });
    builder.addCase(addBug.rejected, (state, action) => {
      state.addBugLoading = false;
      state.addBug = null;

      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(addBug.fulfilled, (state, action) => {
      state.addBugLoading = false;
      state.addBug = action.payload;
      state.bugPanelError = null;
    });

    //edit bug
    builder.addCase(editBug.pending, (state, action) => {
      state.editBugLoading = {
        id: action?.meta?.arg?.bug_id,
      };
      state.editBug = null;
      state.bugPanelError = null;
    });
    builder.addCase(editBug.rejected, (state, action) => {
      state.editBugLoading = {
        id: null,
      };
      state.editBug = null;

      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(editBug.fulfilled, (state, action) => {
      state.editBugLoading = {
        id: null,
      };
      state.editBug = { id: action?.meta?.arg?.bug_id, ...action.payload };
      state.bugPanelError = null;
    });

    //bug info
    builder.addCase(getBugInfo.pending, (state, action) => {
      state.bugInfoLoading = {
        id: action?.meta?.arg?.bug_id,
      };
      state.bugInfo = null;
      state.bugPanelError = null;
    });
    builder.addCase(getBugInfo.rejected, (state, action) => {
      state.bugInfoLoading = {
        id: null,
      };
      state.bugInfo = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(getBugInfo.fulfilled, (state, action) => {
      state.bugInfoLoading = {
        id: null,
      };
      state.bugInfo = action.payload;
      state.bugPanelError = null;
    });

    //pin bug
    builder.addCase(pinBug.pending, (state, action) => {
      state.pinBugLoading = {
        id: action?.meta?.arg,
      };
      state.pinBug = null;
      state.bugPanelError = null;
    });
    builder.addCase(pinBug.rejected, (state, action) => {
      state.pinBugLoading = {
        id: null,
      };
      state.pinBug = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(pinBug.fulfilled, (state, action) => {
      state.pinBugLoading = {
        id: null,
      };
      state.pinBug = { id: action?.meta?.arg, ...action.payload };
      state.bugPanelError = null;
    });

    //detach bug
    builder.addCase(detachBug.pending, (state, action) => {
      state.detachBugLoading = {
        id: action?.meta?.arg,
      };
      state.detachBug = null;
      state.bugPanelError = null;
    });
    builder.addCase(detachBug.rejected, (state, action) => {
      state.detachBugLoading = {
        id: null,
      };
      state.detachBug = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(detachBug.fulfilled, (state, action) => {
      state.detachBugLoading = {
        id: null,
      };
      state.detachBug = { id: action?.meta?.arg, ...action.payload };
      state.bugPanelError = null;
    });

    //delete bug
    builder.addCase(deleteBug.pending, (state, action) => {
      state.deleteBugLoading = {
        id: action?.meta?.arg,
      };
      state.deleteBug = null;
      state.bugPanelError = null;
    });
    builder.addCase(deleteBug.rejected, (state, action) => {
      state.deleteBugLoading = {
        id: null,
      };
      state.deleteBug = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(deleteBug.fulfilled, (state, action) => {
      state.deleteBugLoading = {
        id: null,
      };
      state.deleteBug = { id: action?.meta?.arg, ...action.payload };
      state.bugPanelError = null;
    });

    //add Comment
    builder.addCase(addBugComment.pending, (state, action) => {
      state.addCommentLoading = {
        id: action?.meta?.arg,
      };
      state.addComment = null;
      state.bugPanelError = null;
    });
    builder.addCase(addBugComment.rejected, (state, action) => {
      state.addCommentLoading = {
        id: null,
      };
      state.addComment = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(addBugComment.fulfilled, (state, action) => {
      state.addCommentLoading = {
        id: null,
      };
      state.addComment = { id: action?.meta?.arg?.bug_id, ...action.payload };
      state.bugPanelError = null;
    });

    //remove comment
    builder.addCase(removeBugComment.pending, (state, action) => {
      state.removeCommentLoading = {
        id: action?.meta?.arg,
      };
      state.removeComment = null;
      state.bugPanelError = null;
    });
    builder.addCase(removeBugComment.rejected, (state, action) => {
      state.removeCommentLoading = {
        id: null,
      };
      state.removeComment = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(removeBugComment.fulfilled, (state, action) => {
      state.removeCommentLoading = {
        id: null,
      };
      state.removeComment = {
        id: action?.meta?.arg?.bug_id,
        ...action.payload,
      };
      state.bugPanelError = null;
    });

    builder.addCase(addBugAttachment.pending, (state, action) => {
      state.addBugAttachmentLoading = {
        id: action?.meta?.arg?.bug_id,
      };
      state.addBugAttachment = null;
      state.bugPanelError = null;
    });
    builder.addCase(addBugAttachment.rejected, (state, action) => {
      state.addBugAttachmentLoading = {
        id: null,
      };
      state.addBugAttachment = null;
      if (action.error) {
        state.bugPanelError = { error: action.error.message };
      } else {
        state.bugPanelError = action.payload;
      }
    });
    builder.addCase(addBugAttachment.fulfilled, (state, action) => {
      state.addBugAttachmentLoading = {
        id: null,
      };
      state.addBugAttachment = {
        id: action?.meta?.arg?.bug_id,
        ...action.payload,
      };
      state.bugPanelError = null;
    });
  },
});

export const { bug } = bugSlice.actions;
export default bugSlice.reducer;
