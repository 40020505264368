import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let calenderApi = {};

calenderApi.getCalenderData = (date) => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("timezone", "UTC");
  formData.append("date", date);
  return axios.post(`${config.url}/widgets/calender`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

calenderApi.getKnowledgeBase = () => {
  return axios.get(`${config.url}/widgets/knowledge-base`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

export default calenderApi;
