import React, { Fragment, useEffect } from "react";
import Styles from "./LoginForm.module.css";
import back_arrow from "../../../assets/images/icons/back_arrow_1.svg";
import ErrorContainer from "../../common/ErrorContainer";
import Input from "../../Elements/Input";
import FormButton from "../../Elements/FormButton";
import utils from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUsingEmailPass,
  sendMagicPin,
} from "../../../reducers/loginReducer";
import loadingGif from "../../../assets/images/gifs/loading_gif_1.gif";
function LoginForm__Password(props) {
  let { errorState, changeErrorState, changeFormState, clearState, formState } =
    props.data;
  let dispatch = useDispatch();
  const loggingIn = useSelector((state) => {
    return state.loginReducer.loggingIn;
  });
  const sendMagicPinLoading = useSelector((state) => {
    return state.loginReducer.sendMagicPinLoading;
  });

  return (
    <Fragment>
      <div className={Styles.loginForm__section}>
        <img
          src={back_arrow}
          className={Styles.backArrow}
          alt="back"
          onClick={() => {
            clearState();
            changeFormState((oldState) => {
              let temp = { ...oldState };
              temp.type = "email";
              return temp;
            });
          }}
        />
        <ErrorContainer state={errorState} type="style1" />
        <div className={Styles.buttonWrapper}>
          {" "}
          <FormButton
            type="style2"
            handler={() => {
              clearState();
              let email = formState.data.email;
              dispatch(sendMagicPin(email));
              // changeFormState((oldState) => {
              //   let temp = { ...oldState };
              //   temp.type = "verification";
              //   return temp;
              // });
            }}
          >
            Send Code
          </FormButton>
          {sendMagicPinLoading ? (
            <img
              src={loadingGif}
              className={Styles.loadingGif}
              alt="logginin"
            />
          ) : null}
        </div>

        <p className={Styles.loginForm__sectionText}>
          Already have an account ?
        </p>
        <div className={Styles.passwordFieldContainer}>
          <Input
            type="password"
            placeholder="Password"
            id="userPassword"
            handler={(e) => {
              clearState();
              // changeInputData((oldState) => {
              //   let temp = { ...oldState };
              //   temp.email = e.target.value;
              //   return temp;
              // });
            }}
          />
          <div className={Styles.loginOptions}>
            <p className={Styles.loginOptions__text}>remember me</p>
            <p className={Styles.loginOptions__text}>forgot password?</p>
          </div>
        </div>
        <div className={Styles.buttonWrapper}>
          <FormButton
            type="style1"
            handler={() => {
              let userInput = document.getElementById("userPassword").value;

              if (userInput && !utils.isStringEmpty(userInput)) {
                let email = formState.data.email;
                let password = userInput;
                dispatch(loginUsingEmailPass({ email, password }));
                // changeFormState((oldState) => {
                //   let temp = { ...oldState };
                //   temp.type = "password";
                //   temp.data = {
                //     email: userInput,
                //   };
                //   return temp;
                // });
              } else {
                changeErrorState((oldState) => {
                  let temp = { ...oldState };
                  temp.error = "invalid password";
                  return temp;
                });
              }
            }}
          >
            Login
          </FormButton>
          {loggingIn ? (
            <img
              src={loadingGif}
              className={Styles.loadingGif}
              alt="logginin"
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default LoginForm__Password;
