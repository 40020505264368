import React, { useEffect, useState } from "react";
import Styles from "./CalenderWidget.module.css";
import rightArrow from "../../../../../assets/images/icons/right_arrow_2.svg";
import user from "../../../../../assets/images/icons/user.svg";
import tag from "../../../../../assets/images/icons/Tag.svg";
import userIcon from "../../../../../assets/images/icons/userIconDefault.svg";
import clock from "../../../../../assets/images/icons/clock.svg";
import dropdownArrow from "../../../../../assets/images/icons/dropdown_arrow_1.svg";
import classnames from "classnames";
import ScrollableComponent from "../../../../common/ScrollableComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalenderData,
  getKnowledgeBase,
} from "../../../../../reducers/calenderReducer";
import moment from "moment";
import newTaskEventIcon from "../../../../../assets/images/icons/newTaskEventIcon.svg";
import newBugEventIcon from "../../../../../assets/images/icons/newBugEventIcon.svg";
import meetingIcon from "../../../../../assets/images/icons/newMeetingEventIcon.svg";
import ReadingBookImage from "../../../../../assets/images/other_images/Reading Book.png";
import knowledgeBaseIcon from "../../../../../assets/images/icons/knowledgeBaseIcon.svg";
import megaphoneIcon from "../../../../../assets/images/icons/megaPhoneIcon.svg";
function CalenderWidget(props) {
  let dispatch = useDispatch();
  let [currentDate, changeCurrentDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  let [selectedInfo, changeSelectedInfo] = useState("kb");
  let [currentSelectedType, changeSelectedType] = useState("all");
  let calenderData = useSelector((state) => state.calenderReducer.calenderData);
  let calenderDataLoading = useSelector(
    (state) => state.calenderReducer.calenderDataLoading
  );
  let calenderError = useSelector(
    (state) => state.calenderReducer.calenderError
  );

  let knowledgeBaseData = useSelector(
    (state) => state.calenderReducer.knowledgeBaseData
  );
  let knowledgeBaseLoading = useSelector(
    (state) => state.calenderReducer.knowledgeBaseLoading
  );

  useEffect(() => {
    if (calenderError) {
      props.changeAlertBoxData({
        isVisible: true,
        message: "Something went wrong while fetching calender data",
        type: "danger",
      });
    }
  }, [calenderError]);

  useEffect(() => {
    dispatch(getCalenderData(currentDate));
  }, [currentDate]);

  useEffect(() => {
    dispatch(getKnowledgeBase());
  }, []);

  //TODO: GET FORMAT OF CALENDAR DATA FROM PALASH BHAI
  //FIX THE FORMAT.
  function getCount(data, type) {
    if (data) {
      if (type == "all") {
        return (
          (data["BUG"]["NEW_BUG"]?.length ?? 0) +
          (data["TASK"]["NEW_TASK"]?.length ?? 0)
        );
      } else if (type == "task") {
        return data["TASK"]["NEW_TASK"]?.length ?? 0;
      } else if (type == "bug") {
        return data["BUG"]["NEW_BUG"]?.length ?? 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  function addDate(addBy) {
    changeCurrentDate(
      moment(currentDate).add(addBy, "days").format("YYYY-MM-DD")
    );
  }

  function subtractDate(subtractBy) {
    changeCurrentDate(
      moment(currentDate).subtract(subtractBy, "days").format("YYYY-MM-DD")
    );
  }

  function formatTime(timeString) {
    return moment(timeString, "YYYY-MM-DD HH:mm:ss").format("h:mm A");
  }

  function loadingUi() {
    return [1, 2, 3, 4].map((val, index) => {
      return (
        <div
          className={classnames(Styles.eventBox, Styles.loadingContainer)}
          key={index}
        >
          <div className={Styles.loadingImg}></div>
          <div className={Styles.eventInfoBox}>
            <div className={Styles.eventInfoTextLoading}>
              <div
                className={Styles.loadingElement}
                style={{ width: "2.75em" }}
              ></div>
              <div
                className={Styles.loadingElement}
                style={{ width: "15.375em" }}
              ></div>
            </div>
          </div>
        </div>
      );
    });
  }

  function knowledgebaseLoadingUI() {
    return [1, 2, 3, 4].map((val, index) => {
      return (
        <div
          className={classnames(Styles.infoBox, Styles.loadingContainer)}
          key={index}
        >
          <div className={Styles.infoWrapper}>
            <div
              className={Styles.loadingElement}
              style={{ width: "2.75em" }}
            ></div>
            <div
              className={Styles.loadingElement}
              style={{ width: "8.75em", marginTop: "1em" }}
            ></div>
          </div>
        </div>
      );
    });
  }

  function eventBoxUi(type, otherDataOb) {
    let icon = null,
      heading = "",
      text = "";
    switch (type) {
      case "task":
        icon = newTaskEventIcon;
        heading = "New task";
        text = otherDataOb.task_text;
        break;
      case "bug":
        icon = newBugEventIcon;
        heading = "New bug";
        text = otherDataOb.bug_text;
        break;
      case "meeting":
        icon = meetingIcon;
        heading = "Meeting";
        text = otherDataOb.meeting_text;
        break;
      default:
        icon = null;
    }
    return (
      <div className={Styles.eventBox}>
        <img src={icon} alt="img" className={Styles.eventIcon} />
        <div className={Styles.eventInfoBox}>
          <div className={Styles.eventInfoDetails}>
            <div className={Styles.eventType}>
              <img src={tag} alt="type" className={Styles.eventInfoIcon} />
              <p>{heading}</p>
            </div>
            <div className={Styles.eventUser}>
              <img src={user} alt="type" className={Styles.eventInfoIcon} />
              <p>{otherDataOb.created_by.name}</p>
            </div>
          </div>
          <div className={Styles.eventInfoText}>
            <p>{text}</p>
          </div>
        </div>
        <div className={Styles.eventTimeBox}>
          <img src={clock} alt="clock" className={Styles.eventInfoIcon} />
          <p>{formatTime(otherDataOb.created_at)}</p>
        </div>
      </div>
    );
  }

  function renderUi(type, data) {
    return data
      ? data.map((val, index) => {
          return eventBoxUi(type, val);
        })
      : null;
  }

  function noDataUi() {
    return (
      <div className={Styles.noDataUiContainer}>
        <img src={ReadingBookImage} alt="no data" />
        <p>Sit back & Relax. No more activity today.</p>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.calenderSection}>
        <div className={Styles.calenderHeader}>
          <p className={Styles.headerText}>today,</p>
        </div>
        <div className={Styles.calenderBodyWrapper}>
          <div className={Styles.calenderDates}>
            <div className={Styles.dateSlider}>
              <img
                src={rightArrow}
                className={Styles.leftArrow}
                alt="prev"
                onClick={() => {
                  subtractDate(1);
                }}
              />
              <div className={Styles.selectedDateBox}>
                <p>{moment(currentDate).format("DD")}</p>
                <p>{moment(currentDate).format("ddd")}</p>
              </div>
              <img
                src={rightArrow}
                className={Styles.rightArrow}
                alt="prev"
                onClick={() => {
                  addDate(1);
                }}
              />
            </div>
            <div className={Styles.typeButtonWrapper}>
              <div
                className={classnames(
                  Styles.typeButtonBox,
                  currentSelectedType == "all"
                    ? Styles.typeButtonBoxSelected
                    : ""
                )}
                onClick={() => {
                  changeSelectedType("all");
                }}
              >
                <p>All</p>
                <p>{getCount(calenderData, "all")}</p>
              </div>
              <div
                onClick={() => {
                  changeSelectedType("task");
                }}
                className={classnames(
                  Styles.typeButtonBox,
                  currentSelectedType == "task"
                    ? Styles.typeButtonBoxSelected
                    : ""
                )}
              >
                <p>Task</p>
                <p>{getCount(calenderData, "task")}</p>
              </div>
              <div
                onClick={() => {
                  changeSelectedType("bug");
                }}
                className={classnames(
                  Styles.typeButtonBox,
                  currentSelectedType == "bug"
                    ? Styles.typeButtonBoxSelected
                    : ""
                )}
              >
                <p>Bug</p>
                <p>{getCount(calenderData, "bug")}</p>
              </div>
            </div>
            <img
              src={dropdownArrow}
              alt="arrow"
              className={Styles.collapseArrow}
            />
          </div>
          <div className={Styles.calenderMain}>
            {calenderDataLoading ? (
              loadingUi()
            ) : (
              <ScrollableComponent show={3}>
                {calenderData ? (
                  currentSelectedType == "all" ? (
                    renderUi("task", calenderData["TASK"]?.NEW_TASK) ||
                    renderUi("bug", calenderData["BUG"]?.NEW_BUG) ? (
                      <>
                        {renderUi("task", calenderData["TASK"]?.NEW_TASK)}
                        {renderUi("bug", calenderData["BUG"]?.NEW_BUG)}
                      </>
                    ) : (
                      noDataUi()
                    )
                  ) : currentSelectedType == "task" ? (
                    renderUi("task", calenderData["TASK"]?.NEW_TASK) ??
                    noDataUi()
                  ) : currentSelectedType == "bug" ? (
                    renderUi("bug", calenderData["BUG"]?.NEW_BUG) ?? noDataUi()
                  ) : (
                    ""
                  )
                ) : (
                  "no data ui"
                )}
              </ScrollableComponent>
            )}
          </div>
        </div>
      </div>
      {/* <div className={Styles.recentActivitiesSection}>
        <div className={Styles.activitiesHeader}>
          <p className={Styles.activitiesHeaderText}>Recent Activities</p>
          <img
            src={dropdownArrow}
            alt="arrow"
            className={Styles.collapseArrowActivites}
          />
        </div>
        <div className={Styles.activitiesMain}>
          <div className={Styles.activitiesBox}>
            <img src={userIcon} alt="user" className={Styles.activitiesUser} />
            <div className={Styles.activitiesInfoBox}>
              <p className={Styles.activitiesInfoText}>
                <b>Harshil</b> joined <b>#branding</b>
              </p>
              <p className={Styles.activitiesInfoText2}>Today, 10:00 PM</p>
            </div>
          </div>
        </div>
      </div> */}
      <div className={Styles.infoSection}>
        <div className={Styles.infoSectionHeader}>
          <div
            onClick={() => {
              changeSelectedInfo("kb");
            }}
            className={classnames(
              Styles.infoHeadingContainer,
              selectedInfo == "kb" ? Styles.infoHeadingSelectedContainer : ""
            )}
          >
            <img src={knowledgeBaseIcon} alt="kb" />
            <p className={Styles.infoHeading}>knowledge base</p>
          </div>
          <div
            className={classnames(
              Styles.infoHeadingContainer,
              selectedInfo == "new" ? Styles.infoHeadingSelectedContainer : ""
            )}
            onClick={() => {
              changeSelectedInfo("new");
            }}
          >
            <img src={megaphoneIcon} alt="new" />
            <p className={Styles.infoHeading}>what's new</p>
          </div>
          <img
            src={dropdownArrow}
            alt="collapse"
            className={Styles.collapseArrow}
          />
        </div>
        <div className={Styles.infoSectionBody}>
          {knowledgeBaseLoading
            ? knowledgebaseLoadingUI()
            : knowledgeBaseData
            ? selectedInfo == "kb"
              ? knowledgeBaseData["knowledgeBase"].map((val, index) => {
                  return (
                    <div className={Styles.infoBox} key={index}>
                      <img src={val.icon} alt="icon" />
                      <div className={Styles.infoWrapper}>
                        <p className={Styles.infoCategory}>{val.category}</p>
                        <p className={Styles.infoHeader}>{val.header}</p>
                        <p className={Styles.infoText}>{val.contentHeader}</p>
                      </div>
                    </div>
                  );
                })
              : selectedInfo == "new"
              ? knowledgeBaseData["whatsNew"].map((val, index) => {
                  return (
                    <div className={Styles.infoBox} key={index}>
                      <img src={val.icon} alt="icon" />
                      <div className={Styles.infoWrapper}>
                        <p className={Styles.infoCategory}>{val.category}</p>
                        <p className={Styles.infoHeader}>{val.header}</p>
                        <p className={Styles.infoText}>{val.contentHeader}</p>
                      </div>
                    </div>
                  );
                })
              : ""
            : ""}
        </div>
      </div>
    </div>
  );
}

export default CalenderWidget;
