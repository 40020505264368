import React from "react";
import Styles from "./BgEllipse.module.css";
import bg_ellipse_1 from "../../assets/images/bg_images/bg_ellipse_1.svg";
import bg_ellipse_2 from "../../assets/images/bg_images/bg_ellipse_2.svg";
function BgEllipse(props) {
  return (
    <div className={Styles.container}>
      <img
        src={bg_ellipse_2}
        alt="bg_ellipse"
        className={Styles.container__ellipse_1}
      />
      <img
        src={bg_ellipse_1}
        alt="bg_ellipse"
        className={Styles.container__ellipse_2}
      />
    </div>
  );
}

export default BgEllipse;
