import React, { useEffect } from "react";
import Styles from "./Modal.module.css";
import classnames from "classnames";

//Props:
// show:true/false
// changeModalState()
// parentId
function Modal(props) {
  let childId = props?.childId ?? "commonModal";
  useEffect(() => {
    let parentScrollElement = document.getElementById(props?.parentId);
    const handleScroll = () => {
      const element = document.getElementById(childId);
      const elementOffsetTop = element && element.offsetTop;

      const windowScrollTop = parentScrollElement.scrollTop;

      element.style.marginTop = windowScrollTop + "px";
    };

    parentScrollElement?.addEventListener("scroll", handleScroll);
    return () => {
      parentScrollElement?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={Styles.mainContainer}
      style={{ visibility: props.show ? "visible" : "hidden" }}
      id={childId}
      onClick={(e) => {
        if (e.target.id == childId) {
          props.changeModalState(false);
        }
      }}
    >
      <div
        className={classnames(
          Styles.container,
          props.show ? Styles.showContainer : ""
        )}
        style={{ ...props.styles }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Modal;
