import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import taskApi from "../api/taskApi";

let initialState = {
  graphData: [],
  graphDataLoading: false,
  graphDataError: null,
  taskPanelError: null,
  taskStats: null,
  taskStatsLoading: false,
  taskList: [],
  taskListLoading: false,
  taskCategoryLoading: false,
  taskCategory: null,
  addTask: null,
  addTaskLoading: false,
  editTask: null,
  editTaskLoading: {
    id: null,
  },
  taskInfo: null,
  taskInfoLoading: {
    id: null,
  },
  pinTask: null,
  pinTaskLoading: {
    id: null,
  },
  detachTask: null,
  detachTaskLoading: {
    id: null,
  },
  deleteTaskLoading: {
    id: null,
  },
  deleteTask: null,
  addCommentLoading: {
    id: null,
  },
  addComment: null,
  removeCommentLoading: {
    id: null,
  },
  removeComment: null,
  addTaskAttachment: null,
  addTaskAttachmentLoading: false,
};

export const getGraphData = createAsyncThunk(
  "task/graphData",
  async (chartTime = "week", { rejectWithValue }) => {
    const response = await taskApi.getGraphData(chartTime);

    if (response.data.status == "success") {
      return response.data.graph;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTaskStats = createAsyncThunk(
  "task/stats",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.getStats();
    if (response.data.status == "success") {
      return response.data.stats;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTaskList = createAsyncThunk(
  "task/list",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.getList(data);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTaskCategoryList = createAsyncThunk(
  "task/category",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.getCategories();
    if (response.data.status == "success") {
      return response.data.categories;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTaskTagList = createAsyncThunk(
  "task/tags",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.getTags();
    if (response.data.status == "success") {
      return response.data.tags;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const addTask = createAsyncThunk(
  "task/addTask",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.addtask(data);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const editTask = createAsyncThunk(
  "task/editTask",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.editTask(data);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTaskInfo = createAsyncThunk(
  "task/taskInfo",
  async (data = null, { rejectWithValue }) => {
    const response = await taskApi.getList(data);
    if (response.data.status == "success") {
      return response.data.tasks;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const pinTask = createAsyncThunk(
  "task/pinTask",
  async (task_id = null, { rejectWithValue }) => {
    const response = await taskApi.pinTask(task_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const detachTask = createAsyncThunk(
  "task/detachTask",
  async (task_id = null, { rejectWithValue }) => {
    const response = await taskApi.detachTask(task_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/deleteTask",
  async (task_id = null, { rejectWithValue }) => {
    const response = await taskApi.deleteTask(task_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const addTaskComment = createAsyncThunk(
  "task/addTaskComment",
  async ({ task_id = null, commentText = null }, { rejectWithValue }) => {
    const response = await taskApi.addComment(task_id, commentText);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const removeTaskComment = createAsyncThunk(
  "task/removeTaskComment",
  async ({ task_id = null, comment_id = null }, { rejectWithValue }) => {
    const response = await taskApi.removeComment(task_id, comment_id);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const addTaskAttachment = createAsyncThunk(
  "task/addTaskAttachment",
  async ({ task_id = null, file = null }, { rejectWithValue }) => {
    const response = await taskApi.addAttachment(task_id, file);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState,

  extraReducers: (builder) => {
    //get graph data
    builder.addCase(getGraphData.pending, (state, action) => {
      state.graphDataLoading = true;
      state.graphData = null;
      state.graphDataError = null;
    });
    builder.addCase(getGraphData.rejected, (state, action) => {
      state.graphDataLoading = false;
      state.graphData = null;

      if (action.error) {
        state.graphDataError = { error: action.error.message };
      } else {
        state.graphDataError = action.payload;
      }
    });
    builder.addCase(getGraphData.fulfilled, (state, action) => {
      state.graphDataLoading = false;
      state.graphData = action.payload;
      state.graphDataError = null;
    });

    //get stats
    builder.addCase(getTaskStats.pending, (state, action) => {
      state.taskStatsLoading = true;
      state.taskStats = null;
      state.taskPanelError = null;
    });
    builder.addCase(getTaskStats.rejected, (state, action) => {
      state.taskStatsLoading = false;
      state.taskStats = null;

      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(getTaskStats.fulfilled, (state, action) => {
      state.taskStatsLoading = false;
      state.taskStats = action.payload;
      state.taskPanelError = null;
    });

    //get list
    builder.addCase(getTaskList.pending, (state, action) => {
      state.taskListLoading = true;
      state.taskList = null;
      state.taskPanelError = null;
    });
    builder.addCase(getTaskList.rejected, (state, action) => {
      state.taskListLoading = false;
      state.taskList = null;

      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(getTaskList.fulfilled, (state, action) => {
      state.taskListLoading = false;
      state.taskList = action.payload;
      state.taskPanelError = null;
    });

    //get categories
    builder.addCase(getTaskCategoryList.pending, (state, action) => {
      state.taskCategoryLoading = true;
      state.taskCategory = null;
      state.taskPanelError = null;
    });
    builder.addCase(getTaskCategoryList.rejected, (state, action) => {
      state.taskCategoryLoading = false;
      state.taskCategory = null;

      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(getTaskCategoryList.fulfilled, (state, action) => {
      state.taskCategoryLoading = false;
      state.taskCategory = action.payload;
      state.taskPanelError = null;
    });

    //get tags
    builder.addCase(getTaskTagList.pending, (state, action) => {
      state.getTagsLoading = true;
      state.taskTags = null;
      state.taskPanelError = null;
    });
    builder.addCase(getTaskTagList.rejected, (state, action) => {
      state.getTagsLoading = false;
      state.taskTags = null;

      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(getTaskTagList.fulfilled, (state, action) => {
      state.getTagsLoading = false;
      state.taskTags = action.payload;
      state.taskPanelError = null;
    });

    //add task
    builder.addCase(addTask.pending, (state, action) => {
      state.addTaskLoading = true;
      state.addTask = null;
      state.taskPanelError = null;
    });
    builder.addCase(addTask.rejected, (state, action) => {
      state.addTaskLoading = false;
      state.addTask = null;

      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(addTask.fulfilled, (state, action) => {
      state.addTaskLoading = false;
      state.addTask = action.payload;
      state.taskPanelError = null;
    });

    //edit task
    builder.addCase(editTask.pending, (state, action) => {
      state.editTaskLoading = {
        id: action?.meta?.arg?.task_id,
      };
      state.editTask = null;
      state.taskPanelError = null;
    });
    builder.addCase(editTask.rejected, (state, action) => {
      state.editTaskLoading = {
        id: null,
      };
      state.editTask = null;

      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(editTask.fulfilled, (state, action) => {
      state.editTaskLoading = {
        id: null,
      };
      state.editTask = { id: action?.meta?.arg?.task_id, ...action.payload };
      state.taskPanelError = null;
    });

    //task info
    builder.addCase(getTaskInfo.pending, (state, action) => {
      state.taskInfoLoading = {
        id: action?.meta?.arg?.task_id,
      };
      state.taskInfo = null;
      state.taskPanelError = null;
    });
    builder.addCase(getTaskInfo.rejected, (state, action) => {
      state.taskInfoLoading = {
        id: null,
      };
      state.taskInfo = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(getTaskInfo.fulfilled, (state, action) => {
      state.taskInfoLoading = {
        id: null,
      };
      state.taskInfo = action.payload;
      state.taskPanelError = null;
    });

    //pin task
    builder.addCase(pinTask.pending, (state, action) => {
      state.pinTaskLoading = {
        id: action?.meta?.arg,
      };
      state.pinTask = null;
      state.taskPanelError = null;
    });
    builder.addCase(pinTask.rejected, (state, action) => {
      state.pinTaskLoading = {
        id: null,
      };
      state.pinTask = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(pinTask.fulfilled, (state, action) => {
      state.pinTaskLoading = {
        id: null,
      };
      state.pinTask = { id: action?.meta?.arg, ...action.payload };
      state.taskPanelError = null;
    });

    //detach task
    builder.addCase(detachTask.pending, (state, action) => {
      state.detachTaskLoading = {
        id: action?.meta?.arg,
      };
      state.detachTask = null;
      state.taskPanelError = null;
    });
    builder.addCase(detachTask.rejected, (state, action) => {
      state.detachTaskLoading = {
        id: null,
      };
      state.detachTask = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(detachTask.fulfilled, (state, action) => {
      state.detachTaskLoading = {
        id: null,
      };
      state.detachTask = { id: action?.meta?.arg, ...action.payload };
      state.taskPanelError = null;
    });

    //delete task
    builder.addCase(deleteTask.pending, (state, action) => {
      state.deleteTaskLoading = {
        id: action?.meta?.arg,
      };
      state.deleteTask = null;
      state.taskPanelError = null;
    });
    builder.addCase(deleteTask.rejected, (state, action) => {
      state.deleteTaskLoading = {
        id: null,
      };
      state.deleteTask = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(deleteTask.fulfilled, (state, action) => {
      state.deleteTaskLoading = {
        id: null,
      };
      state.deleteTask = { id: action?.meta?.arg, ...action.payload };
      state.taskPanelError = null;
    });

    //add Comment
    builder.addCase(addTaskComment.pending, (state, action) => {
      state.addCommentLoading = {
        id: action?.meta?.arg,
      };
      state.addComment = null;
      state.taskPanelError = null;
    });
    builder.addCase(addTaskComment.rejected, (state, action) => {
      state.addCommentLoading = {
        id: null,
      };
      state.addComment = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(addTaskComment.fulfilled, (state, action) => {
      state.addCommentLoading = {
        id: null,
      };
      state.addComment = { id: action?.meta?.arg?.task_id, ...action.payload };
      state.taskPanelError = null;
    });

    //remove comment
    builder.addCase(removeTaskComment.pending, (state, action) => {
      state.removeCommentLoading = {
        id: action?.meta?.arg,
      };
      state.removeComment = null;
      state.taskPanelError = null;
    });
    builder.addCase(removeTaskComment.rejected, (state, action) => {
      state.removeCommentLoading = {
        id: null,
      };
      state.removeComment = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(removeTaskComment.fulfilled, (state, action) => {
      state.removeCommentLoading = {
        id: null,
      };
      state.removeComment = {
        id: action?.meta?.arg?.task_id,
        ...action.payload,
      };
      state.taskPanelError = null;
    });

    builder.addCase(addTaskAttachment.pending, (state, action) => {
      state.addTaskAttachmentLoading = {
        id: action?.meta?.arg?.task_id,
      };
      state.addTaskAttachment = null;
      state.taskPanelError = null;
    });
    builder.addCase(addTaskAttachment.rejected, (state, action) => {
      state.addTaskAttachmentLoading = {
        id: null,
      };
      state.addTaskAttachment = null;
      if (action.error) {
        state.taskPanelError = { error: action.error.message };
      } else {
        state.taskPanelError = action.payload;
      }
    });
    builder.addCase(addTaskAttachment.fulfilled, (state, action) => {
      state.addTaskAttachmentLoading = {
        id: null,
      };
      state.addTaskAttachment = {
        id: action?.meta?.arg?.task_id,
        ...action.payload,
      };
      state.taskPanelError = null;
    });
  },
});

export const { task } = taskSlice.actions;
export default taskSlice.reducer;
