import React, { useEffect, useState } from "react";
import Styles from "./TaskBugWidget.module.css";
import millify from "millify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGraphData } from "../../../../../reducers/taskReducer";
import { getBugGraphData } from "../../../../../reducers/bugReducer";
import utils from "../../../../../utils";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import classnames from "classnames";
import newTaskIcon from "../../../../../assets/images/icons/newTaskIcon.svg";
import completedTaskIcon from "../../../../../assets/images/icons/completedIcon.svg";
import priorityTaskIcon from "../../../../../assets/images/icons/priorityIcon.svg";
import rightArrow from "../../../../../assets/images/icons/right_arrow_2.svg";
import newBugIcon from "../../../../../assets/images/icons/newBugIcon.svg";
import resolvedBugIcon from "../../../../../assets/images/icons/resolvedBugIcon.svg";
import highPriorityBugIcon from "../../../../../assets/images/icons/highPriorityBugIcon.svg";
import LoadingElement from "../../../../common/LoadingElement/LoadingElement";
import noDataImage from "../../../../../assets/images/other_images/nodataImage_1.png";
function TaskBugWidget(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isBugWindowActivated, changeWindow] = useState(false);
  const [dataToRender, changeRenderData] = useState([]);
  const [timeFrame, changeTimeFrame] = useState("week");
  const [taskInfo, changeTaskInfo] = useState([
    {
      icon: newTaskIcon,
      value: 0,
      keyword: "new",
      label: "new task",
      textColor: "#096344",
    },
    {
      icon: completedTaskIcon,
      value: 0,
      keyword: "completed",
      label: "completed",
      textColor: "#00499F",
    },
    {
      icon: priorityTaskIcon,
      value: 0,
      keyword: "priority",
      label: "high priority",
      textColor: "#CB083B",
    },
  ]);
  const [bugInfo, changeBugInfo] = useState([
    {
      icon: newBugIcon,
      value: 0,
      keyword: "new",
      label: "new bug",
      textColor: "#18BBBB",
    },
    {
      icon: resolvedBugIcon,
      value: 0,
      keyword: "completed",
      label: "resolved",
      textColor: "var(--darkBlue)",
    },
    {
      icon: highPriorityBugIcon,
      value: 0,
      keyword: "priority",
      label: "high priority",
      textColor: "#CE5613",
    },
  ]);
  const [noDataAvailable, updateNoDataAvailable] = useState(false);

  let taskGraphData = useSelector((state) => state.taskReducer.graphData);

  let bugGraphData = useSelector((state) => state.bugReducer.graphData);
  let taskGraphDataLoading = useSelector(
    (state) => state.taskReducer.graphDataLoading
  );
  let bugGraphDataLoading = useSelector(
    (state) => state.bugReducer.graphDataLoading
  );
  let taskGraphDataError = useSelector(
    (state) => state.taskReducer.graphDataError
  );
  let bugGraphDataError = useSelector(
    (state) => state.bugReducer.graphDataError
  );

  //Fetch bug and task data after render
  // useEffect(() => {
  //   dispatch(getGraphData());
  //   dispatch(getBugGraphData());
  // }, []);

  useEffect(() => {
    dispatch(getGraphData(timeFrame));
    dispatch(getBugGraphData(timeFrame));
  }, [timeFrame]);

  //if there is error in any on api this will handle
  useEffect(() => {
    if (
      (taskGraphDataError && taskGraphDataError.error) ||
      (bugGraphDataError && bugGraphDataError.error)
    ) {
      let error = taskGraphDataError
        ? taskGraphDataError.error
        : bugGraphDataError.error;
      if (error == "Invalid access_token") {
        utils.removeToken();
        navigate("/login");
      } else {
        showAlert(
          "Something went wrong while fetching task/bug data",
          "danger"
        );
      }
    }
  }, [taskGraphDataError, bugGraphDataError]);

  useEffect(() => {
    if (!isBugWindowActivated) {
      changeRenderData(taskGraphData);

      changeTaskInfo((oldState) => {
        let temp = [...oldState];

        temp.forEach((infoOb) => {
          let total = countTotal(taskGraphData, infoOb.keyword);
          infoOb.value = total;
        });

        return temp;
      });
    }
  }, [taskGraphData]);

  useEffect(() => {
    changeRenderData(bugGraphData);
    changeBugInfo((oldState) => {
      let temp = [...oldState];
      temp.forEach((infoOb) => {
        let total = countTotal(bugGraphData, infoOb.keyword);
        infoOb.value = total;
      });

      return temp;
    });
  }, [bugGraphData]);

  function updateNoDataState(dataArr = []) {
    if (dataArr.length != 0) {
      let total = countTotal(dataArr, false);

      if (total == 0) {
        updateNoDataAvailable(true);
      } else {
        updateNoDataAvailable(false);
      }
    } else {
      updateNoDataAvailable(true);
    }
  }
  useEffect(() => {
    if (isBugWindowActivated) {
      updateNoDataState(bugGraphData);
      changeRenderData(bugGraphData);
    } else {
      updateNoDataState(taskGraphData);
      changeRenderData(taskGraphData);
    }
  }, [isBugWindowActivated]);

  function showAlert(message, type) {
    props.changeAlertBoxData((oldState) => {
      let temp = { ...oldState };
      temp.message = message;
      temp.type = type;
      temp.isVisible = true;
      return temp;
    });
  }

  function countTotal(dataArray, keyword) {
    let count = 0;
    dataArray &&
      dataArray.forEach((dataOb) => {
        if (keyword) {
          count = count + dataOb[keyword];
        } else {
          count =
            count + dataOb["new"] + dataOb["completed"] + dataOb["priority"];
        }
      });
    return count;
  }

  function renderInfoData(val) {
    return (
      <div className={Styles.infoBox}>
        <img src={val.icon} alt="img" className={Styles.icon} />
        <div className={Styles.infoTextBox}>
          <p
            className={Styles.infoHead}
            style={{
              color: val.textColor,
            }}
          >
            {millify(val.value)}
          </p>
          <p className={Styles.infoTitle}>{val.label}</p>
        </div>
        <img src={rightArrow} alt="more" className={Styles.infoBoxMenu} />
      </div>
    );
  }

  let bugStyles = {
    new: {
      graphStrokeColorStart: "rgba(24, 187, 187, 1)",
      graphStrokeColorStop: "rgba(24, 187, 187, 0)",
    },
    resolved: {
      graphStrokeColorStart: "rgba(53, 81, 224, 1)",
      graphStrokeColorStop: "rgba(53, 81, 224, 0)",
    },
    priority: {
      graphStrokeColorStart: "rgba(206, 86, 19, 1)",
      graphStrokeColorStop: "rgba(206, 86, 19, 0)",
    },
  };

  let taskStyles = {
    new: {
      graphStrokeColorStart: "rgba(44, 174, 130, 1)",
      graphStrokeColorStop: "rgba(44, 174, 130, 0)",
    },
    completed: {
      graphStrokeColorStart: "rgba(65, 141, 232, 1)",
      graphStrokeColorStop: "rgba(65, 141, 232, 0)",
    },
    priority: {
      graphStrokeColorStart: "rgba(249, 89, 131, 1)",
      graphStrokeColorStop: "rgba(249, 89, 131, 0)",
    },
  };

  let dummyData = [
    { name: "Mon", new: 10, completed: 0, priority: 0 },
    { name: "Tue", new: 0, completed: 10, priority: 0 },
    { name: "Wed", new: 0, completed: 0, priority: 0 },
    { name: "Thu", new: 0, completed: 0, priority: 0 },
    { name: "Fri", new: 3, completed: 1, priority: 11 },
    { name: "Sat", new: 0, completed: 1, priority: 0 },
    { name: "Sun", new: 10, completed: 0, priority: 0 },
  ];
  return (
    <div className={Styles.container}>
      {taskGraphDataLoading || bugGraphDataLoading ? (
        <LoadingElement styles={{ borderRadius: "20px" }} />
      ) : (
        ""
      )}

      <div className={Styles.widgetNav}>
        <div className={Styles.navSection}>
          <button
            className={classnames(
              Styles.widgetNavButton,
              isBugWindowActivated ? "" : Styles.widgetNavButtonActive
            )}
            onClick={() => {
              changeWindow(false);
            }}
          >
            task
          </button>
          <button
            className={classnames(
              Styles.widgetNavButton,
              isBugWindowActivated ? Styles.widgetNavButtonActive : ""
            )}
            onClick={() => {
              changeWindow(true);
            }}
          >
            bug
          </button>
        </div>
        <div className={Styles.navSection}>
          <button
            className={classnames(
              timeFrame == "week" ? Styles.widgetNavButton2Active : "",
              Styles.widgetNavButton2
            )}
            onClick={() => {
              changeTimeFrame("week");
            }}
          >
            W
          </button>
          <button
            className={classnames(
              timeFrame == "month" ? Styles.widgetNavButton2Active : "",
              Styles.widgetNavButton2
            )}
            onClick={() => {
              changeTimeFrame("month");
            }}
          >
            M
          </button>
          <button
            className={classnames(
              timeFrame == "year" ? Styles.widgetNavButton2Active : "",
              Styles.widgetNavButton2
            )}
            onClick={() => {
              changeTimeFrame("year");
            }}
          >
            Y
          </button>
        </div>
      </div>
      <div className={Styles.widgetBody}>
        <div className={Styles.graphContainer}>
          {noDataAvailable ? (
            <div className={Styles.noDataContainer}>
              <img
                src={noDataImage}
                className={Styles.noDataImage}
                alt="no data"
              />
              <p>No data available</p>
            </div>
          ) : (
            ""
          )}

          <ResponsiveContainer>
            <AreaChart
              data={noDataAvailable ? dummyData : dataToRender}
              margin={{ top: 0, right: 0, left: -20, bottom: 20 }}
            >
              <defs>
                <linearGradient id="newData" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="2%"
                    stopColor={
                      isBugWindowActivated
                        ? bugStyles.new.graphStrokeColorStart
                        : taskStyles.new.graphStrokeColorStart
                    }
                    stopOpacity={0.2}
                  />
                  <stop
                    offset="50%"
                    stopColor={
                      isBugWindowActivated
                        ? bugStyles.new.graphStrokeColorStop
                        : taskStyles.new.graphStrokeColorStop
                    }
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="completedData" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="2%"
                    stopColor={
                      isBugWindowActivated
                        ? bugStyles.resolved.graphStrokeColorStart
                        : taskStyles.completed.graphStrokeColorStart
                    }
                    stopOpacity={0.2}
                  />
                  <stop
                    offset="50%"
                    stopColor={
                      isBugWindowActivated
                        ? bugStyles.resolved.graphStrokeColorStop
                        : taskStyles.completed.graphStrokeColorStop
                    }
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="priorityData" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="2%"
                    stopColor={
                      isBugWindowActivated
                        ? bugStyles.priority.graphStrokeColorStart
                        : taskStyles.priority.graphStrokeColorStart
                    }
                    stopOpacity={0.2}
                  />
                  <stop
                    offset="50%"
                    stopColor={
                      isBugWindowActivated
                        ? bugStyles.priority.graphStrokeColorStop
                        : taskStyles.priority.graphStrokeColorStop
                    }
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="name"
                axisLine={false}
                style={{
                  fontFamily: "inherit",
                  fill: "var(--newGrey)",
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
                padding={{ left: 10 }}
                tickMargin={20}
              />
              <YAxis
                tickSize={5}
                tickCount={3}
                minTickGap={5}
                axisLine={false}
                tickMargin={20}
                style={{
                  fontFamily: "inherit",
                  fill: "var(--newGrey)",
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              />
              <CartesianGrid strokeDasharray="5 8" vertical={false} />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="new"
                stroke={
                  isBugWindowActivated
                    ? bugStyles.new.graphStrokeColorStart
                    : taskStyles.new.graphStrokeColorStart
                }
                fillOpacity={1}
                fill="url(#newData)"
              />
              <Area
                type="monotone"
                dataKey="completed"
                stroke={
                  isBugWindowActivated
                    ? bugStyles.resolved.graphStrokeColorStart
                    : taskStyles.completed.graphStrokeColorStart
                }
                fillOpacity={1}
                fill="url(#completedData)"
              />
              <Area
                type="monotone"
                dataKey="priority"
                stroke={
                  isBugWindowActivated
                    ? bugStyles.priority.graphStrokeColorStart
                    : taskStyles.priority.graphStrokeColorStart
                }
                fillOpacity={1}
                fill="url(#priorityData)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className={Styles.infoContainer}>
          {isBugWindowActivated
            ? bugInfo.map((val, index) => {
                return renderInfoData(val);
              })
            : taskInfo.map((val, index) => {
                return renderInfoData(val);
              })}
        </div>
      </div>
    </div>
  );
}

export default TaskBugWidget;
