import React, { Fragment, useState } from "react";
import Styles from "./MainHeader.module.css";
import botzaLogo_1 from "../../assets/images/logos/botza_logo_1.svg";
import botzaLogo_2 from "../../assets/images/logos/botza_logo_2.svg";
import dropdownArrow from "../../assets/images/icons/dropdown_arrow_1.svg";
function MainHeader(props) {
  function renderLogo() {
    if (props.activePage == "slackConnect") {
      return (
        <div className={Styles.header__logoBox}>
          <img src={botzaLogo_1} alt="botza" className={Styles.header__logo} />
          <img
            src={botzaLogo_2}
            alt="botza"
            className={Styles.header__logo_2}
          />
        </div>
      );
    } else {
      return (
        <div className={Styles.header__logoBox}>
          <img src={botzaLogo_1} alt="botza" className={Styles.header__logo} />
        </div>
      );
    }
  }
  function render() {
    switch (props.activePage) {
      case "login":
        return (
          <Fragment>
            <div className={Styles.navContainer__section}>
              <p className={Styles.navElement}>home</p>
              <p className={Styles.navElement}>platforms</p>
              <p className={Styles.navElement}>pricing</p>
              <p className={Styles.navElement}>resources</p>
              <p className={Styles.navElement}>help</p>
            </div>
            <div className={Styles.navContainer__section}>
              <div className={Styles.navDropdown}>
                <p className={Styles.navElement}>English</p>
                <img
                  src={dropdownArrow}
                  alt="dropdown"
                  className={Styles.dropdownArrow}
                />
              </div>
              <p className={Styles.navElement}>My Panel</p>
            </div>
          </Fragment>
        );
        break;
      case "signing":
        return "";
        break;
      case "slackConnect":
        return (
          <Fragment>
            <div className={Styles.navContainer__section}></div>
            <div className={Styles.navContainer__section}>
              <div className={Styles.navDropdown}>
                <p className={Styles.navElement}>English</p>
                <img
                  src={dropdownArrow}
                  alt="dropdown"
                  className={Styles.dropdownArrow}
                />
              </div>
            </div>
          </Fragment>
        );
        break;
      default:
        return "";
    }
  }

  return (
    <div className={Styles.header}>
      {renderLogo()}
      <div className={Styles.navContainer}>{render()}</div>
    </div>
  );
}

export default MainHeader;
