import React, { useEffect } from "react";
import Styles from "./AlertBox_1.module.css";
import classnames from "classnames";
import successAlertIcon from "../../../assets/images/icons/successAlertIcon.svg";
import dangerAlertIcon from "../../../assets/images/icons/dangerAlertIcon.svg";
import infoAlertIcon from "../../../assets/images/icons/infoAlertIcon.svg";
import warningAlertIcon from "../../../assets/images/icons/warningAlertIcon.svg";
import crossIcon from "../../../assets/images/icons/crossIcon.svg";

//props:
//isVisible:true/false
//type
//changeAlertState function
//message
function AlertBox1(props) {
  let alertType = {
    success: {
      icon: successAlertIcon,
      className: Styles.successBox,
    },
    danger: {
      icon: dangerAlertIcon,
      className: Styles.dangerBox,
    },
    info: {
      icon: infoAlertIcon,
      className: Styles.infoBox,
    },
    warning: {
      icon: warningAlertIcon,
      className: Styles.warningBox,
    },
  };

  function hideAlert() {
    props.changeAlertState({
      isVisible: false,
      message: "",
      type: "success",
    });
  }
  useEffect(() => {
    if (props.isVisible) {
      setTimeout(() => {
        hideAlert();
      }, 5000);
    }
  }, [props.isVisible]);

  return (
    <div
      className={classnames(
        Styles.container,
        props.isVisible ? Styles.visible : Styles.hidden,
        alertType[props.type].className
      )}
    >
      <img
        src={alertType[props.type].icon}
        className={Styles.alertIcon}
        alt="icon"
      />
      <p>{props.message}</p>
      <img
        src={crossIcon}
        className={Styles.crossIcon}
        alt="cancel"
        onClick={hideAlert}
      />
    </div>
  );
}

export default AlertBox1;
