import React, { useState, useEffect, useRef } from "react";
import Styles from "./DataTable.module.css";
import classnames from "classnames";

function DataTable(props) {
  const [shouldEnableScrolling, setShouldEnableScrolling] = useState(false);
  let lastChildOfTable = useRef();
  useEffect(() => {
    let parentScrollElement = document.getElementById("dashboardLayout");
    const handleScroll = () => {
      const element = document.getElementById(`targetElement_${props.type}`);
      // const elementOffsetTop = element && element.offsetTop;

      // const windowScrollTop = parentScrollElement.scrollTop;
      let panelBodyElement = document.getElementById(props.parentId);
      let parentElementPosition = parentScrollElement?.getBoundingClientRect();
      let panelBodyPosition = panelBodyElement?.getBoundingClientRect();
      let targetElementPosition = element?.getBoundingClientRect();

      if (element) {
        if (parentElementPosition?.top + 80 >= panelBodyPosition?.top) {
          let differenceInHeight =
            targetElementPosition?.top - panelBodyPosition?.top + 30;
          let heightOfTargetElement =
            parentElementPosition.height - differenceInHeight + "px";
          element.style.height = heightOfTargetElement;
          setShouldEnableScrolling(true);
        } else {
          setShouldEnableScrolling(false);
        }
      }
    };
    parentScrollElement.addEventListener("scroll", handleScroll);
    return () => {
      parentScrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById(`targetElement_${props.type}`);
    const handleScroll = () => {
      if (lastChildOfTable && lastChildOfTable.current) {
        let panelBodyElement = document.getElementById(props.parentId);
        let panelBodyBottom = panelBodyElement.getBoundingClientRect().bottom;
        let lastChildBottom =
          lastChildOfTable.current.getBoundingClientRect().bottom;

        if (lastChildBottom <= panelBodyBottom + 10) {
          props.fetch();
        }
      }
    };
    element.addEventListener("scroll", handleScroll);
    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  });

  function getHeadingData() {
    switch (props.type) {
      case "task":
        return [
          "assignor",
          "task id",
          "title",
          "other assignees",
          "tag",
          "due date",
          "comments",
        ];
      case "bug":
        return [
          "ASSIGNOR",
          "bug id",
          "summary",
          "status",
          "other assignee",
          "due date",
          "comments",
        ];
      case "uptime":
        return ["status", "site", "uptime", "respose time", "speed graph"];
    }
  }

  function rowStyle() {
    switch (props.type) {
      case "task":
        return `0.5fr 0.5fr 3fr 1fr 1fr 2fr 2fr 2.2em`;
      case "bug":
        return `0.5fr 0.5fr 3fr 1fr 1fr 2fr 2fr 2.2em`;
      case "uptime":
        return `0.5fr 1fr 1fr 1fr 5fr`;
      default:
        return `0.5fr 0.5fr 1fr 1fr 1fr 2fr 2fr 2.2em`;
    }
  }
  let headingData = getHeadingData();

  let bodyData = props.rowData ?? [];
  let rowsStyles = {
    gridTemplateColumns: rowStyle(),
  };

  function loadingDataRowUi(numberOfRows) {
    let arr = Array.from({ length: numberOfRows }, (v, i) => i);

    return (
      <div className={Styles.loadingBox} style={rowsStyles}>
        {arr.map((val, index) => {
          return (
            <div
              className={classnames(Styles.bodyRow)}
              style={rowsStyles}
              key={index}
            >
              <div
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>
              <div
                style={{
                  width: "18.0625em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>
              <div
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  borderRadius: "10em",
                  background: "var(--lightGrey)",
                }}
              ></div>
              <div
                style={{
                  width: "4.375em",
                  height: "1.5em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.rowWrapper}>
        {props.selectMultiple ? (
          <input
            type="radio"
            className={Styles.selectRadio}
            style={{ marginBottom: 0 }}
            onClick={props.selectAll}
            checked={props.isAllSelected()}
          />
        ) : (
          ""
        )}

        <div className={Styles.headerRow} style={rowsStyles}>
          {headingData &&
            headingData.map((value, index) => {
              return (
                <p className={Styles.headerText} key={index}>
                  {value}
                </p>
              );
            })}
        </div>
      </div>

      <div
        className={Styles.body}
        id={`targetElement_${props.type}`}
        style={{
          overflow: shouldEnableScrolling ? "scroll" : "hidden",
          positin: "relative",
        }}
      >
        {props.loadingData && props.currentPage == 1
          ? loadingDataRowUi(5)
          : bodyData &&
            bodyData.map((value, index) => {
              let rowId = value?.valOb?.id;
              let selectId = `${rowId}`;
              return (
                <div className={Styles.rowWrapper}>
                  {props.selectMultiple ? (
                    <input
                      type="radio"
                      className={Styles.selectRadio}
                      checked={props.isRowSelected(selectId)}
                    />
                  ) : (
                    ""
                  )}

                  <div
                    className={classnames(
                      Styles.bodyRow,
                      props.isRowSelected(selectId)
                        ? Styles.bodyRowSelected
                        : ""
                    )}
                    style={rowsStyles}
                    key={index}
                    onClick={(e) => {
                      if (props.selectMultiple) {
                        props.selectRow(selectId);
                      } else {
                        if (
                          e.target.getAttribute("class") &&
                          e.target
                            .getAttribute("class")
                            .search("DataTable_bodyRow") > -1
                        )
                          props.showInfoModalHandler(value.valOb);
                      }
                    }}
                  >
                    {" "}
                    {value.rowData.map((row, rowIndex) => {
                      let isOtherOption = false;
                      if (row?.props?.className?.search("otherOptions") > -1) {
                        isOtherOption = true;
                      }
                      return (
                        <div
                          key={rowIndex}
                          className={isOtherOption ? Styles.otherOptions : ""}
                        >
                          {row}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        {props.loadingData && props.currentPage != 1 ? loadingDataRowUi(1) : ""}
        <div ref={lastChildOfTable}></div>
      </div>
    </div>
  );
}

export default DataTable;
