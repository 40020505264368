import React, { useState, useEffect, Fragment, useRef } from "react";
import Styles from "./UptimeWidget.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import { Navigation, Pagination, Virtual, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import devzaLogo from "../../../../../assets/images/logos/devzaLogo.svg";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/virtual";
import "swiper/css/pagination";
import SearchIcon from "../../../../../assets/images/icons/searchIcon.svg";
import classnames from "classnames";
import noDataImage from "../../../../../assets/images/icons/uptimeNoDataImage.svg";
import LoadingElement from "../../../../common/LoadingElement/LoadingElement";
import LoadingElement2 from "../../../../common/LoadinElement2/LoadingElement2";
import {
  getDowntime,
  getDowntimeList,
  getDowntimeListGraph,
  downtimeRemove,
  downtimeSave,
  resetDowntimeData,
} from "../../../../../reducers/uptimeReducer";
import saveIcon from "../../../../../assets/images/icons/saveIcon.svg";
import savedIcon from "../../../../../assets/images/icons/savedIcon.svg";
import trashIcon from "../../../../../assets/images/icons/trashIcon.svg";

function UptimeWidget(props) {
  let dispatch = useDispatch();

  const sliderRef = useRef(null);
  let [downtimeListGraphData, updateDowntimeListGraphData] = useState([]);
  let downtimeList = useSelector((state) => {
    return state.uptimeReducer.downtimeList;
  });
  let downtimeGraph = useSelector((state) => {
    return state.uptimeReducer.downtimeGraph;
  });
  let domainDowntime = useSelector((state) => {
    return state.uptimeReducer.downtime;
  });
  let [isDataLoading, changeDataLoading] = useState(false);

  let domainDowntimeLoading = useSelector((state) => {
    return state.uptimeReducer.downtimeLoading;
  });

  let downtimeRemoveData = useSelector((state) => {
    return state.uptimeReducer.downtimeRemove;
  });

  let downtimeSaveData = useSelector((state) => {
    return state.uptimeReducer.downtimeSave;
  });

  //Error Objects
  // let downtimeListError = useSelector((state) => {
  //   return state.uptimeReducer.downtimeListError;
  // });

  // let downtimeGraphError = useSelector((state) => {
  //   return state.uptimeReducer.downtimeGraphError;
  // });

  let downtimeError = useSelector((state) => {
    return state.uptimeReducer.downtimeError;
  });

  // let downtimeRemoveError = useSelector((state) => {
  //   return state.uptimeReducer.downtimeRemoveError;
  // });

  // let downtimeSaveError = useSelector((state) => {
  //   return state.uptimeReducer.downtimeSaveError;
  // });

  useEffect(() => {
    if (downtimeError) {
      props.changeAlertBoxData((oldState) => {
        let temp = { ...oldState };
        temp.isVisible = true;
        temp.message = "Something went wrong, Please try again";
        temp.type = "danger";
        return temp;
      });
    }
  }, [downtimeError]);

  useEffect(() => {
    changeDataLoading(domainDowntimeLoading);
  }, [domainDowntimeLoading]);

  useEffect(() => {
    dispatch(getDowntimeList());
  }, []);

  useEffect(() => {
    //TODO: CHANGE STATUS TO ONLINE/OFFLINE rather than status code in api
    //TODO: add logo/domain name in api
    if (domainDowntime) {
      if (domainDowntime.domain_id) {
        getGraph(domainDowntime.domain_id);
      }
    }
  }, [domainDowntime]);

  useEffect(() => {
    if (downtimeList && downtimeList.length > 0) {
      downtimeList.forEach((val) => {
        getGraph(val.id);
      });
      if (downtimeSave) {
        goToSlide(downtimeList.length);
      }
    }
  }, [downtimeList]);

  useEffect(() => {
    if (downtimeGraph) {
      updateDowntimeListGraphData((oldState) => {
        let temp = [...oldState];
        let alreadyPresent = 0;
        temp.forEach((val) => {
          if (val.domain == downtimeGraph.domain) {
            alreadyPresent = 1;
            val = downtimeGraph;
          }
        });
        if (alreadyPresent == 0) {
          temp.push(downtimeGraph);
        }
        return temp;
      });
    }
  }, [downtimeGraph]);

  useEffect(() => {
    if (downtimeRemoveData || downtimeSaveData) {
      dispatch(getDowntimeList());
    }
  }, [downtimeRemoveData, downtimeSaveData]);

  function getGraph(domain_id = null) {
    if (domain_id) {
      //TODO : update api to fetch multiple domain id data
      //TODO : add domain id as well in api
      dispatch(getDowntimeListGraph({ id: domain_id }));
    }
  }

  const dummyGraphData = [
    {
      pingTime: "0.3",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.4",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.5",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },

    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.7",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-02 09:15:02",
    },
    {
      pingTime: "0.4",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "1",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.1",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.5",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.1",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.6",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
  ];

  const goToSlide = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
  };

  function getGraphDataFromDomainName(domainName) {
    let found = false;
    if (domainName) {
      downtimeListGraphData.forEach((val) => {
        if (domainName == val.domain) {
          found = val;
        }
      });
    }
    return found;
  }

  function searchDomain() {
    let domainToSearch = document.getElementById("domainToSearch").value;
    if (domainToSearch) {
      dispatch(getDowntime(domainToSearch));
    }
  }

  function uptimeUI({
    domain,
    status,
    graphData,
    responseTime = null,
    domain_id,
  }) {
    return (
      <div className={Styles.infoContainer}>
        <div className={Styles.infoHeader}>
          {domain ? (
            <div className={Styles.domainNameContainer}>
              <p className={Styles.domainText}>{domain}</p>
              {!domain_id ? (
                <img
                  src={saveIcon}
                  alt="save"
                  className={Styles.saveIcon}
                  onClick={() => {
                    dispatch(downtimeSave(getDomainNameInput()));
                  }}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {
            //TODO:no logo in api
            /* <img src={devzaLogo} alt="logo" className={Styles.logo} /> */
          }
          <div className={Styles.infoSection}>
            {status || isDataLoading ? (
              <div className={Styles.infoBox}>
                <p className={Styles.infoHeading}>Status</p>
                <p className={classnames(Styles.infoText, Styles.statusText)}>
                  {isDataLoading ? <LoadingElement2 /> : status}
                </p>
              </div>
            ) : (
              ""
            )}

            {/* TODO: no uptime percent in api and response time */}
            {graphData ? (
              <Fragment>
                <div className={Styles.infoBox}>
                  <p className={Styles.infoHeading}>Response time</p>
                  <p className={Styles.infoText}>
                    {graphData.avgResponseTime}ms
                  </p>
                </div>
                <div className={Styles.infoBox}>
                  <p className={Styles.infoHeading}>Uptime</p>
                  <p className={Styles.infoText}>{graphData.uptime}%</p>
                </div>
              </Fragment>
            ) : responseTime ? (
              <div className={Styles.infoBox}>
                <p className={Styles.infoHeading}>Response time</p>
                <p className={Styles.infoText}>{responseTime}ms</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {graphData ? (
          <div className={Styles.graphSection}>
            <ResponsiveContainer>
              <AreaChart
                data={graphData.results}
                margin={{ top: 0, right: 0, left: -20, bottom: 20 }}
              >
                <defs>
                  <linearGradient id="uptimeData" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={"#725AFF"} stopOpacity={0.7} />
                    <stop
                      offset="90%"
                      stopColor={"#725AFF"}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  hide={true}
                  dataKey="date"
                  axisLine={false}
                  style={{
                    fontFamily: "inherit",
                    fill: "var(--newGrey)",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                  padding={{ left: 10 }}
                  tickMargin={20}
                />
                <YAxis
                  hide={true}
                  tickSize={3}
                  minTickGap={0}
                  axisLine={false}
                  tickMargin={0}
                  ticks={getTicksArray(graphData.results)}
                  style={{
                    fontFamily: "inherit",
                    fill: "var(--newGrey)",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                />

                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="pingTime"
                  stroke={"#00000"}
                  fillOpacity={1}
                  fill="url(#uptimeData)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className={Styles.graphSection}>
            {isDataLoading ? (
              <LoadingElement styles={{ width: "100%" }} />
            ) : (
              <div className={Styles.noDataContainer}>
                <img
                  src={noDataImage}
                  alt="noData"
                  className={Styles.noDataImage}
                />
                <p>No Data available </p>
                <p>Try again after some time</p>
              </div>
            )}

            <ResponsiveContainer>
              <AreaChart
                data={dummyGraphData}
                margin={{ top: 0, right: 0, left: -20, bottom: 20 }}
              >
                <defs>
                  <linearGradient
                    id="uptimeData_temp"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor={"#725AFF"} stopOpacity={0.4} />
                    <stop
                      offset="90%"
                      stopColor={"#725AFF"}
                      stopOpacity={0.3}
                    />
                  </linearGradient>
                </defs>
                <XAxis
                  hide={true}
                  dataKey="date"
                  axisLine={false}
                  style={{
                    fontFamily: "inherit",
                    fill: "var(--newGrey)",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                  padding={{ left: 10 }}
                  tickMargin={20}
                />
                <YAxis
                  hide={true}
                  tickSize={5}
                  tickCount={3}
                  minTickGap={5}
                  axisLine={false}
                  tickMargin={20}
                  style={{
                    fontFamily: "inherit",
                    fill: "var(--newGrey)",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                />

                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="pingTime"
                  stroke={"#00000"}
                  fillOpacity={1}
                  fill="url(#uptimeData_temp)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        )}
        <div className={Styles.footer}>
          {domain_id ? (
            <img
              src={trashIcon}
              alt="remove"
              className={Styles.trashIcon}
              onClick={() => {
                dispatch(downtimeRemove(domain_id));
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  function getDomainNameInput() {
    return document.getElementById("domainToSearch").value;
  }

  function getTicksArray(graphData) {
    let highestNumber = 0;

    graphData.forEach((dataOb) => {
      if (parseInt(highestNumber) < parseInt(dataOb.pingTime)) {
        highestNumber = dataOb.pingTime;
      }
    });
    highestNumber = parseInt(highestNumber);
    return [0, highestNumber, highestNumber + 1];
  }

  function removeUserInput() {
    document.getElementById("domainToSearch").value = "";
  }

  function initiateDomainFetching() {
    searchDomain();
    changeDataLoading(true);

    goToSlide(0);
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 1,
    dotsClass: `slick-dots ${Styles.dots} uptimeSliderDots`,
    beforeChange: (oldIndex, newIndex) => {
      if (oldIndex == 0 && downtimeList.length != 0 && oldIndex !== newIndex) {
        removeUserInput();
        dispatch(resetDowntimeData());
      }
    },
    onReInit: () => {
      let btn = document.querySelector(".uptimeSliderDots button");
      if (btn) {
        btn.setAttribute("disabled", "true");
      }
    },
  };
  return (
    <div className={Styles.container}>
      <div className={Styles.searchBarContainer}>
        <div className={Styles.inputWrapper}>
          <input
            placeholder="www.devza.com"
            className={Styles.searchBox}
            id="domainToSearch"
            onKeyDown={(eventData) => {
              if (eventData.key == "Enter") {
                initiateDomainFetching();
              }
            }}
          />
          <img
            src={SearchIcon}
            className={Styles.searchIcon}
            alt="search"
            onClick={() => {
              initiateDomainFetching();
            }}
          />
        </div>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {domainDowntime
          ? uptimeUI({
              domain: getDomainNameInput(),
              status: domainDowntime.statusCode,
              responseTime: domainDowntime.reqTime,
              domain_id: domainDowntime.domain_id,
              graphData: getGraphDataFromDomainName(getDomainNameInput()),
            })
          : uptimeUI({
              domain: "",
              domain_id: null,
              status: null,
              responseTime: null,
              isLoading: true,
              graphData: null,
            })}

        {downtimeList &&
          downtimeList.map((value, index) => {
            let graphData = getGraphDataFromDomainName(value.domain);
            return uptimeUI({
              domain: value.domain,
              status: value.monitor.status,
              graphData,
              domain_id: value.id,
            });
          })}
      </Slider>

      <div className={Styles.footer}></div>
    </div>
  );
}

export default UptimeWidget;
