import React, { useState, useEffect, useRef } from "react";
import Styles from "./Panel.module.css";
import addIcon from "../../../assets/images/icons/addIcon.svg";
import downArrow from "../../../assets/images/icons/dropdown_arrow_1.svg";
// import collapseIcon from "../../../assets/images/icons/collapseIcon.svg";
import collapseIcon from "../../../assets/images/icons/downArrow_1.svg";
import sortIcon from "../../../assets/images/icons/sortIcon.svg";
import userDefaultIcon from "../../../assets/images/icons/defaultUserIcon.svg";
import clockIcon from "../../../assets/images/icons/clock.svg";
import pinIcon from "../../../assets/images/icons/pinIcon.svg";
import attachmentIcon from "../../../assets/images/icons/attachmentIcon.svg";
import commentIcon from "../../../assets/images/icons/commentIcon.svg";
import FormButton from "../../Elements/FormButton";
import Graph from "./Graph/Graph";
import DataTable from "../../common/DataTable/DataTable";
import DropdownMenu from "../../common/DropdownMenu/DropdownMenu";
import classnames from "classnames";
import uptrendIcon from "../../../assets/images/icons/uptrendIcon.svg";
import downtrendIcon from "../../../assets/images/icons/downtrendIcon.svg";
import crossIcon from "../../../assets/images/icons/crossIcon.svg";
import addAssigneeIcon from "../../../assets/images/icons/addAssigneeIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../../assets/images/icons/trashIcon.svg";
import addIconPlus from "../../../assets/images/icons/addIcon_plus.svg";
import AlertBox from "../../../components/common/AlertBox_1/AlertBox_1";
import addTagIcon from "../../../assets/images/icons/addTagIcon.svg";
import searchIcon from "../../../assets/images/icons/searchIcon.svg";
import optionsIcon from "../../../assets/images/icons/panelOptionsIcon.svg";
import pinIcon_1 from "../../../assets/images/icons/pinIcon_1.svg";
import checkIcon from "../../../assets/images/icons/checkIcon.svg";
import interliningIcon from "../../../assets/images/icons/interliningIcon.svg";
import trashRedIcon from "../../../assets/images/icons/trashRedIcon.svg";
import {
  getTaskStats,
  getTaskList,
  getTaskCategoryList,
  getTaskTagList,
  editTask,
  getTaskInfo,
  pinTask,
  detachTask,
  deleteTask,
  addTaskComment,
  removeTaskComment,
} from "../../../reducers/taskReducer";
import {
  getBugStats,
  getBugList,
  getBugCategoryList,
  getBugTagList,
  editBug,
  getBugInfo,
  pinBug,
  detachBug,
  deleteBug,
  addBugComment,
  removeBugComment,
} from "../../../reducers/bugReducer";

import { getMembers, getChannels } from "../../../reducers/teamReducer";
import moment from "moment/moment";
import utils from "../../../utils";
import nodataImage from "../../../assets/images/other_images/Reading Book.png";
import AddModal from "./AddModal/AddModal";
import InfoModal from "./InfoModal/InfoModal";
function Panel(props) {
  const dispatch = useDispatch();
  let addAssigneeInputElement = useRef(null);
  let [currentPage, changeCurrentPage] = useState(1);

  //States
  const [alertBox, changeAlertBox] = useState({
    isVisible: false,
    type: "success",
    message: "",
  });
  const [panelType, changePanelType] = useState(null);
  let [dropdownActive, changeDropdownState] = useState({
    status: false,
    id: null,
  });
  let [allDataDropdown, changeAllDataDropdown] = useState({
    status: false,
    selected: 2,
  });

  let [selectedFilters, updatedSelectedFilters] = useState([]);

  let [showAddModal, changeAddModalState] = useState(false);
  let [showInfoModal, changeInfoModalState] = useState({
    show: false,
    value: null,
  });
  let [taskListArr, changeTaskListArr] = useState([]);
  let [bugListArr, changeBugListArr] = useState([]);

  let [addAssigneeDropdown, changeAddAssigneeDropdown] = useState({
    isActive: false,
    selected: [],
    id: null,
    type: null,
  });

  let [editTagDropdown, changeEditTagDropdown] = useState({
    isActive: false,
    selected: null,
    id: null,
    type: null,
  });

  let [isTaskInfoLoading, changeTaskInfoLoading] = useState(null);
  let [isBugInfoLoading, changeBugInfoLoading] = useState(null);

  let [addAssigneeInput, changeAddAssigneeInput] = useState("");
  let [isAddAssigneeFocused, changeAddAssigneeFocus] = useState(false);
  let [searchTag, changeSearchTag] = useState("");

  let [otherOptionDropdown, changeOtherOptionDropdown] = useState({
    id: null,
    isActive: false,
    type: null,
  });

  let [changePriorityDropdown, updateChangePriorityDropdown] = useState({
    id: null,
    isActive: false,
    type: null,
  });

  let [selectMultiple, changeSelectMultiple] = useState(false);
  let [selectedRowsId, changeSelectedRowsId] = useState([]);
  //general selectors
  const members = useSelector((state) => state.teamReducer.getMembersData);
  const profile = useSelector((state) => state.profileReducer.profileData);
  const channels = useSelector((state) => state.teamReducer.getChannel);
  //Task selectors
  const taskStats = useSelector((state) => state.taskReducer.taskStats);
  const taskStatsLoading = useSelector(
    (state) => state.taskReducer.taskStatsLoading
  );
  const taskPanelError = useSelector(
    (state) => state.taskReducer.taskPanelError
  );
  let taskList = useSelector((state) => state.taskReducer.taskList);

  let taskListLoading = useSelector(
    (state) => state.taskReducer.taskListLoading
  );
  let taskTagList = useSelector((state) => state.taskReducer.taskTags);
  let taskGraphDataError = useSelector(
    (state) => state.taskReducer.graphDataError
  );
  let taskCategory = useSelector((state) => state.taskReducer.taskCategory);
  let addTaskResult = useSelector((state) => state.taskReducer.addTask);
  let updateTaskResult = useSelector((state) => state.taskReducer.editTask);
  let updateTaskLoading = useSelector(
    (state) => state.taskReducer.editTaskLoading
  );
  let taskInfo = useSelector((state) => state.taskReducer.taskInfo);
  let taskInfoLoading = useSelector(
    (state) => state.taskReducer.taskInfoLoading
  );
  let pinTaskResult = useSelector((state) => state.taskReducer.pinTask);
  let pinTaskLoading = useSelector((state) => state.taskReducer.pinTaskLoading);
  let detachTaskResult = useSelector((state) => state.taskReducer.detachTask);
  let detachTaskLoading = useSelector(
    (state) => state.taskReducer.detachTaskLoading
  );
  let deleteTaskResult = useSelector((state) => state.taskReducer.deleteTask);
  let addTaskCommentResult = useSelector(
    (state) => state.taskReducer.addComment
  );
  let removeTaskCommentResult = useSelector(
    (state) => state.taskReducer.removeComment
  );
  let addTaskAttachmentResult = useSelector(
    (state) => state.taskReducer.addTaskAttachment
  );
  //bug selectors
  const bugStats = useSelector((state) => state.bugReducer.bugStats);
  const bugStatsLoading = useSelector(
    (state) => state.bugReducer.bugStatsLoading
  );
  const bugPanelError = useSelector((state) => state.bugReducer.bugPanelError);
  let bugList = useSelector((state) => state.bugReducer.bugList);
  let bugListLoading = useSelector((state) => state.bugReducer.bugListLoading);
  let bugTagList = useSelector((state) => state.bugReducer.bugTags);
  let bugGraphDataError = useSelector(
    (state) => state.bugReducer.graphDataError
  );
  let bugCategory = useSelector((state) => state.bugReducer.bugCategory);
  let addBugResult = useSelector((state) => state.bugReducer.addBug);
  let updateBugResult = useSelector((state) => state.bugReducer.editBug);
  let updateBugLoading = useSelector(
    (state) => state.bugReducer.editBugLoading
  );
  let bugInfo = useSelector((state) => state.bugReducer.bugInfo);
  let bugInfoLoading = useSelector((state) => state.bugReducer.bugInfoLoading);
  let pinBugResult = useSelector((state) => state.bugReducer.pinBug);
  let pinBugLoading = useSelector((state) => state.bugReducer.pinBugLoading);
  let detachBugResult = useSelector((state) => state.bugReducer.detachBug);
  let detachBugLoading = useSelector(
    (state) => state.bugReducer.detachBugLoading
  );
  let deleteBugResult = useSelector((state) => state.bugReducer.deleteBug);
  let addBugCommentResult = useSelector((state) => state.bugReducer.addComment);
  let removeBugCommentResult = useSelector(
    (state) => state.bugReducer.removeComment
  );

  let addBugAttachmentResult = useSelector(
    (state) => state.bugReducer.addBugAttachment
  );

  useEffect(() => {
    // console.log(currentPage);
    if (currentPage != 1) fetchList();
  }, [currentPage]);

  useEffect(() => {
    if (taskPanelError || bugPanelError) {
      changeTaskInfoLoading(null);
      showAlert("Something went wrong.Try Again.", "danger");
    }
    if (taskGraphDataError || bugGraphDataError) {
      showAlert("Something went wrong.Try Again.", "danger");
    }
  }, [taskGraphDataError, taskPanelError, bugPanelError, bugGraphDataError]);

  useEffect(() => {
    if (deleteTaskResult && deleteTaskResult.id) {
      showAlert(
        `Task #${deleteTaskResult.id} deleted successfully.`,
        "success"
      );
      removeDataFromList(deleteTaskResult.id);

      if (
        showInfoModal?.value?.id == deleteTaskResult.id &&
        showInfoModal.show
      ) {
        hideInfoModalHandler();
      }
    }
  }, [deleteTaskResult]);

  useEffect(() => {
    if (deleteBugResult && deleteBugResult.id) {
      showAlert(`Bug #${deleteBugResult.id} deleted successfully.`, "success");
      removeDataFromList(deleteBugResult.id);

      if (
        showInfoModal?.value?.id == deleteBugResult.id &&
        showInfoModal.show
      ) {
        hideInfoModalHandler();
      }
    }
  }, [deleteBugResult]);

  //fetch task/bug info after update
  useEffect(() => {
    if (updateTaskResult && updateTaskResult.status == "success") {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: updateTaskResult.id,
          },
        ])
      );
    }
  }, [updateTaskResult]);

  useEffect(() => {
    if (updateBugResult && updateBugResult.status == "success") {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: updateBugResult.id,
          },
        ])
      );
    }
  }, [updateBugResult]);

  useEffect(() => {
    if (addTaskCommentResult?.id) {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: addTaskCommentResult?.id || null,
          },
        ])
      );
    }
  }, [addTaskCommentResult]);

  useEffect(() => {
    if (addTaskAttachmentResult?.id) {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: addTaskAttachmentResult?.id || null,
          },
        ])
      );
    }
  }, [addTaskAttachmentResult]);

  useEffect(() => {
    if (addBugCommentResult?.id) {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: addBugCommentResult?.id || null,
          },
        ])
      );
    }
  }, [addBugCommentResult]);

  useEffect(() => {
    if (addBugAttachmentResult?.id) {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: addBugAttachmentResult?.id || null,
          },
        ])
      );
    }
  }, [addBugAttachmentResult]);

  useEffect(() => {
    if (removeTaskCommentResult?.id) {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: removeTaskCommentResult?.id || null,
          },
        ])
      );
    }
  }, [removeTaskCommentResult]);

  useEffect(() => {
    if (removeBugCommentResult?.id) {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: removeBugCommentResult?.id || null,
          },
        ])
      );
    }
  }, [removeBugCommentResult]);

  useEffect(() => {
    if (pinTaskLoading.id || detachTaskLoading.id) {
      changeTaskInfoLoading(pinTaskLoading.id || detachTaskLoading.id);
    }
  }, [pinTaskLoading, detachTaskLoading]);

  useEffect(() => {
    if (pinBugLoading.id || detachBugLoading.id) {
      changeBugInfoLoading(pinBugLoading.id || detachBugLoading.id);
    }
  }, [pinBugLoading, detachBugLoading]);

  useEffect(() => {
    if (isTaskInfoLoading && pinTaskResult) {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: pinTaskResult?.id || null,
          },
        ])
      );
    }
  }, [pinTaskResult]);

  useEffect(() => {
    if (isBugInfoLoading && pinBugResult) {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: pinBugResult?.id || null,
          },
        ])
      );
    }
  }, [pinBugResult]);

  useEffect(() => {
    if (isTaskInfoLoading && detachTaskResult) {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: detachTaskResult?.id || null,
          },
        ])
      );
    }
  }, [detachTaskResult]);

  useEffect(() => {
    if (!selectMultiple) {
      changeSelectedRowsId([]);
    }
  }, [selectMultiple]);

  useEffect(() => {
    if (isBugInfoLoading && detachBugResult) {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: detachBugResult?.id || null,
          },
        ])
      );
    }
  }, [detachBugResult]);

  useEffect(() => {
    if (taskInfo) {
      if (
        taskInfo[0].task_status == "COMPLETED" &&
        allDataDropdown.selected == 2
      ) {
        removeDataFromList(taskInfo[0].id);
      } else {
        changeTaskListArr((oldState) => {
          let temp = [...oldState];
          let newTaskArr = temp.map((taskOb, index) => {
            if (taskOb.id == taskInfo[0].id) {
              return taskInfo[0];
            } else {
              return taskOb;
            }
          });
          return newTaskArr;
        });

        if (showInfoModal?.value?.id == taskInfo[0].id && showInfoModal.show) {
          changeInfoModalState({
            value: taskInfo[0],
            show: true,
          });
        }
      }
    }
  }, [taskInfo]);

  useEffect(() => {
    if (bugInfo) {
      if (
        bugInfo[0].bug_status == "COMPLETED" &&
        allDataDropdown.selected == 2
      ) {
        removeDataFromList(bugInfo[0].id);
      } else {
        changeBugListArr((oldState) => {
          let temp = [...oldState];
          let newBugArr = temp.map((bugOb, index) => {
            if (bugOb.id == bugInfo[0].id) {
              return bugInfo[0];
            } else {
              return bugOb;
            }
          });

          return newBugArr;
        });

        if (showInfoModal?.value?.id == bugInfo[0].id && showInfoModal.show) {
          changeInfoModalState({
            value: bugInfo[0],
            show: true,
          });
        }
      }
    }
  }, [bugInfo]);

  useEffect(() => {
    if (taskInfo) {
      changeTaskInfoLoading(null);
    }
  }, [taskListArr]);

  useEffect(() => {
    if (bugInfo) {
      changeBugInfoLoading(null);
    }
  }, [bugListArr]);

  // useEffect(() => {
  //   if (addTaskResult && addTaskResult.task) {
  //     changeTaskListArr((oldState) => {
  //       let temp = [addTaskResult.task, ...oldState];
  //       return temp;
  //     });
  //     changeAddModalState(false);
  //     showAlert(
  //       `Task added successfully: #${addTaskResult.task_id}`,
  //       "success"
  //     );
  //   }
  // }, [addTaskResult]);

  // useEffect(() => {
  //   if (addBugResult && addBugResult.bug) {
  //     changeBugListArr((oldState) => {
  //       let temp = [addBugResult.bug, ...oldState];
  //       return temp;
  //     });
  //     changeAddModalState(false);
  //     showAlert(`Bug raised successfully: #${addBugResult.bug_id}`, "success");
  //   }
  // }, [addBugResult]);

  useEffect(() => {
    if (taskList && taskList?.tasks) {
      // changeTaskListArr(taskList?.tasks);
      changeTaskListArr((oldState) => {
        let temp = [...oldState, ...taskList?.tasks];
        let uniqueValue = temp.filter((dataOb, index) => {
          let indx = temp.findIndex((val) => {
            if (val.id == dataOb.id) {
              return true;
            } else {
              return false;
            }
          });
          if (indx == index) {
            return true;
          }
        });
        return uniqueValue;
      });
    }

    // if (taskList && taskList?.next) {
    //   changeNextPageIndicator(true);
    // } else {
    //   changeNextPageIndicator(false);
    // }
  }, [taskList]);

  useEffect(() => {
    if (bugList && bugList?.bugs) {
      // changeTaskListArr(taskList?.tasks);
      changeBugListArr((oldState) => {
        let temp = [...oldState, ...bugList?.bugs];
        let uniqueValue = temp.filter((dataOb, index) => {
          let indx = temp.findIndex((val) => {
            if (val.id == dataOb.id) {
              return true;
            } else {
              return false;
            }
          });
          if (indx == index) {
            return true;
          }
        });
        return uniqueValue;
      });
    }
  }, [bugList]);

  useEffect(() => {
    changePanelType(props.type);
    changeCurrentPage(1);
    if (!members) {
      dispatch(getMembers());
    }
    if (!channels) {
      dispatch(getChannels());
    }
    if (props.type == "task") {
      dispatch(getTaskStats());

      dispatch(
        getTaskList([
          { key: "page", value: 1 },
          { key: "status", value: "PENDING" },
        ])
      );
      // dispatch(getTaskCategoryList());
      dispatch(getTaskTagList());
    } else if (props.type == "bug") {
      dispatch(getBugStats());

      dispatch(
        getBugList([
          { key: "page", value: 1 },
          { key: "status", value: "PENDING" },
        ])
      );
      // dispatch(getBugCategoryList());
      dispatch(getBugTagList());
    }
  }, [props.type]);

  useEffect(() => {
    changeTaskListArr([]);
    changeBugListArr([]);
    changeCurrentPage(1);
    fetchList(1);
  }, [allDataDropdown.selected, selectedFilters]);

  useEffect(() => {
    console.log(taskPanelError);
  }, [taskPanelError]);

  function fetchMorePagesOfList() {
    if (props.type == "task") {
      if (taskList && taskList?.next) {
        changeCurrentPage(currentPage + 1);
      }
    } else {
      if (bugList && bugList?.next) {
        changeCurrentPage(currentPage + 1);
      }
    }
  }

  function editMultipleListItems(type) {
    if (props.type == "task") {
    } else {
    }
  }

  function removeMultipleDataFromList() {
    if (selectedRowsId.length > 0) {
      let idsCommaSeparated = selectedRowsId.join(",");
      if (props.type == "task") {
        dispatch(deleteTask(idsCommaSeparated));
      } else if (props.type == "bug") {
      }
    }
  }

  function removeDataFromList(id) {
    if (props.type == "task") {
      changeTaskListArr((oldState) => {
        let temp = [...oldState];
        let newTaskArr = temp.filter((taskOb, index) => {
          if (taskOb.id != id) {
            return taskOb;
          }
        });
        return newTaskArr;
      });
    } else {
      changeBugListArr((oldState) => {
        let temp = [...oldState];
        let newBugArr = temp.filter((bugOb, index) => {
          if (bugOb.id != id) {
            return bugOb;
          }
        });
        return newBugArr;
      });
    }
  }
  function addComment(id, commentText) {
    if (id && commentText) {
      props.type == "task"
        ? dispatch(addTaskComment({ task_id: id, commentText }))
        : dispatch(addBugComment({ bug_id: id, commentText }));
    }
  }

  function removeComment(id, comment_id) {
    if (id && comment_id) {
      props.type == "task"
        ? dispatch(removeTaskComment({ task_id: id, comment_id }))
        : dispatch(removeBugComment({ bug_id: id, comment_id }));
    }
  }

  function selectRow(id) {
    let isAlreadyPresent = false;
    selectedRowsId.forEach((selectedId) => {
      if (selectedId == id) {
        isAlreadyPresent = true;
      }
    });
    if (!isAlreadyPresent) {
      changeSelectedRowsId((oldState) => {
        let temp = [...oldState];
        temp.push(id);
        return temp;
      });
    } else {
      changeSelectedRowsId((oldState) => {
        let temp = [];
        oldState.forEach((selectedId) => {
          if (selectedId != id) {
            temp.push(selectedId);
          }
        });
        return temp;
      });
    }
  }

  function selectAll() {
    let list = props.type == "task" ? taskListArr : bugListArr;
    if (isAllSelected()) {
      changeSelectedRowsId((oldState) => {
        let temp = [];
        return temp;
      });
    } else {
      changeSelectedRowsId((oldState) => {
        let temp = [...oldState];
        list.forEach((dataOb) => {
          temp.push(dataOb?.id ?? null);
        });
        let tempSet = new Set(temp);
        let tempArr = Array.from(tempSet);
        return tempArr;
      });
    }
  }

  function isAllSelected() {
    let list = props.type == "task" ? taskListArr : bugListArr;
    if (selectedRowsId.length == list.length) {
      return true;
    } else {
      return false;
    }
  }
  function isRowSelected(id) {
    let isAlreadyPresent = false;
    selectedRowsId.forEach((selectedId) => {
      if (selectedId == id) {
        isAlreadyPresent = true;
      }
    });
    return isAlreadyPresent;
  }
  function showInfoModalHandler(val) {
    if (!selectMultiple) {
      changeInfoModalState({
        show: true,
        value: val,
      });
    }
  }

  function hideInfoModalHandler(val) {
    changeInfoModalState({
      show: false,
      value: null,
    });
  }
  function hideOtherOptionsDropdown() {
    changeOtherOptionDropdown({
      id: null,
      isActive: false,
      type: null,
    });
  }

  function hideChangePriorityDropdown() {
    updateChangePriorityDropdown({
      id: null,
      isActive: false,
      type: null,
    });
  }
  //show alert
  function showAlert(message, type) {
    changeAlertBox({
      message,
      type,
      isVisible: true,
    });
  }

  function getCategoryNameFromId(id) {
    let name = "";
    if (props.type == "task") {
      taskCategory &&
        taskCategory.forEach((catOb) => {
          if (catOb.id == id) {
            name = catOb.name;
          }
        });
    } else if (props.type == "bug") {
      bugCategory &&
        bugCategory.forEach((catOb) => {
          if (catOb.id == id) {
            name = catOb.name;
          }
        });
    }
    return name;
  }

  function hideAddAssigneeDropdown(type, id) {
    changeAddAssigneeFocus(false);
    changeAddAssigneeDropdown({
      isActive: false,
      selected: [],
      id: null,
    });
  }

  function showAddAssigneeDropdown(id, type = null) {
    changeAddAssigneeDropdown({
      isActive: true,
      selected: addAssigneeDropdown.selected,
      type,
      id,
    });
  }

  function addAssigneeInSelectedList(id) {
    changeAddAssigneeDropdown((oldState) => {
      let temp = { ...oldState };
      temp.selected.push(id);
      let tempSet = new Set(temp.selected);
      temp.selected = Array.from(tempSet);
      return temp;
    });
    addAssigneeInputElement.current?.focus();
  }

  function removeSelectedAssignee(userid) {
    changeAddAssigneeDropdown((oldState) => {
      let temp = { ...oldState };
      let newListArr = [];
      temp.selected.forEach((val) => {
        if (val != userid) {
          newListArr.push(val);
        }
      });

      temp.selected = newListArr;

      return temp;
    });
  }

  function getChannelNameFromId(channelId) {
    if (channels && channelId) {
      let name = null;
      channels.forEach((ob) => {
        if (ob.channel_id == channelId) {
          name = ob.channel_name;
        }
      });
      return name;
    } else {
      return null;
    }
  }
  //to convert channel data into particular format for rendering
  function processChannelData(data) {
    if (data) {
      let newData = [];
      data.forEach((ob) => {
        newData.push({ id: ob.channel_id, name: ob.channel_name });
      });
      return newData;
    }
  }

  //fetch list from api based on filters
  function fetchList(currentPageArg = null) {
    let filterArr = [];
    let selectedOb = fetchSelectedDataFromAllFilterInfo(
      allDataDropdown.selected
    );
    if (selectedOb && selectedOb.type) {
      let selectedDataOb = {};
      selectedDataOb.key = selectedOb.type;
      selectedDataOb.value = selectedOb.value;

      filterArr = [...getFiltersInFormat(), selectedDataOb];
    } else {
      filterArr = getFiltersInFormat();
    }
    filterArr = [
      { key: "page", value: currentPageArg ? currentPageArg : currentPage },
      ...filterArr,
    ];

    if (props.type == "task") {
      dispatch(getTaskList(filterArr));
    } else if (props.type == "bug") {
      dispatch(getBugList(filterArr));
    }
  }

  function addFilterData(data) {
    updatedSelectedFilters((oldState) => {
      let isAlreadyPresent = false;
      let temp = [...oldState];
      temp.forEach((ob) => {
        if (
          ob.filterType_id == data.filterType_id &&
          ob.filter_id == data.filter_id
        ) {
          isAlreadyPresent = true;
        }
      });
      if (!isAlreadyPresent) {
        temp.push(data);
      }
      return temp;
    });
  }

  function removeFilterData(data) {
    updatedSelectedFilters((oldState) => {
      let temp = [];

      oldState.forEach((ob) => {
        if (
          !(
            ob.filterType_id == data.filterType_id &&
            ob.filter_id == data.filter_id
          )
        ) {
          temp.push(ob);
        }
      });

      return temp;
    });
  }

  function fetchMyUserId() {
    let userId = null;
    if (profile) {
      profile?.teams.forEach((teamOb) => {
        if (teamOb.team_id == utils.fetchVariable("current_team")) {
          userId = teamOb.userid;
        }
      });
    }
    return userId;
  }

  function closeDropdown() {
    changeDropdownState({
      status: false,
      id: null,
    });
  }

  function closeAllDataDropdown() {
    changeAllDataDropdown({
      status: false,
      selected: allDataDropdown.selected,
    });
  }

  function openFilterDropdown(id) {
    changeDropdownState({
      status: true,
      id: id,
    });
  }

  function closeFilterDropdown() {
    changeDropdownState({
      status: false,
      id: null,
    });
  }

  function trimText(text, trimBy = 110) {
    let appendedStr = "";
    if (text && text.length > trimBy) {
      appendedStr = ".....";
    }

    return text.substr(text, trimBy) + appendedStr;
  }

  function getAssigneeArrayToRender(asigneeArr) {
    let assigneeArrNew = asigneeArr.map((val, index) => {
      return fetchMyUserId() != val.userid ? (
        <img
          key={index}
          src={val.avatar ?? userDefaultIcon}
          alt="user"
          style={{
            width: "1.5em",
            borderRadius: "5em",
            height: "1.5em",
            marginLeft: index > 0 ? "-10px" : "",
          }}
        />
      ) : null;
    });
    let filteredArray = assigneeArrNew.filter((value) => value);
    return filteredArray.length > 0 ? filteredArray : null;
  }

  function findDataInArray(arr, data) {
    let isFound = false;
    arr.forEach((val) => {
      if (val == data) {
        isFound = true;
      }
    });
    return isFound;
  }

  function getMemberObFromUserid(userid) {
    let found = false;
    members &&
      members.forEach((userOb) => {
        if (userid == userOb.userid) {
          found = userOb;
        }
      });
    return found;
  }

  //get position of input element to render dropdown properly
  function getAddAssigneeInputElementPosition() {
    if (addAssigneeInputElement && addAssigneeInputElement.current) {
      console.log(addAssigneeInputElement.current.getBoundingClientRect());
    }
  }

  function isAssigneeAlreadyPresent(assigneeArr, userid) {
    let found = false;
    assigneeArr.forEach((assigneeOb) => {
      if (assigneeOb.userid == userid) {
        found = true;
      }
    });
    return found;
  }

  function removeAssignee(assigneesArr, assigneeToRemove) {
    let newAssigneeIdList = [];
    assigneesArr.forEach((assigneeOb) => {
      if (assigneeOb.userid != assigneeToRemove) {
        newAssigneeIdList.push(assigneeOb.userid);
      }
    });
    return newAssigneeIdList;
  }
  function addAssigneeUi(val, location) {
    let mainId = val.id;
    let styles = {
      zIndex: 5,
      top: "20px",
    };

    if (location == "infoModal") {
      styles.right = 0;
    }

    return (
      <DropdownMenu
        id={`assigneeDropdown_${val.id}_${location}`}
        styles={styles}
        isActive={
          val.id == addAssigneeDropdown.id &&
          location == addAssigneeDropdown.type
            ? addAssigneeDropdown.isActive
            : false
        }
        updateDropdownState={() => {
          hideAddAssigneeDropdown("", val.id);
        }}
      >
        {val.assigned_to &&
          val.assigned_to.map((assigneeOb, index) => {
            return (
              <div className={Styles.addAssigneeDropdownItem}>
                <img src={assigneeOb.avatar ?? userDefaultIcon} alt="user" />
                <p>{assigneeOb.name ? assigneeOb.name : "Unnamed"} </p>
                {fetchMyUserId() != assigneeOb.userid ? (
                  <img
                    src={deleteIcon}
                    alt="remove"
                    onClick={() => {
                      let newAssigneeList = removeAssignee(
                        val.assigned_to,
                        assigneeOb.userid
                      );
                      props.type == "task"
                        ? editTaskFunc({
                            assigned_to: newAssigneeList.join(","),
                            task_id: mainId,
                          })
                        : editBugFunc({
                            assigned_to: newAssigneeList.join(","),
                            bug_id: mainId,
                          });
                    }}
                  />
                ) : (
                  ""
                )}{" "}
              </div>
            );
          })}

        <div className={Styles.addAssigneeDropdownItemAdd}>
          <div className={Styles.selectedAssigneeList}>
            {addAssigneeDropdown.selected.length > 0 &&
              addAssigneeDropdown.selected.map((val, index) => {
                return (
                  <div style={{ position: "relative" }} key={index}>
                    <img
                      className={Styles.userAvatar}
                      src={getMemberObFromUserid(val).avatar ?? userDefaultIcon}
                      alt="usr"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSelectedAssignee(val);
                        document
                          .getElementById(
                            `addAssigneeInput_${mainId}_${location}`
                          )
                          ?.focus();
                      }}
                    />
                    <p>Click to remove</p>
                  </div>
                );
              })}
            <div style={{ display: "flex" }}>
              <img src={addIconPlus} alt="add" style={{ width: "0.875em" }} />
              <input
                type="text"
                ref={addAssigneeInputElement}
                placeholder="Add"
                id={`addAssigneeInput_${mainId}_${location}`}
                onKeyDown={(e) => {
                  if (e && e.key && e.key == "Enter") {
                    if (addAssigneeDropdown.selected.length > 0) {
                      let newAssigneeList = [
                        ...addAssigneeDropdown.selected,
                        removeAssignee(val.assigned_to, ""),
                      ];
                      props.type == "task"
                        ? editTaskFunc({
                            assigned_to: newAssigneeList.join(","),
                            task_id: mainId,
                          })
                        : editBugFunc({
                            assigned_to: newAssigneeList.join(","),
                            bug_id: mainId,
                          });
                    }
                  }
                }}
                onChange={(e) => {
                  changeAddAssigneeInput(e.target.value);
                }}
                onFocus={() => {
                  changeAddAssigneeFocus(true);
                }}
              />
            </div>
          </div>
          <DropdownMenu
            styles={{ top: "4.2em" }}
            isActive={
              val.id == addAssigneeDropdown.id &&
              isAddAssigneeFocused &&
              location == addAssigneeDropdown.type
                ? addAssigneeDropdown.isActive
                : false
            }
            id={`assigneeDropdown_${val.id}_${location}`}
            updateDropdownState={hideAddAssigneeDropdown}
          >
            {members &&
              members.map((memberOb, index) => {
                return memberOb.name
                  .toLowerCase()
                  .search(addAssigneeInput.toLowerCase()) > -1 &&
                  !findDataInArray(
                    addAssigneeDropdown.selected,
                    memberOb.userid
                  ) &&
                  !isAssigneeAlreadyPresent(
                    val.assigned_to,
                    memberOb.userid
                  ) ? (
                  <div
                    className={Styles.addAssigneeDropdownItem}
                    onClick={(e) => {
                      e.stopPropagation();
                      addAssigneeInSelectedList(memberOb.userid);
                      document
                        .getElementById(
                          `addAssigneeInput_${mainId}_${location}`
                        )
                        ?.focus();
                    }}
                  >
                    <img
                      style={{ borderRadius: "5em" }}
                      src={memberOb.avatar ?? userDefaultIcon}
                      alt="user"
                    />
                    <p>{memberOb.name}</p>
                  </div>
                ) : (
                  ""
                );
              })}
          </DropdownMenu>
        </div>
      </DropdownMenu>
    );
  }
  function hideEditTagDropdown() {
    changeEditTagDropdown({
      isActive: false,
      selected: null,
      id: null,
      type: null,
    });
  }
  function editTagUi(val, location) {
    let mainId = val.id;
    let styles = {
      zIndex: 5,
      top: "20px",
    };
    if (location == "infoModal") {
      styles.right = 0;
    }
    return (
      <DropdownMenu
        id={`editTagDropdown_${mainId}_${location}`}
        styles={styles}
        isActive={
          mainId == editTagDropdown.id && location == editTagDropdown.type
            ? editTagDropdown.isActive
            : false
        }
        updateDropdownState={() => {
          hideEditTagDropdown();
        }}
      >
        <div className={Styles.tagSearchBox}>
          <img src={searchIcon} alt="search" />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              changeSearchTag(e.target.value);
            }}
          />
        </div>
        {props.type == "task"
          ? taskTagList &&
            taskTagList.map((tagOb, index) => {
              return tagOb.name.toLowerCase().search(searchTag.toLowerCase()) >
                -1 && tagOb.id != val.tag_id ? (
                <div
                  className={Styles.editTagDropdownItem}
                  onClick={() => {
                    hideEditTagDropdown();
                    editTaskFunc({
                      tag_id: tagOb.id,
                      task_id: mainId,
                    });
                  }}
                >
                  <div
                    style={{
                      background: tagOb.color,
                      width: "0.75em",
                      height: "0.75em",
                      borderRadius: "5em",
                    }}
                  ></div>
                  <p>{tagOb.name}</p>
                </div>
              ) : (
                ""
              );
            })
          : bugTagList &&
            bugTagList.map((tagOb, index) => {
              return tagOb.name.toLowerCase().search(searchTag.toLowerCase()) >
                -1 && tagOb.id != val.tag_id ? (
                <div
                  className={Styles.editTagDropdownItem}
                  onClick={() => {
                    hideEditTagDropdown();
                    editBugFunc({
                      tag_id: tagOb.id,
                      bug_id: mainId,
                    });
                  }}
                >
                  <div
                    style={{
                      background: tagOb.color,
                      width: "0.75em",
                      height: "0.75em",
                      borderRadius: "5em",
                    }}
                  ></div>
                  <p>{tagOb.name}</p>
                </div>
              ) : (
                ""
              );
            })}

        {/* 
        <div className={Styles.addAssigneeDropdownItemAdd}>
          <DropdownMenu
            styles={{ top: "4.2em" }}
            isActive={
              val.id == editTagDropdown.id ? editTagDropdown.isActive : false
            }
            id={`editTagDropdown_${val.id}`}
            updateDropdownState={hideEditTagDropdown}
          >
            {members &&
              members.map((memberOb, index) => {
                return memberOb.name
                  .toLowerCase()
                  .search(addAssigneeInput.toLowerCase()) > -1 &&
                  !findDataInArray(
                    addAssigneeDropdown.selected,
                    memberOb.userid
                  ) &&
                  !isAssigneeAlreadyPresent(
                    val.assigned_to,
                    memberOb.userid
                  ) ? (
                  <div
                    className={Styles.addAssigneeDropdownItem}
                    onClick={(e) => {
                      e.stopPropagation();
                      addAssigneeInSelectedList(memberOb.userid);
                      document
                        .getElementById(`addAssigneeInput_${mainId}`)
                        ?.focus();
                    }}
                  >
                    <img
                      style={{ borderRadius: "5em" }}
                      src={memberOb.avatar ?? userDefaultIcon}
                      alt="user"
                    />
                    <p>{memberOb.name}</p>
                  </div>
                ) : (
                  ""
                );
              })}
          </DropdownMenu>
        </div> */}
      </DropdownMenu>
    );
  }

  function getTagDetails(tagId) {
    let tagDetails = null;
    if (props.type == "task") {
      taskTagList &&
        taskTagList.forEach((tagOb) => {
          if (tagOb.id == tagId) {
            tagDetails = tagOb;
          }
        });
    } else {
      bugTagList &&
        bugTagList.forEach((tagOb) => {
          if (tagOb.id == tagId) {
            tagDetails = tagOb;
          }
        });
    }
    return tagDetails;
  }

  function showAddTagDropdown(val, type = null) {
    changeEditTagDropdown({
      isActive: true,
      selected: null,
      id: val.id,
      type,
    });
  }

  function tagUI(val, location) {
    let idToUse = `editTagDropdown_${val.id}_${location}`;
    return (
      <div id={idToUse} style={{ position: "relative", width: "fit-content" }}>
        {val.tag_id ? (
          <div
            style={{
              width: "4.375em",
              padding: "0.375em 0",
              backgroundColor: getTagDetails(val.tag_id)?.color,
              borderRadius: "0.625em",
              cursor: "pointer",
            }}
            onClick={() => {
              showAddTagDropdown(val, location);
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: "0.625rem",
                fontWeight: "700",
                color: "var(--black)",
              }}
            >
              {getTagDetails(val.tag_id)?.name}
            </p>
          </div>
        ) : (
          <img
            src={addTagIcon}
            alt="addTag"
            className={Styles.addTagIcon}
            onClick={() => {
              showAddTagDropdown(val, location);
            }}
          />
        )}

        {editTagUi(val, location)}
      </div>
    );
  }

  function assigneeUI(val, location) {
    if (val && val.id) {
      let idToUse = `assigneeDropdown_${val.id}_${location}`;
      return (
        <div
          id={idToUse}
          style={{
            display: "flex",
            gap: "-10px",
            position: "relative",
          }}
        >
          <div
            onClick={() => showAddAssigneeDropdown(val.id, location)}
            style={{ cursor: "pointer" }}
          >
            {getAssigneeArrayToRender(val.assigned_to) ?? (
              <img
                onClick={() => showAddAssigneeDropdown(val.id, location)}
                src={addAssigneeIcon}
                alt="assign"
                className={Styles.addAssigneeIcon}
              />
            )}
          </div>

          {addAssigneeUi(val, location)}
        </div>
      );
    }
  }

  function optionsUI(val, location) {
    let styles = {};

    if (location == "infoModal") {
      styles.right = 0;
    }
    return (
      <div
        className="otherOptions"
        id={`otherOptions_${val.id}_${location}`}
        style={{
          position: "relative",
        }}
      >
        <img
          src={optionsIcon}
          alt="options"
          className={classnames(Styles.moreOptionsIcon)}
          style={{
            display:
              val.id == otherOptionDropdown.id && otherOptionDropdown.isActive
                ? "block"
                : "block",
          }}
          onClick={() => {
            changeOtherOptionDropdown({
              id: val.id,
              isActive: true,
              type: location,
            });
          }}
        />
        <DropdownMenu
          id={`otherOptions_${val.id}_${location}`}
          isActive={
            val.id == otherOptionDropdown.id &&
            location == otherOptionDropdown.type
              ? otherOptionDropdown.isActive
              : false
          }
          styles={styles}
          updateDropdownState={hideOtherOptionsDropdown}
        >
          <div
            className={Styles.otherOptionsDropdownItem}
            onClick={() => {
              props.type == "task"
                ? editTaskFunc({
                    mark_as_completed: 1,
                    task_id: val.id,
                  })
                : editBugFunc({
                    mark_as_completed: 1,
                    bug_id: val.id,
                  });

              hideOtherOptionsDropdown();
              hideInfoModalHandler();
            }}
          >
            <img src={checkIcon} alt="check" />
            {props.type == "task" ? (
              <p>mark complete</p>
            ) : (
              <p>mark as resolved</p>
            )}
          </div>
          <div
            className={Styles.otherOptionsDropdownItem}
            onClick={() => {
              hideOtherOptionsDropdown();
              if (val.pinned == 1) {
                props.type == "task"
                  ? dispatch(detachTask(val.id))
                  : dispatch(detachBug(val.id));
              } else {
                props.type == "task"
                  ? dispatch(pinTask(val.id))
                  : dispatch(pinBug(val.id));
              }
            }}
          >
            <img src={pinIcon_1} alt="pin" />
            <p>{val.pinned == 1 ? "unpin" : "pin"}</p>
          </div>
          <div
            className={Styles.otherOptionsDropdownItem}
            id={`changePriority_${val.id}_${location}`}
            onClick={() => {
              updateChangePriorityDropdown({
                id: val.id,
                isActive: true,
                type: location,
              });
            }}
          >
            <img src={interliningIcon} alt="inter" />
            <p>change priority</p>
            <DropdownMenu
              styles={{ top: "30px" }}
              id={`changePriority_${val.id}_${location}`}
              isActive={
                val.id == changePriorityDropdown.id &&
                location == changePriorityDropdown.type &&
                otherOptionDropdown.isActive
                  ? changePriorityDropdown.isActive
                  : false
              }
              updateDropdownState={hideChangePriorityDropdown}
            >
              {getPriorityDropdownItems().map((priorityOb, index) => {
                return priorityOb.type != val.priority ? (
                  <p
                    className={Styles.priorityDropdownItem}
                    onClick={() => {
                      hideOtherOptionsDropdown();
                      props.type == "task"
                        ? editTaskFunc({
                            task_id: val.id,
                            priority: priorityOb.type,
                          })
                        : editBugFunc({
                            bug_id: val.id,
                            priority: priorityOb.type,
                          });
                    }}
                  >
                    {priorityOb.title}
                  </p>
                ) : (
                  ""
                );
              })}
            </DropdownMenu>
          </div>
          <div
            className={Styles.otherOptionsDropdownItem}
            onClick={() => {
              props.type == "task"
                ? dispatch(deleteTask(val.id))
                : dispatch(deleteBug(val.id));
            }}
          >
            <img src={trashRedIcon} alt="trash" />
            <p style={{ color: "var(--alertRed )" }}>delete</p>
          </div>
        </DropdownMenu>
      </div>
    );
  }

  //TODO: MAKE THIS PROPERLY USING API DATA
  function getTableRowData() {
    let dataArr = [];
    if (props.type == "task") {
      taskListArr &&
        taskListArr.forEach((val) => {
          let dataToPush = null;
          if (
            ((updateTaskLoading && updateTaskLoading.id == val?.id) ||
              (taskInfoLoading && taskInfoLoading.id == val?.id) ||
              isTaskInfoLoading == val?.id) &&
            showInfoModal.show == false
          ) {
            dataToPush = [
              <div
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "18.0625em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  borderRadius: "10em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "4.375em",
                  height: "1.5em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
            ];
          } else {
            dataToPush = [
              <img
                src={val.created_by.avatar ?? userDefaultIcon}
                alt="user"
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  borderRadius: "5em",
                }}
                onClick={() => {
                  showInfoModalHandler(val);
                }}
              />,
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  color: "var(--lightBlack)",
                  margin: 0,
                }}
                onClick={() => {
                  showInfoModalHandler(val);
                }}
              >
                {" "}
                {val.id ?? ""}
              </p>,
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "700",
                  color: "var(--black)",
                  textAlign: "center",
                  margin: 0,
                }}
                onClick={() => {
                  showInfoModalHandler(val);
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    utils.processText(trimText(val.task_text), {
                      getMemberObFromUserid,
                    }) ?? "",
                }}
              ></p>,
              assigneeUI(val, "tile"),
              tagUI(val, "tile"),
              <div
                onClick={() => {
                  showInfoModalHandler(val);
                }}
              >
                {val.due_date ? (
                  <p
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "600",
                      color: "var(--lightBlack)",
                      margin: 0,
                    }}
                  >
                    <img
                      src={clockIcon}
                      alt="clock"
                      style={{ width: "0.8em", height: "0.8em" }}
                    />{" "}
                    {moment(val.due_date).format("MMMM DD")}
                  </p>
                ) : (
                  ""
                )}
              </div>,
              <div style={{ display: "flex", gap: "1.25em" }}>
                {val.pinned == 1 ? (
                  <img src={pinIcon} alt="pin" style={{ width: "0.75em" }} />
                ) : (
                  ""
                )}

                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "var(--lightBlack)",
                  }}
                >
                  <img
                    src={attachmentIcon}
                    alt="attachment"
                    style={{ width: "0.75em" }}
                  />{" "}
                  {val.attachments?.length ?? 0}
                </p>
                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "var(--lightBlack)",
                  }}
                >
                  <img
                    src={commentIcon}
                    alt="comment"
                    style={{ width: "0.75em" }}
                  />{" "}
                  {val.comments?.length ?? 0}
                </p>
              </div>,
              optionsUI(val, "tile"),
            ];
          }
          dataArr.push({ valOb: val, rowData: dataToPush });
        });
    } else if (props.type == "bug") {
      bugListArr &&
        bugListArr.forEach((val) => {
          let dataToPush = null;
          if (
            ((updateBugLoading && updateBugLoading.id == val?.id) ||
              (bugInfoLoading && bugInfoLoading.id == val?.id) ||
              isBugInfoLoading == val?.id) &&
            showInfoModal.show == false
          ) {
            dataToPush = [
              <div
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  borderRadius: "20em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "2.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "4.0625em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "1.5em",
                  height: "1.5em",
                  borderRadius: "10em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "4.375em",
                  height: "1.5em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "18.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
              <div
                style={{
                  width: "3.75em",
                  height: "0.625em",
                  borderRadius: "0.5em",
                  background: "var(--lightGrey)",
                }}
              ></div>,
            ];
          } else {
            dataToPush = [
              <img
                src={val.created_by.avatar ?? userDefaultIcon}
                alt="user"
                style={{
                  width: "2.5em",
                  height: "2.5em",
                  borderRadius: "5em",
                }}
              />,
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  color: "var(--lightBlack)",
                  margin: 0,
                }}
                onClick={() => {
                  showInfoModalHandler(val);
                }}
              >
                {" "}
                {val.id ?? ""}
              </p>,
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "700",
                  color: "var(--black)",
                  textAlign: "left",
                  margin: 0,
                }}
              >
                {trimText(val.bug_text) ?? ""}
              </p>,
              // <div>
              //   <p
              //     style={{
              //       fontSize: "0.875rem",
              //       fontWeight: "600",
              //       color: "var(--lightBlack)",
              //       margin: 0,
              //     }}
              //   >
              //     {val.category_id
              //       ? getCategoryNameFromId(val.category_id)
              //       : ""}
              //   </p>
              // </div>,
              tagUI(val, "tile"),
              assigneeUI(val, "tile"),
              <div
                onClick={() => {
                  showInfoModalHandler(val);
                }}
              >
                {val.due_date ? (
                  <p
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: "600",
                      color: "var(--lightBlack)",
                      margin: 0,
                    }}
                  >
                    <img
                      src={clockIcon}
                      alt="clock"
                      style={{ width: "0.8em", height: "0.8em" }}
                    />{" "}
                    {moment(val.due_date).format("MMMM DD")}
                  </p>
                ) : (
                  ""
                )}
              </div>,
              <div style={{ display: "flex", gap: "1.25em" }}>
                {val.pinned == 1 ? (
                  <img src={pinIcon} alt="pin" style={{ width: "0.75em" }} />
                ) : (
                  ""
                )}
                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "var(--lightBlack)",
                  }}
                >
                  <img
                    src={attachmentIcon}
                    alt="attachment"
                    style={{ width: "0.75em" }}
                  />{" "}
                  {val.attachments?.length ?? 0}
                </p>
                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    color: "var(--lightBlack)",
                  }}
                >
                  <img
                    src={commentIcon}
                    alt="comment"
                    style={{ width: "0.75em" }}
                  />{" "}
                  {val.comments?.length ?? 0}
                </p>
              </div>,
              optionsUI(val, "tile"),
            ];
          }
          dataArr.push({ valOb: val, rowData: dataToPush });
        });
    }

    return dataArr;
  }

  function summaryBox(type) {
    switch (type) {
      case "newTask":
        return (
          <div className={Styles.summaryBox}>
            <p
              className={Styles.summaryBoxHeading}
              style={{ color: "#096344" }}
            >
              New Tasks
            </p>
            <p className={Styles.summaryText} style={{ color: "#096344" }}>
              {taskStats?.new?.today ?? 0}
            </p>
            <div className={Styles.summaryDetails}>
              <p className={Styles.trendNumber}>
                {taskStats?.new ? (
                  <span
                    style={{
                      color:
                        taskStats?.new?.lastmonth?.direction == "up"
                          ? "var(--greenAlert)"
                          : "var(--alertRed)",
                    }}
                  >
                    {taskStats?.new?.lastmonth?.direction == "up" ? "+" : "-"}
                    {taskStats?.new?.lastmonth?.percent ?? 0}%
                  </span>
                ) : (
                  0
                )}
                {taskStats?.new?.lastmonth?.direction == "up" ? (
                  <img
                    src={uptrendIcon}
                    alt="up"
                    className={Styles.trendIcon}
                  />
                ) : (
                  <img
                    src={downtrendIcon}
                    alt="down"
                    className={Styles.trendIcon}
                  />
                )}
              </p>
              <p className={Styles.infoTimeText}>last month</p>
            </div>
          </div>
        );
      case "completed":
        return (
          <div className={Styles.summaryBox}>
            <p
              className={Styles.summaryBoxHeading}
              style={{ color: "#00499F" }}
            >
              Completed
            </p>
            <p className={Styles.summaryText} style={{ color: "#00499F" }}>
              {taskStats?.completed?.today ?? 0}
            </p>
            <div className={Styles.summaryDetails}>
              <p className={Styles.trendNumber}>
                {taskStats?.completed ? (
                  <span
                    style={{
                      color:
                        taskStats?.completed?.lastmonth?.direction == "up"
                          ? "var(--greenAlert)"
                          : "var(--alertRed)",
                    }}
                  >
                    {taskStats?.completed?.lastmonth?.direction == "up"
                      ? "+"
                      : "-"}
                    {taskStats?.completed?.lastmonth?.percent ?? 0}%
                  </span>
                ) : (
                  0
                )}
                {taskStats?.completed?.lastmonth?.percent == "up" ? (
                  <img
                    src={uptrendIcon}
                    alt="up"
                    className={Styles.trendIcon}
                  />
                ) : (
                  <img
                    src={downtrendIcon}
                    alt="down"
                    className={Styles.trendIcon}
                  />
                )}
              </p>
              <p className={Styles.infoTimeText}>last month</p>
            </div>
          </div>
        );
      case "priority":
        return (
          <div className={Styles.summaryBox}>
            <p
              className={Styles.summaryBoxHeading}
              style={{ color: "#CB083B" }}
            >
              High Priority
            </p>
            <p className={Styles.summaryText} style={{ color: "#CB083B" }}>
              {taskStats?.highPriority?.today ?? 0}
            </p>
            <div className={Styles.summaryDetails}>
              <p className={Styles.trendNumber}>
                {taskStats?.highPriority ? (
                  <span
                    style={{
                      color:
                        taskStats?.highPriority?.lastmonth?.direction == "up"
                          ? "var(--greenAlert)"
                          : "var(--alertRed)",
                    }}
                  >
                    {taskStats?.highPriority?.lastmonth?.direction == "up"
                      ? "+"
                      : "-"}
                    {taskStats?.highPriority?.lastmonth?.percent ?? 0}%
                  </span>
                ) : (
                  0
                )}
                {taskStats?.highPriority?.lastmonth?.direction == "up" ? (
                  <img
                    src={uptrendIcon}
                    alt="up"
                    className={Styles.trendIcon}
                  />
                ) : (
                  <img
                    src={downtrendIcon}
                    alt="down"
                    className={Styles.trendIcon}
                  />
                )}
              </p>
              <p className={Styles.infoTimeText}>last month</p>
            </div>
          </div>
        );
      case "newBug":
        return (
          <div className={Styles.summaryBox}>
            <p
              className={Styles.summaryBoxHeading}
              style={{ color: "#29A4A4" }}
            >
              New Bugs
            </p>
            <p className={Styles.summaryText} style={{ color: "#29A4A4" }}>
              {bugStats?.new?.today ?? 0}
            </p>
            <div className={Styles.summaryDetails}>
              <p className={Styles.trendNumber}>
                {bugStats?.new ? (
                  <span
                    style={{
                      color:
                        bugStats?.new?.lastmonth?.direction == "up"
                          ? "var(--greenAlert)"
                          : "var(--alertRed)",
                    }}
                  >
                    {bugStats?.new?.lastmonth?.direction == "up" ? "+" : "-"}
                    {bugStats?.new?.lastmonth?.percent ?? 0}%
                  </span>
                ) : (
                  0
                )}
                {bugStats?.new?.lastmonth?.direction == "up" ? (
                  <img
                    src={uptrendIcon}
                    alt="up"
                    className={Styles.trendIcon}
                  />
                ) : (
                  <img
                    src={downtrendIcon}
                    alt="down"
                    className={Styles.trendIcon}
                  />
                )}
              </p>
              <p className={Styles.infoTimeText}>last month</p>
            </div>
          </div>
        );
      case "resolved":
        return (
          <div className={Styles.summaryBox}>
            <p
              className={Styles.summaryBoxHeading}
              style={{ color: "#1C2E8C" }}
            >
              Resolved
            </p>
            <p className={Styles.summaryText} style={{ color: "#1C2E8C" }}>
              {bugStats?.completed?.today ?? 0}
            </p>
            <div className={Styles.summaryDetails}>
              <p className={Styles.trendNumber}>
                {bugStats?.completed ? (
                  <span
                    style={{
                      color:
                        bugStats?.completed?.lastmonth?.direction == "up"
                          ? "var(--greenAlert)"
                          : "var(--alertRed)",
                    }}
                  >
                    {bugStats?.completed?.lastmonth?.direction == "up"
                      ? "+"
                      : "-"}
                    {bugStats?.completed?.lastmonth?.percent ?? 0}%
                  </span>
                ) : (
                  0
                )}
                {bugStats?.completed?.lastmonth?.percent == "up" ? (
                  <img
                    src={uptrendIcon}
                    alt="up"
                    className={Styles.trendIcon}
                  />
                ) : (
                  <img
                    src={downtrendIcon}
                    alt="down"
                    className={Styles.trendIcon}
                  />
                )}
              </p>
              <p className={Styles.infoTimeText}>last month</p>
            </div>
          </div>
        );
      case "severity":
        return (
          <div className={Styles.summaryBox}>
            <p
              className={Styles.summaryBoxHeading}
              style={{ color: "#CE5613" }}
            >
              High Severity
            </p>
            <p className={Styles.summaryText} style={{ color: "#CE5613" }}>
              {bugStats?.highPriority?.today ?? 0}
            </p>
            <div className={Styles.summaryDetails}>
              <p className={Styles.trendNumber}>
                {bugStats?.highPriority ? (
                  <span
                    style={{
                      color:
                        bugStats?.highPriority?.lastmonth?.direction == "up"
                          ? "var(--greenAlert)"
                          : "var(--alertRed)",
                    }}
                  >
                    {bugStats?.highPriority?.lastmonth?.direction == "up"
                      ? "+"
                      : "-"}
                    {bugStats?.highPriority?.lastmonth?.percent ?? 0}%
                  </span>
                ) : (
                  0
                )}
                {bugStats?.highPriority?.lastmonth?.direction == "up" ? (
                  <img
                    src={uptrendIcon}
                    alt="up"
                    className={Styles.trendIcon}
                  />
                ) : (
                  <img
                    src={downtrendIcon}
                    alt="down"
                    className={Styles.trendIcon}
                  />
                )}
              </p>
              <p className={Styles.infoTimeText}>last month</p>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function loadingSummaryBoxUi(index) {
    return (
      <div
        className={classnames(Styles.summaryBox, Styles.loadingSummaryBox)}
        key={index}
      >
        <div className={Styles.loadingElement} style={{ width: "50%" }}></div>
        <div
          className={Styles.loadingElement}
          style={{ width: "20%", marginTop: "1em" }}
        ></div>
      </div>
    );
  }

  function fetchSelectedDataFromAllFilterInfo(id) {
    let fetchedOb = null;
    filterDropdown.forEach((ob) => {
      if (ob.id == id) {
        fetchedOb = ob;
      }
    });
    return fetchedOb;
  }

  function addButton() {
    return (
      <FormButton
        type="style1"
        styles={{
          width: "8.1em",
          padding: "0.78em",
          fontSize: "1.125rem",
          height: "2.8em",
          textTransform: "capitalize",
          borderRadius: "0.55em",
        }}
        handler={() => {
          changeAddModalState(true);
        }}
      >
        <img
          src={addIcon}
          alt="add"
          style={{ width: "0.73em", height: "0.73em" }}
        />{" "}
        {panelType == "task" ? "add task" : panelType == "bug" ? "add bug" : ""}
      </FormButton>
    );
  }

  function noDataUi() {
    return (
      <div className={Styles.noDataContainer}>
        <img src={nodataImage} alt="no data" />
        <p
          style={{
            fontSize: "1.875rem",
            fontWeight: "600",
            color: "var(--black)",
            margin: 0,
          }}
        >
          Hi, {profile?.name}
        </p>
        <p
          style={{
            fontSize: "1.125rem",
            fontWeight: "500",
            color: "var(--grey)",
            margin: 0,
            marginTop: "0.5em",
            marginBottom: "1em",
          }}
        >
          There’re no {props.type == "task" ? "task" : "bug"} for you to see
          yet. If you want to create new, just click
        </p>
        {addButton()}
      </div>
    );
  }

  function getPriorityDropdownItems() {
    return [
      { type: 1, title: "low" },
      { type: 2, title: "medium" },
      { type: 3, title: "high" },
    ];
  }
  let filterDropdown =
    props.type == "task"
      ? [
          {
            id: "0",
            title: "completed",
            type: "status",
            value: "COMPLETED",
          },
          {
            id: "1",
            title: "due this week",
            type: "dueAfter",
            value: moment().startOf("week").format("YYYY-MM-DD"),
          },
          {
            id: "2",
            title: "my tasks",
            type: "status",
            value: "PENDING",
          },
          {
            id: "3",
            title: "task assigned by me",
            type: "assignedBy",
            value: fetchMyUserId(),
          },
          {
            id: "4",
            title: "high priority",
            type: "priority",
            value: 3,
          },
        ]
      : [
          {
            id: "0",
            title: "resolved",
            type: "status",
            value: "COMPLETED",
          },
          {
            id: "1",
            title: "due this week",
            type: "dueAfter",
            value: moment().startOf("week").format("YYYY-MM-DD"),
          },
          {
            id: "2",
            title: "my bugs",
            type: "status",
            value: "PENDING",
          },
          {
            id: "3",
            title: "bug assigned by me",
            type: "assignedBy",
            value: fetchMyUserId(),
          },
          {
            id: "4",
            title: "high severity",
            type: "priority",
            value: 3,
          },
        ];
  let filterData = [
    // {
    //   title: "category",
    //   key: "category_id",
    //   id: "category_id",
    //   childData: props.type == "task" ? taskCategory ?? [] : bugCategory ?? [],
    // },
    {
      title: "channel",
      key: "channel_id",
      id: "channel_id",
      childData: processChannelData(channels) ?? [],
    },

    {
      title: "person",
      key: "assignedBy",
      id: "person_id",
      childData: members ?? [],
    },
    {
      title: "tags",
      key: "tag_id",
      id: "tag_id",
      childData: taskTagList ?? [],
    },
  ];

  function isFilterSelected({ filterType_id, filter_id }) {
    let isSelected = false;
    selectedFilters.forEach((ob) => {
      if (filterType_id == ob.filterType_id && filter_id == ob.filter_id) {
        isSelected = true;
      }
    });

    return isSelected;
  }

  function getFiltersInFormat() {
    let dataArr = [];
    filterData.forEach((filterOb) => {
      let obToPush = {
        key: filterOb.key,
        value: [],
      };
      selectedFilters.forEach((selectedFilterOb) => {
        if (selectedFilterOb.filterType_id == filterOb.id) {
          obToPush.value.push(selectedFilterOb.filter_id);
        }
      });
      if (obToPush.value.length > 0) {
        obToPush.value = obToPush.value.join(",");
        dataArr.push(obToPush);
      }
    });
    return dataArr;
  }

  function infoBoxProperties() {
    return {
      tagUI,
      assigneeUI,
      optionsUI,
      getCategoryNameFromId,
      isTaskInfoLoading,
      isBugInfoLoading,
      addComment,
      removeComment,
      type: props.type,
      getChannelNameFromId,
      getMemberObFromUserid,
    };
  }
  function editTaskFunc(data) {
    changeTaskInfoLoading(data.task_id);
    hideAddAssigneeDropdown();
    dispatch(editTask(data));
  }

  function editBugFunc(data) {
    changeBugInfoLoading(data.bug_id);
    hideAddAssigneeDropdown();
    dispatch(editBug(data));
  }

  return (
    <div className={Styles.container}>
      <AddModal
        type={props.type}
        show={showAddModal}
        changeAddModalState={changeAddModalState}
        changeTaskListArr={changeTaskListArr}
        showAlert={showAlert}
        changeBugListArr={changeBugListArr}
      />
      <InfoModal
        show={showInfoModal.show}
        infoModalData={showInfoModal}
        changeInfoModalState={hideInfoModalHandler}
        {...infoBoxProperties()}
      />
      <AlertBox
        isVisible={alertBox.isVisible}
        type={alertBox.type}
        message={alertBox.message}
        changeAlertState={changeAlertBox}
      />
      <div className={Styles.header}>
        <div className={Styles.headerInfoSection}>
          <div className={Styles.headerInfo_header}>
            <div className={Styles.headerInfo_headerText}>
              <p>
                {panelType == "task"
                  ? "My Task"
                  : panelType == "bug"
                  ? "My Bugs"
                  : ""}
              </p>
              <p>Welcome back,here's your activity today.</p>
            </div>
            {addButton()}
          </div>
          <div className={Styles.headerInfo_body}>
            {props.type == "task" ? (
              taskStatsLoading ? (
                [0, 1, 2].map((val, index) => {
                  return loadingSummaryBoxUi(index);
                })
              ) : (
                <>
                  {summaryBox("newTask")}
                  {summaryBox("completed")}
                  {summaryBox("priority")}
                </>
              )
            ) : props.type == "bug" ? (
              bugStatsLoading ? (
                [0, 1, 2].map((val, index) => {
                  return loadingSummaryBoxUi(index);
                })
              ) : (
                <>
                  {summaryBox("newBug")}
                  {summaryBox("resolved")}
                  {summaryBox("severity")}
                </>
              )
            ) : (
              ""
            )}
          </div>
        </div>
        <div className={Styles.graphSection}>
          <Graph type={props.type} />
        </div>
      </div>
      <div className={Styles.body} id="panelBody">
        <div className={Styles.bodyHeader}>
          <div
            className={Styles.bodyHeader_mainHeadingWrapper}
            id="allDataDropdown"
          >
            <p className={Styles.headingText}>
              {fetchSelectedDataFromAllFilterInfo(allDataDropdown.selected)
                ?.title ?? ""}
            </p>
            <p className={Styles.headingCountText}>
              {props.type == "task"
                ? taskList && taskList?.total
                  ? taskList.total
                  : 0
                : bugList && bugList?.total
                ? bugList.total
                : 0}
            </p>
            <img
              style={{ width: "1em" }}
              src={downArrow}
              alt="dropdown"
              onClick={() => {
                changeAllDataDropdown({
                  status: true,
                  selected: allDataDropdown.selected,
                });
              }}
            />
            <DropdownMenu
              isActive={allDataDropdown.status}
              updateDropdownState={closeAllDataDropdown}
              id="allDataDropdown"
              styles={{ top: "2.5em" }}
            >
              {filterDropdown.map((filterOb, index) => {
                return filterOb.id != allDataDropdown.selected ? (
                  <p
                    className={Styles.filterText}
                    onClick={() => {
                      changeAllDataDropdown({
                        status: false,
                        selected: filterOb.id,
                      });
                    }}
                  >
                    {filterOb.title}
                  </p>
                ) : (
                  ""
                );
              })}
            </DropdownMenu>
          </div>

          {selectMultiple ? (
            <div className={Styles.selectMenuContainer}>
              <div className={Styles.selectMenuWrapper}>
                <p className={Styles.selectMenuHead}>
                  {selectedRowsId.length + ` ${props.type}s selected`}
                </p>
                <div className={Styles.selectMenuBox}>
                  <div className={Styles.selectMenuItem}>
                    <img src={checkIcon} alt="check" />
                  </div>
                  <div className={Styles.selectMenuItem}>
                    <img src={pinIcon_1} alt="pin" />
                  </div>
                  <div className={Styles.selectMenuItem}>
                    <img src={interliningIcon} alt="priority" />
                  </div>
                  <div className={Styles.selectMenuItem}>
                    <img
                      src={trashRedIcon}
                      alt="delete"
                      onClick={() => {
                        removeMultipleDataFromList();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <button
              onClick={() => {
                changeSelectMultiple(!selectMultiple);
              }}
            >
              Select
            </button> */}

          <div className={Styles.filterWrapper}>
            <button
              onClick={() => {
                changeSelectMultiple(!selectMultiple);
              }}
              className={Styles.selectMultipleButton}
            >
              Select
            </button>
            {filterData.map((filterOb, index) => {
              return (
                <div
                  id={filterOb.id}
                  key={index}
                  style={{ position: "relative" }}
                >
                  <div
                    className={classnames(
                      dropdownActive.id == filterOb.id
                        ? Styles.filterBoxActive
                        : Styles.filterBox
                    )}
                    onClick={() => {
                      if (
                        dropdownActive.id == filterOb.id &&
                        dropdownActive.status
                      ) {
                        closeFilterDropdown(filterOb.id);
                      } else {
                        openFilterDropdown(filterOb.id);
                      }
                    }}
                  >
                    <p className={Styles.filterName}>{filterOb.title}</p>
                    <img
                      src={collapseIcon}
                      alt="collapse"
                      style={{ width: "0.8em" }}
                    />
                  </div>
                  {/* Render filter dropdown */}
                  <DropdownMenu
                    isActive={
                      dropdownActive.id == filterOb.id
                        ? dropdownActive.status
                        : false
                    }
                    updateDropdownState={closeDropdown}
                    id={filterOb.id}
                  >
                    {filterOb.childData.map((childOb, index) => {
                      let id =
                        filterOb.id == "filterOb" ? childOb.userid : childOb.id;
                      return (
                        <div className={Styles.dropdownWrapper}>
                          <div
                            onClick={() => {
                              if (
                                isFilterSelected({
                                  filterType_id: filterOb.id,
                                  filter_id: id,
                                })
                              ) {
                                removeFilterData({
                                  filterType_id: filterOb.id,
                                  filter_id: id,
                                });
                              } else {
                                addFilterData({
                                  filterType_id: filterOb.id,
                                  filter_id: id,
                                });
                              }
                            }}
                            className={
                              isFilterSelected({
                                filterType_id: filterOb.id,
                                filter_id: id,
                              })
                                ? Styles.filterSelected
                                : ""
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.2em",
                            }}
                          >
                            {childOb.color ? (
                              <div
                                style={{
                                  background: childOb.color,
                                  width: "0.75em",
                                  height: "0.75em",
                                  borderRadius: "5em",
                                }}
                              ></div>
                            ) : (
                              ""
                            )}
                            {childOb.avatar ? (
                              <img
                                src={childOb.avatar ?? userDefaultIcon}
                                style={{
                                  width: "1.0625em",
                                  height: "1.0625em",
                                  borderRadius: "5em",
                                }}
                                alt="user"
                              />
                            ) : (
                              ""
                            )}
                            <p
                              key={index}
                              className={classnames(Styles.filterText)}
                            >
                              {childOb.name}
                            </p>
                          </div>

                          {childOb.canDelete ? (
                            <img src={deleteIcon} alt="remove" />
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </DropdownMenu>
                </div>
              );
            })}

            <div className={Styles.filterBox}>
              <img src={sortIcon} alt="sort" className={Styles.sortIcon} />
            </div>
          </div>
        </div>
        <div className={Styles.dataTable}>
          {(props.type == "task" &&
            taskListArr &&
            taskListArr.length == 0 &&
            !taskListLoading) ||
          (props.type == "bug" &&
            bugListArr &&
            bugListArr.length == 0 &&
            !bugListLoading) ? (
            noDataUi()
          ) : (
            <DataTable
              selectRow={selectRow}
              isRowSelected={isRowSelected}
              rowData={getTableRowData()}
              selectAll={selectAll}
              isAllSelected={isAllSelected}
              selectMultiple={selectMultiple}
              loadingData={
                props.type == "task" ? taskListLoading : bugListLoading
              }
              currentPage={currentPage}
              showInfoModalHandler={showInfoModalHandler}
              type={props.type}
              fetch={fetchMorePagesOfList}
              parentId={"panelBody"}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Panel;
