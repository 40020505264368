import React, { useState } from "react";
import Styles from "./Webmaster.module.css";
import uptimeMonitorImage from "../../../assets/images/icons/uptimeMonitorImage.svg";
import ipReverseImage from "../../../assets/images/icons/ipReverseImage.svg";
import websiteRankImage from "../../../assets/images/icons/websiteRankImage.svg";
import UptimeMonitor from "./UptimeMonitor/UptimeMonitor";
import IPReverse from "./IPReverse/IPReverse";
import classnames from "classnames";

function Webmaster(props) {
  let navBoxData = [
    {
      id: "uptime",
      image: uptimeMonitorImage,
      title: "uptime monitor",
    },
    { id: "ipReverse", image: ipReverseImage, title: "IP reverse" },
    // {
    //   id: "websiteRank",
    //   image: websiteRankImage,
    //   title: "Website Rank",
    //   isDisabled: true,
    // },
  ];

  let [activeTab, changeActiveTab] = useState("uptime");

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.headerHeading}>
          <p>webmaster essentials</p>
          <p>Get webmaster tools at your fingertips</p>
        </div>
        <div className={Styles.headerBody}>
          {navBoxData.map((navOb, id) => {
            return (
              <div
                className={classnames(
                  Styles.navBox,
                  navOb?.isDisabled
                    ? Styles.navBoxDisabled
                    : Styles.navBoxActive,
                  navOb.id == activeTab ? Styles.activeNavBox : ""
                )}
                onClick={() => {
                  if (!navOb?.isDisabled) changeActiveTab(navOb.id);
                }}
              >
                <img src={navOb.image} alt="img" />
                <p>{navOb.title}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className={Styles.body}>
        {activeTab == "uptime" ? <UptimeMonitor /> : ""}
        {activeTab == "ipReverse" ? <IPReverse /> : ""}
      </div>
    </div>
  );
}

export default Webmaster;
