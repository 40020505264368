import React, { useState, useRef, useEffect } from "react";
import Styles from "./AddModal.module.css";
import FormButton from "../../../Elements/FormButton";
import defaultUserIcon from "../../../../assets/images/icons/defaultUserIcon.svg";
import collapseIcon from "../../../../assets/images/icons/dropdown_arrow_1.svg";
import redCrossIcon from "../../../../assets/images/icons/redCrossIcon.svg";
import closeIcon from "../../../../assets/images/icons/crossIcon.svg";
import exclamation from "../../../../assets/images/icons/exclamation.svg";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import DropdownMenu from "../../../common/DropdownMenu/DropdownMenu";
import {
  addTask,
  addTaskAttachment,
  getTaskInfo,
} from "../../../../reducers/taskReducer";
import {
  addBug,
  addBugAttachment,
  getBugInfo,
} from "../../../../reducers/bugReducer";
import addIcon from "../../../../assets/images/icons/addIcon.svg";
import trashRedIcon from "../../../../assets/images/icons/trashRedIcon.svg";
function AddModal(props) {
  const dispatch = useDispatch();
  const [isAdditionalFieldCollapsed, changeCollapse] = useState(true);
  const [showSuggestionDropdown, changeSuggestionDropdown] = useState(false);
  const [userSuggestionInput, changeUserSuggestionInput] = useState("");
  let [userSelectedAttachments, changeUserSelectedAttachments] = useState([]);
  let [selectedAssignee, changeSelectedAssignee] = useState([]);
  let [errorText, changeErrorText] = useState("");
  const elementRef = useRef(null);

  const members = useSelector((state) => state.teamReducer.getMembersData);

  //Task selectors
  let taskCategory = useSelector((state) => state.taskReducer.taskCategory);
  let addTaskResult = useSelector((state) => state.taskReducer.addTask);
  let addTaskLoading = useSelector((state) => state.taskReducer.addTaskLoading);
  let addTaskAttachmentResult = useSelector(
    (state) => state.taskReducer.addTaskAttachment
  );
  //bug selectors
  let bugCategory = useSelector((state) => state.bugReducer.bugCategory);
  let addBugLoading = useSelector((state) => state.bugReducer.addBugLoading);
  let addBugResult = useSelector((state) => state.bugReducer.addBug);
  let addBugAttachmentResult = useSelector(
    (state) => state.bugReducer.addBugAttachment
  );
  useEffect(() => {
    if (addTaskResult && addTaskResult.task) {
      let task_id = addTaskResult.task?.id;
      props.changeTaskListArr((oldState) => {
        let temp = [addTaskResult.task, ...oldState];
        return temp;
      });
      if (
        userSelectedAttachments &&
        userSelectedAttachments.length > 0 &&
        task_id
      ) {
        dispatch(addTaskAttachment({ task_id, file: userSelectedAttachments }));
      } else {
        props.changeAddModalState(false);
        props.showAlert(
          `Task added successfully: #${addTaskResult.task_id}`,
          "success"
        );
      }
    }
  }, [addTaskResult]);

  useEffect(() => {
    if (addTaskAttachmentResult && addTaskAttachmentResult?.id) {
      dispatch(
        getTaskInfo([
          {
            key: "task_id",
            value: addTaskAttachmentResult.id,
          },
        ])
      );
      props.changeAddModalState(false);
      if (userSelectedAttachments.length > 0) {
        props.showAlert(
          `Task added successfully: #${addTaskAttachmentResult.id}`,
          "success"
        );
        changeUserSelectedAttachments([]);
      }
    }
  }, [addTaskAttachmentResult]);

  useEffect(() => {
    if (addBugResult && addBugResult.bug) {
      let bug_id = addBugResult.bug?.id;
      props.changeBugListArr((oldState) => {
        let temp = [addBugResult.bug, ...oldState];
        return temp;
      });
      if (
        userSelectedAttachments &&
        userSelectedAttachments.length > 0 &&
        bug_id
      ) {
        dispatch(addBugAttachment({ bug_id, file: userSelectedAttachments }));
      } else {
        props.changeAddModalState(false);
        props.showAlert(`Bug added successfully: #${bug_id}`, "success");
      }
    }
  }, [addBugResult]);

  useEffect(() => {
    if (addBugAttachmentResult && addBugAttachmentResult?.id) {
      dispatch(
        getBugInfo([
          {
            key: "bug_id",
            value: addBugAttachmentResult.id,
          },
        ])
      );
      props.changeAddModalState(false);
      if (userSelectedAttachments.length > 0) {
        props.showAlert(
          `Bug added successfully: #${addBugAttachmentResult.id}`,
          "success"
        );
        changeUserSelectedAttachments([]);
      }
    }
  }, [addBugAttachmentResult]);

  useEffect(() => {
    let parentScrollElement = document.getElementById("dashboardLayout");
    const handleScroll = () => {
      const element = document.getElementById("addModalContainer");
      const elementOffsetTop = element && element.offsetTop;

      const windowScrollTop = parentScrollElement.scrollTop;

      element.style.marginTop = windowScrollTop + "px";
    };

    parentScrollElement.addEventListener("scroll", handleScroll);
    return () => {
      parentScrollElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (errorText) {
      setTimeout(() => {
        changeErrorText("");
      }, 3000);
    }
  }, [errorText]);

  useEffect(() => {
    fetchPreviewElements();
  }, [userSelectedAttachments]);

  useEffect(() => {
    if (!props.show) {
      resetAllFields();
      changeUserSelectedAttachments([]);
    }
  }, [props.show]);

  function resetAllFields() {
    if (document.getElementById("addTitle"))
      document.getElementById("addTitle").value = "";
    //optional
    changeSelectedAssignee([]);
    document.getElementById("addComments").value = "";
    document.getElementById("fileInputOriginal").value = "";
  }

  function checkIfAssigneeIsAlreadySelected(memberUserId) {
    let isAlreadyPresent = false;
    selectedAssignee.forEach((userId) => {
      if (userId == memberUserId) {
        isAlreadyPresent = true;
      }
    });
    return isAlreadyPresent;
  }

  function fetchObFromAssigneeUserId(userid) {
    let ob = {};
    members &&
      members.forEach((memberOb) => {
        if (userid == memberOb.userid) {
          ob = memberOb;
        }
      });
    return ob;
  }

  function removeFromSelectedAssignee(userid) {
    changeSelectedAssignee((oldState) => {
      let temp = [];
      oldState.forEach((userId) => {
        if (userId != userid) {
          temp.push(userId);
        }
      });
      return temp;
    });
  }

  function addTaskInitiator() {
    let title = document.getElementById("addTitle").value;

    //optional
    let priority = document.getElementById("addPriority").value;
    let dueDate = document.getElementById("addDate").value;
    // let category = document.getElementById("addCategory").value;
    // let comments = document.getElementById("addComments").value;

    let addTaskApproved = true;
    if (!title) {
      changeErrorText("Invalid Title");
      addTaskApproved = false;
    }
    if (selectedAssignee.length == 0) {
      addTaskApproved = false;
      changeErrorText("No member selected to assign task!");
    }

    if (addTaskApproved) {
      let addOb = {
        task_text: title,
        assigned_to: selectedAssignee.join(","),
      };
      if (!isAdditionalFieldCollapsed) {
        addOb = {
          due_date: dueDate,
          priority,
          // category_id: category,
          ...addOb,
        };
      }
      dispatch(addTask(addOb));
    }
  }

  function addBugInitiator() {
    // let category = document.getElementById("addCategory").value;
    let bugSummary = document.getElementById("bugSummary").value;
    //optional
    let priority = document.getElementById("addPriority").value;
    let dueDate = document.getElementById("addDate").value;

    // let comments = document.getElementById("addComments").value;
    // let bugDesc = document.getElementById("bugDescription").value;

    let addBugApproved = true;
    // if (!category) {
    //   changeErrorText("Invalid Category");
    //   addBugApproved = false;
    // }
    if (!bugSummary) {
      changeErrorText("Invalid Summary");
      addBugApproved = false;
    }
    if (selectedAssignee.length == 0) {
      addBugApproved = false;
      changeErrorText("No member selected to assign task!");
    }

    if (addBugApproved) {
      let addOb = {
        bug_text: bugSummary,

        assigned_to: selectedAssignee.join(","),
      };

      // if (category) {
      //   addOb = { ...addOb, category_id: category };
      // }
      if (!isAdditionalFieldCollapsed) {
        addOb = {
          due_date: dueDate,
          priority,
          ...addOb,
        };
      }
      dispatch(addBug(addOb));
    }
  }

  function selectAssignee(memberob) {
    changeUserSuggestionInput("");
    changeSelectedAssignee((oldState) => {
      let temp = [...oldState];
      let isAlreadyPresent = false;
      temp.forEach((userId) => {
        if (userId == memberob.userid) {
          isAlreadyPresent = true;
        }
      });
      if (!isAlreadyPresent) {
        temp.push(memberob.userid);
      }

      return temp;
    });
  }

  function handleFileInput(e) {
    if (e.target.files.length > 0) {
      let fileArr = [...userSelectedAttachments];
      for (let i = 0; i < e.target.files.length; i++) {
        let isAlreadySelected = false;
        fileArr.forEach((fileSelectedOb) => {
          if (fileSelectedOb.name == e.target.files[i].name) {
            isAlreadySelected = true;
          }
        });
        if (!isAlreadySelected) {
          fileArr.push(e.target.files[i]);
        }
      }
      changeUserSelectedAttachments(fileArr);
    }
  }

  function removeAttachment(file) {
    if (file) {
      let newFileArr = [];
      userSelectedAttachments.forEach((attachedFiles) => {
        if (attachedFiles?.name != file?.name) {
          newFileArr.push(attachedFiles);
        }
      });

      changeUserSelectedAttachments(newFileArr);
    }
  }

  function fetchPreviewElements() {
    var imagePreview = document.getElementById("attachmentPreview");
    if (userSelectedAttachments.length > 0) {
      imagePreview.innerHTML = "";
      userSelectedAttachments.forEach((file) => {
        let fileReader = new FileReader();
        fileReader.onload = function (e) {
          var divSection = document.createElement("div");
          divSection.setAttribute("class", Styles.previewImageBox);

          var delIconDiv = document.createElement("div");
          delIconDiv.setAttribute("class", Styles.previewDeleteIconWrapper);
          delIconDiv.onclick = () => {
            removeAttachment(file);
          };

          var deleteImg = document.createElement("img");
          deleteImg.src = trashRedIcon;
          deleteImg.setAttribute("class", Styles.previewDeleteIcon);
          delIconDiv.append(deleteImg);

          var img = document.createElement("img");
          img.src = e.target.result;

          img.style.maxWidth = "100%";

          divSection.appendChild(img);
          divSection.appendChild(delIconDiv);
          // Clear previous previews and append the new image element
          imagePreview.appendChild(divSection);
        };

        // Read the file as a data URL
        fileReader.readAsDataURL(file);
      });
    } else {
      imagePreview.innerHTML = "";
    }
  }

  return (
    <div
      className={Styles.mainContainer}
      style={{ visibility: props.show ? "visible" : "hidden" }}
      id="addModalContainer"
      onClick={(e) => {
        if (e.target.id == "addModalContainer") {
          props.changeAddModalState(false);
        }
      }}
    >
      <div
        className={classnames(
          Styles.container,
          props.show ? Styles.showContainer : ""
        )}
      >
        <div className={Styles.header}>
          <div className={Styles.markerDesign1}></div>
          <p>New {props.type == "task" ? "Task" : "Bug"}</p>
          <div className={Styles.markerDesign2}></div>
          <img
            src={closeIcon}
            alt="close"
            onClick={() => {
              props.changeAddModalState(false);
            }}
          />
        </div>

        <div className={Styles.body}>
          <div
            style={{
              display: "flex",
              gap: "0.5em",
              color: "#FF1E1E",
              fontSize: "0.8rem",
              fontWeight: "600",
              marginTop: "0.8em",
            }}
          >
            {errorText ? (
              <>
                {" "}
                <img src={exclamation} alt="err" style={{ width: "1em" }} />
                <p style={{ margin: 0 }}>{errorText}</p>
              </>
            ) : (
              ""
            )}
          </div>
          {props.type == "task" ? (
            <div className={Styles.formBox} style={{ marginTop: "1em" }}>
              <p>Enter {props.type == "task" ? "Task" : "Bug"} Title</p>
              <textarea
                rows="5"
                id="addTitle"
                placeholder={"What needs to be assigned?"}
              ></textarea>
            </div>
          ) : (
            <div className={Styles.formBox}>
              <p>Bug Summary</p>
              <textarea
                rows="5"
                id="bugSummary"
                placeholder={"Enter Bug Details"}
              ></textarea>
            </div>
          )}

          <div className={Styles.formBox}>
            <p>Assigned to</p>
            <div className={Styles.assignedToBox} ref={elementRef}>
              {selectedAssignee.map((userId) => {
                let memberOb = fetchObFromAssigneeUserId(userId);
                return (
                  <div className={Styles.userInfoBox}>
                    <img src={memberOb.avatar ?? defaultUserIcon} alt="usr" />
                    <p>{memberOb.name ? memberOb.name : "unnamed"}</p>
                    <img
                      onClick={() => {
                        removeFromSelectedAssignee(userId);
                      }}
                      src={redCrossIcon}
                      alt="remove"
                      style={{
                        width: "0.57em",
                        height: "0.57em",
                        marginTop: "0.2em",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                );
              })}

              <div
                id="suggestionDropdown"
                // className={classnames(
                //   Styles.searchUserSuggestion,
                //   showSuggestionDropdown
                //     ? Styles.searchUserSuggestionVisible
                //     : ""
                // )}
                style={{
                  display: "flex",
                  width: selectedAssignee.length > 0 ? "auto" : "100%",
                }}
              >
                <input
                  type="text"
                  placeholder="Select Users"
                  style={{
                    padding: "0",
                    width: selectedAssignee.length > 0 ? "10em" : "100%",
                  }}
                  onFocus={() => {
                    changeSuggestionDropdown(true);
                  }}
                  value={userSuggestionInput}
                  onChange={(e) => {
                    changeUserSuggestionInput(e.target.value);
                  }}
                />
                <DropdownMenu
                  id="suggestionDropdown"
                  isActive={showSuggestionDropdown}
                  updateDropdownState={changeSuggestionDropdown}
                  styles={{
                    width: "100%",
                    left: 0,
                    paddingTop: "0.5em",
                    gap: "0",
                    top:
                      elementRef && elementRef.current
                        ? elementRef.current.offsetHeight - 10 + "px"
                        : "3em",
                    height: "10em",
                    overflow: "scroll",
                    overflowX: "hidden",
                    zIndex: 5,
                  }}
                >
                  {members &&
                    members.map((memberob, index) => {
                      return memberob.name
                        .toLowerCase()
                        .search(userSuggestionInput.toLowerCase()) > -1 &&
                        !checkIfAssigneeIsAlreadySelected(memberob.userid) ? (
                        <div
                          className={Styles.searchUserInfoBox}
                          onClick={() => {
                            selectAssignee(memberob);
                          }}
                        >
                          <img
                            src={memberob.avatar ?? defaultUserIcon}
                            alt="user"
                          />
                          <p>{memberob.name ? memberob.name : "unnamed"}</p>
                        </div>
                      ) : (
                        ""
                      );
                    })}
                </DropdownMenu>
              </div>
            </div>
            {/* <div className={Styles.assignedSuggestionBox}>
              <div className={Styles.userInfoBox}>
                <img src={defaultUserIcon} />
                <p>test name</p>
              </div>
            </div> */}
          </div>

          <div className={Styles.formButton}>
            <FormButton
              type="style1"
              styles={{
                width: "10.5em",
                padding: "0.4375em 0",
                fontSize: "1.125rem",
                borderRadius: "0.555em",
                textTransform: "capitalize",
              }}
              handler={() => {
                props.type == "task" ? addTaskInitiator() : addBugInitiator();
              }}
            >
              {props.type == "task"
                ? addTaskLoading
                  ? "Assigning"
                  : "Add task"
                : addBugLoading
                ? "Assigning"
                : "Add Bug"}
            </FormButton>
          </div>

          <div
            className={Styles.additionalFieldsHeader}
            onClick={() => {
              changeCollapse(!isAdditionalFieldCollapsed);
            }}
          >
            <p>Additional Fields</p>
            <img
              src={collapseIcon}
              alt="collapse"
              style={{ rotate: isAdditionalFieldCollapsed ? "" : "180deg" }}
            />
            <div className={Styles.horizontalLine}></div>
          </div>
          <div
            className={classnames(
              Styles.additionalFieldsBox,
              isAdditionalFieldCollapsed
                ? Styles.additionalFieldsBoxCollapsed
                : ""
            )}
          >
            <div className={Styles.formBoxSection}>
              <div className={Styles.formBox}>
                <p>Due Date</p>
                <input type="date" className={Styles.dateInput} id="addDate" />
              </div>
              <div className={Styles.formBox}>
                <p>{props.type == "task" ? "Priority" : "Severity"}</p>
                <select className={Styles.selectBox} id="addPriority">
                  <option value="0">Low</option>
                  <option value="1">Medium</option>
                  <option value="2">High</option>
                </select>
              </div>
              <div className={Styles.formBox}>
                <p>Comments</p>
                <textarea
                  placeholder={"Write a comment"}
                  cols="50"
                  id="addComments"
                ></textarea>
              </div>
            </div>
            <div className={Styles.formBoxSection}>
              <div
                className={classnames(
                  Styles.formBox,
                  Styles.attachmentFormWrapper
                )}
              >
                <p>Attachments</p>
                <div
                  className={Styles.fileInput}
                  onClick={() => {
                    document.getElementById("fileInputOriginal")?.click();
                  }}
                >
                  <img src={addIcon} alt="add" />
                  <p>Add New</p>
                </div>
                <input
                  type="file"
                  id="fileInputOriginal"
                  onChange={handleFileInput}
                  style={{ display: "none" }}
                  multiple
                  accept=".jpg, .jpeg, .png, .mp4, .mp3, .csv, .docx, .txt, .pdf, .svg, .gif"
                />
                <div
                  className={Styles.attachmentPreview}
                  id="attachmentPreview"
                ></div>
              </div>
            </div>
            {/* {props.type == "task" ? (
              <div className={Styles.formBox}>
                <p>Category</p>
                <select className={Styles.categorySelectBox} id="addCategory">
                  {taskCategory &&
                    taskCategory.map((categoryOb, index) => {
                      return (
                        <option value={categoryOb.id}>{categoryOb.name}</option>
                      );
                    })}
                </select>
              </div>
            ) : (
              ""
            )} */}

            {/* {props.type == "bug" ? (
              <div className={Styles.formBox}>
                <p>Description</p>
                <textarea
                  placeholder={"Add more info"}
                  cols="50"
                  id="bugDescription"
                ></textarea>
              </div>
            ) : (
              ""
            )} */}

            {/* <div className={Styles.formBox}>
              <p>Attachments</p>
              <p>Add New</p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddModal;
