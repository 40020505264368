import React, { useEffect, useState } from "react";
import Styles from "../Settings.module.css";
import FormButton from "../../../Elements/FormButton";
import workspaceIcon from "../../../../assets/images/icons/workspaceIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../common/Modal/Modal";
import loadingGif from "../../../../assets/images/gifs/loading_gif_1.gif";
import {
  getTeam,
  connectTeam,
  disconnectTeam,
} from "../../../../reducers/teamReducer";
import { getProfile } from "../../../../reducers/profileReducer";
function Workspace(props) {
  let [showModal, changeModalState] = useState(false);

  let [deactivateModal, changeDeactivateModal] = useState(false);
  let [selectedDeactivateTeam, changeDeactivateTeam] = useState(null);
  let [connectedTeam, changeConnectedTeam] = useState(null);
  let dispatch = useDispatch();

  let profileData = useSelector((state) => state.profileReducer.profileData);
  let availableTeams = useSelector((state) => state.teamReducer.getTeam);
  let availableTeamLoading = useSelector(
    (state) => state.teamReducer.getTeamLoading
  );
  let connectTeamResult = useSelector((state) => state.teamReducer.connectTeam);
  let connectTeamLoading = useSelector(
    (state) => state.teamReducer.connectTeamLoading
  );

  let disconnectTeamResult = useSelector(
    (state) => state.teamReducer.disconnectTeam
  );
  let disconnectTeamLoading = useSelector(
    (state) => state.teamReducer.disconnectTeamLoading
  );
  useEffect(() => {
    dispatch(getTeam());
  }, []);

  useEffect(() => {
    if (connectTeamResult && connectTeamResult[connectedTeam]) {
      if (connectTeamResult[connectedTeam]?.status == "success") {
        dispatch(getProfile());
        changeConnectedTeam(null);
      } else {
        //TODO:handle error
      }
      changeModalState(false);
    } else {
      if (!connectTeamLoading) changeConnectedTeam(null);
      changeModalState(false);
    }
  }, [connectTeamResult]);

  useEffect(() => {
    if (selectedDeactivateTeam) {
      changeDeactivateModal(true);
    } else {
      changeDeactivateModal(false);
    }
  }, [selectedDeactivateTeam]);

  useEffect(() => {
    if (
      selectedDeactivateTeam &&
      disconnectTeamResult &&
      disconnectTeamResult[selectedDeactivateTeam.team_id]
    ) {
      if (
        disconnectTeamResult[selectedDeactivateTeam.team_id]?.status ==
        "success"
      ) {
        dispatch(getProfile());
        changeDeactivateTeam(null);
      } else {
        //TODO:handle error
      }
    } else {
      if (!disconnectTeamLoading) changeDeactivateTeam(null);
    }
  }, [disconnectTeamResult]);

  return (
    <div className={Styles.container}>
      <div className={Styles.workspaceHeader}>
        <FormButton
          styles={{
            width: "fit-content",
            padding: "0.8em 1.5em",
          }}
          handler={() => {
            dispatch(getTeam());
            changeModalState(true);
          }}
        >
          Add new
        </FormButton>
        {/* <FormButton
          styles={{ width: "fit-content", padding: "0.8em 1.5em" }}
          type={"style1"}
        >
          Save changes
        </FormButton> */}
      </div>
      <div className={Styles.workspaceBody}>
        <Modal
          parentId={"settingsBody"}
          show={showModal}
          changeModalState={changeModalState}
        >
          <div className={Styles.lookupTeamContainer}>
            <p>Available Teams</p>

            {availableTeamLoading ? (
              <img
                src={loadingGif}
                alt="loading"
                className={Styles.loadingGif}
              />
            ) : (
              <>
                {availableTeams &&
                  availableTeams.map((teamOb, index) => {
                    return (
                      <div
                        className={Styles.lookupTeamBox}
                        onClick={() => {
                          if (!connectTeamLoading) {
                            changeConnectedTeam(teamOb.team_id);
                            dispatch(connectTeam(teamOb.team_id));
                          }
                        }}
                      >
                        <img
                          src={
                            teamOb.icon_url ? teamOb.icon_url : workspaceIcon
                          }
                          alt="workspace"
                        />
                        <div className={Styles.lookupTeamInfo}>
                          <p>{teamOb.team_name}</p>
                          <p>{teamOb.team_domain}.slack.com</p>
                        </div>
                      </div>
                    );
                  })}
                {availableTeams && availableTeams.length < 1 ? (
                  <p className={Styles.infoText}>No teams found</p>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </Modal>
        <Modal
          childId={"deactivate_workspace_modal"}
          parentId={"settingsBody"}
          show={deactivateModal}
          changeModalState={changeDeactivateModal}
        >
          <div className={Styles.deactivateContainer}>
            <div className={Styles.deactivateInfo}>
              <img
                src={
                  selectedDeactivateTeam?.icon_url
                    ? selectedDeactivateTeam.icon_url
                    : workspaceIcon
                }
                alt="team"
              />
              <p>
                Are you sure you want to deactivate “
                {selectedDeactivateTeam?.team_name}” workspace?
              </p>
            </div>
            <div className={Styles.deactivateInfoButtons}>
              <FormButton
                type="style1"
                styles={{
                  width: "7em",
                  fontSize: "1em",
                  borderRadius: "0.7em",
                }}
                handler={() => {
                  changeDeactivateModal(false);
                }}
              >
                Cancel
              </FormButton>
              <FormButton
                type="style2"
                styles={{
                  width: "7em",

                  fontSize: "1em",
                  borderRadius: "0.7em",
                }}
                handler={() => {
                  dispatch(disconnectTeam(selectedDeactivateTeam?.team_id));
                }}
              >
                Deactivate
              </FormButton>
            </div>
          </div>
        </Modal>
        {profileData?.teams &&
          profileData.teams.map((teamOb, index) => {
            return (
              <div className={Styles.workspaceInfoBox}>
                <img
                  src={teamOb.icon_url ? teamOb.icon_url : workspaceIcon}
                  alt="workspace"
                />
                <div className={Styles.workspaceInfoWrapper}>
                  <div className={Styles.infoBox}>
                    <p className={Styles.heading}>organisation name</p>
                    <input type="text" defaultValue={teamOb.team_name} />
                  </div>
                  <div className={Styles.infoBox}>
                    <p className={Styles.heading}>workspace ID</p>
                    <input type="text" defaultValue={teamOb.team_domain} />
                  </div>
                  <p
                    className={Styles.redText}
                    onClick={() => {
                      changeDeactivateModal(true);
                      changeDeactivateTeam(teamOb);
                    }}
                  >
                    deactivate workspace
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Workspace;
