import React, {
  Fragment,
  useRef,
  useEffect,
  useState,
  useContext,
} from "react";
import Styles from "./IPReverse.module.css";
import countryIcon from "../../../../assets/images/icons/countryIcon.svg";
import continentIcon from "../../../../assets/images/icons/continentIcon.svg";
import proxyIcon from "../../../../assets/images/icons/proxyIcon.svg";
import providerIcon from "../../../../assets/images/icons/providerIcon_2.svg";
import connectionIcon from "../../../../assets/images/icons/connectionIcon.svg";
import asnIcon from "../../../../assets/images/icons/ASNIcon.svg";
import locationIcon from "../../../../assets/images/icons/locationIcon.svg";
import saveIcon from "../../../../assets/images/icons/saveIcon.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import collapseArrow from "../../../../assets/images/icons/collapseIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import LoadingElement from "../../../common/LoadingElement/LoadingElement";
import {
  fetchSavedIp,
  checkIp,
  fetchRecentIp,
} from "../../../../reducers/ipResolveReducer";
import { AlertContext } from "../../Layout";

function IPReverse(props) {
  const sliderRef = useRef(null);
  const sliderRef2 = useRef(null);
  let dispatch = useDispatch();
  let alertContext = useContext(AlertContext);

  let [userInput, changeUserInput] = useState("");
  let [showResult, changeResultShow] = useState(false);

  let savedIps = useSelector((state) => state.ipResolveReducer.savedIps);

  let ipResult = useSelector((state) => state.ipResolveReducer.checkIpData);
  let ipResultLoading = useSelector(
    (state) => state.ipResolveReducer.checkIpLoading
  );

  let recentIps = useSelector((state) => state.ipResolveReducer.recentIps);

  let ipReverseError = useSelector(
    (state) => state.ipResolveReducer.ipResolveError
  );

  useEffect(() => {
    dispatch(fetchSavedIp());
    dispatch(fetchRecentIp());
  }, []);

  useEffect(() => {
    if (recentIps) {
      slideGoto(sliderRef, 0);
    }
  }, [recentIps]);

  useEffect(() => {
    if (savedIps) {
      slideGoto(sliderRef2, 0);
    }
  }, [savedIps]);

  useEffect(() => {
    if (ipResult) {
      changeResultShow(true);
    }
  }, [ipResult]);

  useEffect(() => {
    if (ipResultLoading) {
      changeResultShow(true);
    }
  }, [ipResultLoading]);

  useEffect(() => {
    if (ipReverseError)
      alertContext?.showAlert("Something went wrong,try again", "danger");
    console.log(ipReverseError);
  }, [ipReverseError]);

  function savedBox(type = "saved", data) {
    let ipInfo = data;
    let isLoading = ipResultLoading;
    return (
      <div
        className={Styles.savedBox}
        style={
          type == "result"
            ? {
                backgroundColor: "transparent",
                boxShadow: "none",
                cursor: "default",
              }
            : {}
        }
      >
        {type != "result" ? (
          <Fragment>
            <div className={Styles.savedBoxHeader}>
              {type == "recent" ? (
                <p>{ipInfo?.ip}</p>
              ) : (
                <Fragment>
                  <p className={Styles.ipText}>{ipInfo?.tag ?? "Unnamed"}</p>
                  <img src={collapseArrow} alt="collapse" />
                </Fragment>
              )}
            </div>
            <div className={Styles.lineBreak}></div>
          </Fragment>
        ) : (
          ""
        )}

        <div className={Styles.savedBoxInfo}>
          {type == "saved" ? <p className={Styles.ipText}>{data?.ip}</p> : ""}
          <div className={Styles.savedInfoItem}>
            <img src={countryIcon} alt="country" />
            <p>
              country:{" "}
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span style={{ color: "var(--black)" }}>{ipInfo?.country}</span>
              )}
            </p>
          </div>
          <div className={Styles.savedInfoItem}>
            <img src={continentIcon} alt="country" />
            <p>
              continenet:{" "}
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span style={{ color: "var(--black)", marginLeft: "0.4em" }}>
                  {ipInfo?.continent}
                </span>
              )}
            </p>
          </div>
          <div className={Styles.savedInfoItem}>
            <img src={proxyIcon} alt="country" />
            <p>
              proxy:{" "}
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span style={{ color: "var(--black)" }}>{ipInfo?.proxy}</span>
              )}
            </p>
          </div>
          <div className={Styles.savedInfoItem}>
            <img src={providerIcon} alt="country" />
            <p>
              provider:{" "}
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span style={{ color: "var(--black)" }}>
                  {ipInfo?.provider}
                </span>
              )}
            </p>
          </div>
          <div className={Styles.savedInfoItem}>
            <img src={connectionIcon} alt="country" />
            <p>
              connection:{" "}
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span style={{ color: "var(--black)" }}>
                  {ipInfo?.connection}
                </span>
              )}
            </p>
          </div>
          <div className={Styles.savedInfoItem}>
            <img src={asnIcon} alt="country" />

            <p>
              ASN:
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span style={{ color: "var(--black)" }}>{ipInfo?.asn}</span>
              )}
            </p>
          </div>
          <div className={Styles.savedInfoItem}>
            <img src={locationIcon} alt="country" />
            <p>
              location:{" "}
              {isLoading ? (
                <span
                  style={{
                    width: "4em",
                    height: "0.8em",
                    borderRadius: "20em",
                    background: "var(--lightGrey)",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <a
                  target="__blank"
                  href={`https://maps.google.com/?q=${ipInfo?.latitude},${ipInfo?.longitude}`}
                >
                  {`https://maps.google.com/?q=${ipInfo?.latitude},${ipInfo?.longitude}`}
                </a>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 1,
    dotsClass: `slick-dots ${Styles.dots} uptimeSliderDots`,
  };

  const slideForward = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const slideBackward = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const slideGoto = (refType, number) => {
    if (refType.current) {
      refType.current.slickGoTo(number);
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.searchBox}>
        <input
          type="text"
          placeholder="Enter IP address"
          defaultValue={userInput}
          onChange={(e) => {
            if (e) changeUserInput(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e && e.key == "Enter") {
              dispatch(checkIp(userInput));
            }
          }}
        />
        <button>Get IP Details</button>
      </div>
      {showResult ? (
        <div className={Styles.resultWrapper}>
          <p>
            Showing Results for IP{" "}
            <span
              style={{ color: "var(--black)", textDecoration: "underline" }}
            >
              {userInput}
            </span>{" "}
          </p>
          <div className={Styles.resultBox}>
            <div className={Styles.mapLocation}>
              <iframe
                src={`https://maps.google.com/maps?q=${ipResult?.latitude},${ipResult?.longitude}&hl=es;z=14&amp;output=embed`}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "inherit",
                  border: "none",
                }}
                title={"ip_location"}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className={Styles.resultInfo}>
              {savedBox("result", ipResult)}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={Styles.savedContainer}>
        <p>Saved IP</p>
        <div className={`${Styles.savedBoxWrapperScroll} ipReverseRecent`}>
          <Slider {...settings} ref={sliderRef2}>
            {savedIps &&
              savedIps.map((val, index) => {
                val = { ...val.data, ip: val.ip, tag: val.tag };
                return savedBox("saved", val);
              })}
          </Slider>
        </div>
      </div>
      <div className={Styles.savedContainer}>
        <p>Recent lookups</p>
        <div
          className={`${Styles.savedBoxWrapperScroll} ipReverseRecent`}
          //   onWheel={(e) => {
          //     if (e && e.deltaY) {
          //       e.preventDefault();
          //       e.stopPropagation();
          //       if (e.deltaY > 0) {
          //         slideForward();
          //       } else {
          //         slideBackward();
          //       }
          //     }
          //   }}
        >
          <Slider {...settings} ref={sliderRef}>
            {recentIps &&
              recentIps.map((val, index) => {
                val = { ...val.data, ip: val.ip ?? null };
                return savedBox("recent", val);
              })}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default IPReverse;
