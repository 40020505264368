import React from "react";
import Styles from "./LoadingElement.module.css";
import loadingImage from "../../../assets/images/other_images/loadingGif.svg";
function LoadingElement(props) {
  return (
    <div className={Styles.container} style={{ ...props.styles }}>
      <img src={loadingImage} alt="Loading" className={Styles.image} />
    </div>
  );
}

export default LoadingElement;
