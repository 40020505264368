import React, { useState } from "react";
import Styles from "./UserDropdown.module.css";
import utils from "../../../../utils";
import DropdownMenu from "../../../common/DropdownMenu/DropdownMenu";
import userIconDefault from "../../../../assets/images/icons/userIconDefault.svg";
import adminPanelIcon from "../../../../assets/images/icons/adminPanelcon.svg";
import helpIcon from "../../../../assets/images/icons/helpIcon.svg";
import powerIcon from "../../../../assets/images/icons/powerIcon.svg";
import settingsIcon from "../../../../assets/images/icons/settingsIcon.svg";
import { useNavigate } from "react-router-dom";
function UserDropdown(props) {
  let [isDropdownVisible, updateDropdownState] = useState(false);
  let navigate = useNavigate();
  function getUserIcon() {
    if (props.profileData && props.profileData.teams) {
      let teamOb = utils.fetchTeamDataFromArray(
        props.profileData.teams,
        utils.fetchVariable("current_team")
      );

      if (teamOb?.avatar) {
        return teamOb.avatar;
      } else {
        return userIconDefault;
      }
    } else {
      return userIconDefault;
    }
  }
  return (
    <>
      <img
        src={getUserIcon()}
        className={Styles.userIcon}
        alt="user"
        onClick={() => {
          isDropdownVisible
            ? updateDropdownState(false)
            : updateDropdownState(true);
        }}
      />
      <DropdownMenu
        isActive={isDropdownVisible}
        updateDropdownState={updateDropdownState}
        id="userDropdown"
        styles={{
          right: 0,
        }}
      >
        <p style={{ color: "var(--black)", cursor: "default" }}>
          {props.profileData && props.profileData.name
            ? props.profileData.name
            : "user"}
        </p>
        <hr />
        <div
          className={Styles.dropdownElement}
          onClick={() => {
            navigate("/settings");
            updateDropdownState(false);
          }}
        >
          <img
            src={settingsIcon}
            className={Styles.menuIcon}
            alt="My Settings"
          />{" "}
          My Settings
        </div>
        <div className={Styles.dropdownElement}>
          <img
            src={adminPanelIcon}
            className={Styles.menuIcon}
            alt="Admin Panel"
          />{" "}
          Admin Panel
        </div>
        <div className={Styles.dropdownElement}>
          <img src={helpIcon} className={Styles.menuIcon} alt="Help" /> Help
        </div>
        <div
          className={Styles.dropdownElement}
          onClick={() => {
            utils.logoutUser();

            navigate("/login");
          }}
        >
          <img src={powerIcon} className={Styles.menuIcon} alt="Logout" />{" "}
          Logout
        </div>
      </DropdownMenu>
    </>
  );
}

export default UserDropdown;
