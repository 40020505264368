import React, { useEffect } from "react";
import Styles from "./ScrollableComponent.module.css";
import classnames from "classnames";
function ScrollableComponent(props) {
  useEffect(() => {
    let childrenToShow = props.show;
    let containerElement = document.getElementsByClassName(
      Styles.scrollContainer
    )[0];
    let children = document.getElementsByClassName(Styles.scrollContainer)[0]
      .children;

    if (children.length <= childrenToShow) {
      containerElement.style.overflow = "none";
    } else {
      let heightToShow = 0;
      for (let index = 0; index < children.length; index++) {
        if (index < childrenToShow) {
          heightToShow =
            heightToShow +
            children[index].offsetHeight +
            parseInt(
              getComputedStyle(children[index]).marginBottom.split("px")[0]
            );
        }
      }
      containerElement.style.overflowY = "scroll";
      containerElement.style.height = heightToShow + "px";
    }
  }, []);

  return (
    <div
      className={classnames(
        Styles.scrollContainer,
        props.hideScrollbar ? Styles.hiddenScrollbar : ""
      )}
    >
      {props.children}
    </div>
  );
}

export default ScrollableComponent;
