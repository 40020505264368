import React, { Fragment, useEffect, useState } from "react";
import Styles from "./SlackConnect.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import FormButton from "../../Elements/FormButton";
import classNames from "classnames";
import ToggleButton from "../../Elements/ToggleButton";
import ScrollableComponent from "../../common/ScrollableComponent";
import rich from "../../../assets/images/other_images/Rich.png";
import skate from "../../../assets/images/other_images/Skate.png";
import slackIcon from "../../../assets/images/logos/slack_logo.svg";
import rightArrow from "../../../assets/images/icons/right_arrow_1.svg";
import greenTick from "../../../assets/images/icons/green_tick.svg";
import comingsoon_telegram from "../../../assets/images/other_images/comingsoon_tele.svg";
import comingsoon_whatsapp from "../../../assets/images/other_images/comingsoon_whatsapp.svg";
import comingsoon_teams from "../../../assets/images/other_images/comingsoon_teams.svg";
import { slackConnect, slackAuthPoll } from "../../../reducers/loginReducer";
import {
  getTeam,
  connectTeam,
  getTeamList,
} from "../../../reducers/teamReducer";
import loadingGif from "../../../assets/images/gifs/loading_gif_1.gif";
import ErrorContainer from "../../common/ErrorContainer";
import utils from "../../../utils";
function SlackConnect(props) {
  let navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [activeState, changeActiveState] = useState(
    searchParams.get("teams") ? "connect" : "install"
  );
  let [activeTeam, changeActiveTeam] = useState([]);
  const [errorState, changeErrorState] = useState({
    error: "",
  });
  const [availableTeamError, changeAvailableTeamError] = useState({
    error: "",
  });
  let [addToSlackLoading, changeAddToSlackLoading] = useState(false);
  let [teamsLoading, changeTeamsLoading] = useState(false);
  let dispatch = useDispatch();
  let slackConnectResult = useSelector(
    (state) => state.loginReducer.slackConnect
  );
  let slackConnectError = useSelector(
    (state) => state.loginReducer.slackConnectError
  );

  let slackAuth = useSelector((state) => state.loginReducer.slackAuth);
  let availableTeams = useSelector((state) => state.teamReducer.getTeam);
  let availableTeamsLoading = useSelector(
    (state) => state.teamReducer.getTeamLoading
  );
  let availableTeamsError = useSelector(
    (state) => state.loginReducer.getTeamError
  );
  let connectTeamResult = useSelector((state) => state.teamReducer.connectTeam);
  let connectTeamError = useSelector(
    (state) => state.teamReducer.connectTeamError
  );

  let teamList = useSelector((state) => state.teamReducer.teamList);

  let teamListLoading = useSelector(
    (state) => state.teamReducer.teamListLoading
  );
  let teamListError = useSelector((state) => state.teamReducer.teamListError);

  useEffect(() => {
    if (!teamList) dispatch(getTeamList());
  }, []);

  useEffect(() => {
    if (availableTeamsLoading || teamListLoading) {
      changeTeamsLoading(true);
    } else {
      changeTeamsLoading(false);
    }
  }, [teamListLoading, availableTeamsLoading]);

  useEffect(() => {
    if (connectTeamResult && Object.keys(connectTeamResult).length > 0) {
      navigate("/launch");
    }
  }, [connectTeamResult]);

  useEffect(() => {
    if (slackConnectError) {
      changeErrorState({
        error: "Something went wrong, try again",
      });
    }
    if (availableTeamsError || connectTeamError || teamListError) {
      changeAvailableTeamError({
        error: "Something went wrong, try again",
      });
    }

    changeAddToSlackLoading(false);
  }, [slackConnectError, availableTeamsError, connectTeamError, teamListError]);

  useEffect(() => {
    if (slackAuth) {
      let code = utils.fetchVariable("slack_connect_code");
      if (slackAuth?.status == "success") {
        changeAddToSlackLoading(false);
        if (code) {
          changeActiveState("connect");
        }
      } else {
        if (code) {
          setTimeout(() => {
            dispatch(slackAuthPoll(code));
          }, 5000);
        }
      }
    }
  }, [slackAuth]);

  useEffect(() => {
    if (slackConnectResult?.url && slackConnectResult?.code) {
      utils.setVariable("slack_connect_code", slackConnectResult.code);
      changeAddToSlackLoading(true);
      dispatch(slackAuthPoll(slackConnectResult.code));
      window.open(slackConnectResult.url, "_blank");
    }
  }, [slackConnectResult]);

  useEffect(() => {
    if (activeState == "connect") {
      dispatch(getTeam());
    }
  }, [activeState]);

  useEffect(() => {
    if (teamList) {
      let arr = [];
      teamList.forEach((teamOb) => {
        arr.push(teamOb.team_id);
      });
      changeActiveTeam((oldState) => {
        let temp = [...oldState, ...arr];
        return temp;
      });
    }
  }, [teamList]);

  function isTeamInActive(teamId) {
    let isActive = false;
    activeTeam.forEach((team_id) => {
      if (teamId == team_id) {
        isActive = true;
      }
    });
    return isActive;
  }

  function fetchTeams() {
    let teams = [];
    if (teamList && availableTeams) {
      teams = [...teamList, ...availableTeams];
    }
    return teams;
  }
  return (
    <div className={Styles.slackConnectContainer}>
      {activeState == "install" ? (
        <p className={Styles.slackConnect__text}>
          It’s time to install{" "}
          <span className={Styles.gradientText}>Botza</span> to your workspace
        </p>
      ) : (
        <p className={Styles.slackConnect__text}>Connect your workspace!</p>
      )}

      <div className={Styles.slackConnectSection}>
        <div className={Styles.slackConnectInfo}>
          <p className={Styles.slackConnect__infoText}>
            Botza’s advanced digital tools, elevates the way you manage your
            projects and workflow. All within the platforms.
          </p>
          <div
            className={classNames(
              Styles.slackConnect__service,
              activeState == "connect" ? Styles.slackConnect__serviceActive : ""
            )}
          >
            <div
              className={classNames(
                Styles.serviceBox,
                activeState == "connect" ? Styles.serviceBoxActive : ""
              )}
            >
              {activeState == "connect" ? (
                <img
                  src={greenTick}
                  alt="greenTick"
                  className={Styles.greenTick}
                />
              ) : (
                ""
              )}

              <img src={slackIcon} alt="slack" className={Styles.serviceLogo} />
              <p className={Styles.serviceName}>Slack</p>
            </div>
            {activeState == "connect" ? (
              <div className={Styles.availableWorkspaceContainer}>
                <p className={Styles.workspace__heading}>
                  Available Workspaces
                </p>
                <ErrorContainer type="style1" state={availableTeamError} />
                <div className={Styles.workspace__toggleWrapper}>
                  {availableTeamsLoading ? (
                    <img
                      src={loadingGif}
                      className={Styles.loadingGif}
                      alt="loading"
                    />
                  ) : (
                    ""
                  )}
                  {fetchTeams() && !teamsLoading && fetchTeams().length == 0 ? (
                    <p>No available teams found</p>
                  ) : (
                    ""
                  )}

                  <ScrollableComponent show={2}>
                    {fetchTeams().length > 0 &&
                      fetchTeams().map((teamOb) => {
                        return (
                          <div className={Styles.workspace__toggleBox}>
                            <div
                              onClick={() => {
                                if (isTeamInActive(teamOb.team_id)) {
                                  let activeTeamArr = activeTeam.filter(
                                    (team) => {
                                      if (team != teamOb.team_id) {
                                        return team;
                                      }
                                    }
                                  );
                                  changeActiveTeam(activeTeamArr);
                                } else {
                                  let activeTeamArr = [
                                    ...activeTeam,
                                    teamOb.team_id,
                                  ];
                                  changeActiveTeam(activeTeamArr);
                                }
                              }}
                            >
                              <ToggleButton
                                isActive={isTeamInActive(teamOb.team_id)}
                              />
                            </div>

                            <div className={Styles.workspaceDetails}>
                              <p className={Styles.workspaceName}>
                                {teamOb.team_name}
                              </p>
                              <p className={Styles.workspaceUrl}>
                                {teamOb.team_domain}.slack.com
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    {/* {availableTeams &&
                      availableTeams.length > 0 &&
                      availableTeams.map((teamOb) => {
                        return (
                          <div className={Styles.workspace__toggleBox}>
                            <div
                              onClick={() => {
                                if (isTeamInActive(teamOb.team_id)) {
                                  let activeTeamArr = activeTeam.filter(
                                    (team) => {
                                      if (team != teamOb.team_id) {
                                        return team;
                                      }
                                    }
                                  );
                                  changeActiveTeam(activeTeamArr);
                                } else {
                                  let activeTeamArr = [
                                    ...activeTeam,
                                    teamOb.team_id,
                                  ];
                                  changeActiveTeam(activeTeamArr);
                                }
                              }}
                            >
                              <ToggleButton
                                isActive={isTeamInActive(teamOb.team_id)}
                              />
                            </div>

                            <div className={Styles.workspaceDetails}>
                              <p className={Styles.workspaceName}>
                                {teamOb.team_name}
                              </p>
                              <p className={Styles.workspaceUrl}>
                                {teamOb.team_domain}.slack.com
                              </p>
                            </div>
                          </div>
                        );
                      })} */}
                  </ScrollableComponent>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={Styles.slackConnect__buttons}>
            <ErrorContainer type="style1" state={errorState} />
            {activeState == "install" ? (
              <Fragment>
                {" "}
                {addToSlackLoading ? (
                  <img src={loadingGif} className={Styles.loadingGif} />
                ) : (
                  ""
                )}
                <FormButton
                  type="style1"
                  handler={() => {
                    if (!addToSlackLoading) dispatch(slackConnect());
                  }}
                >
                  Add to Slack
                </FormButton>
                <FormButton
                  type="style2"
                  handler={() => {
                    changeActiveState("connect");
                  }}
                >
                  Find your workspace
                </FormButton>
              </Fragment>
            ) : (
              <FormButton
                type="style1"
                handler={() => {
                  if (activeTeam.length > 0)
                    dispatch(connectTeam(activeTeam.join(",")));
                }}
              >
                Connect
              </FormButton>
            )}

            <div
              className={Styles.flexContainer}
              onClick={() => {
                navigate("/launch");
              }}
            >
              <p className={Styles.skipText}>skip</p>
              <img src={rightArrow} alt="skip" className={Styles.skipIcon} />
            </div>
          </div>
          <div className={Styles.releasingServicesContainer}>
            <p className={Styles.releasingSoonHeading}>Releasing Soon!</p>
            <div className={Styles.releasingServices}>
              <img
                src={comingsoon_telegram}
                alt="telegram"
                className={Styles.releasingSoonImages}
              />
              <img
                src={comingsoon_whatsapp}
                alt="whatsapp"
                className={Styles.releasingSoonImages}
              />
              <img
                src={comingsoon_teams}
                alt="teams"
                className={Styles.releasingSoonImages}
              />
            </div>
          </div>
        </div>
        {activeState == "install" ? (
          <img src={rich} alt="img" className={Styles.richImage} />
        ) : (
          <img src={skate} alt="img" className={Styles.skateImage} />
        )}
      </div>
    </div>
  );
}

export default SlackConnect;
