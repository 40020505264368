import React from "react";
import Styles from "./FormButton.module.css";
import classNames from "classnames";
function FormButton(props) {
  return (
    <button
      onClick={props.handler ?? null}
      style={props.styles ?? {}}
      className={
        props.type === "style1"
          ? classNames(Styles.loginForm__button, Styles.loginForm__buttonStyle1)
          : classNames(Styles.loginForm__button, Styles.loginForm__buttonStyle2)
      }
    >
      {props.children}
    </button>
  );
}

export default FormButton;
