import React from "react";
import Styles from "./Banner.module.css";
import starImage from "../../../../assets/images/other_images/bannerBgStar.svg";
import bannerImage from "../../../../assets/images/other_images/bannerImg.png";
function Banner(props) {
  return (
    <div className={Styles.container}>
      <img src={starImage} className={Styles.bgImg1} alt="bg" />
      <img src={bannerImage} className={Styles.bgImg2} alt="bg" />
      <div className={Styles.infoBody}>
        <p className={Styles.infoText1}>
          Activate <b>BOTZA</b> pro to open all functions
        </p>
        <p className={Styles.infoText2}>Get one month free trial membership!</p>
        <button className={Styles.trybutton}>Try it Free</button>
      </div>
    </div>
  );
}

export default Banner;
