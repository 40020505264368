import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uptimeApi from "../api/uptimeApi";

let initialState = {
  downtimeList: [],
  // downtimeListError: null,
  downtimeListLoading: false,
  downtimeGraph: null,
  // downtimeGraphError: null,
  downtimeGraphLoading: false,
  downtime: null,
  downtimeLoading: false,
  // downtimeError: null,
  downtimeRemove: null,
  // downtimeRemoveError: null,
  downtimeRemoveLoading: false,
  downtimeSave: null,
  // downtimeSaveError: null,
  downtimeSaveLoading: false,
  downtimeError: null,
};

export const getDowntimeList = createAsyncThunk(
  "uptime/downtimeList",
  async (limit = 2, { rejectWithValue }) => {
    const response = await uptimeApi.getDowntimeList(limit);
    if (response.data.status == "success") {
      return response.data.result;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getDowntimeListGraph = createAsyncThunk(
  "uptime/downtimeListGraph",
  async ({ id, chartTime = "day" }, { rejectWithValue }) => {
    const response = await uptimeApi.getDowntimeListGraph(id, chartTime);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getDowntime = createAsyncThunk(
  "uptime/downtime",
  async (domain, { rejectWithValue }) => {
    const response = await uptimeApi.getDowntime(domain);
    if (response.data.status == "success") {
      return response.data.result;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const downtimeRemove = createAsyncThunk(
  "uptime/downtimeRemove",
  async (domain, { rejectWithValue }) => {
    const response = await uptimeApi.removeDowntime(domain);
    if (response.data.status == "success") {
      return { ...response.data, domain_id: domain };
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const downtimeSave = createAsyncThunk(
  "uptime/downtimeSave",
  async (domain, { rejectWithValue }) => {
    const response = await uptimeApi.saveDowntime(domain);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);
const uptimeSlice = createSlice({
  name: "uptime",
  initialState,
  reducers: {
    resetDowntimeData(state) {
      state.downtime = null;
    },
  },
  extraReducers: (builder) => {
    //downTimelist builders
    builder.addCase(getDowntimeList.pending, (state, action) => {
      state.downtimeListLoading = true;
      state.downtimeList = null;
      // state.downtimeListError = null;
      state.downtimeError = null;
    });
    builder.addCase(getDowntimeList.rejected, (state, action) => {
      state.downtimeListLoading = false;
      state.downtimeList = null;

      if (action.error) {
        // state.downtimeListError = { error: action.error.message };
        state.downtimeError = { error: action.error.message };
      } else {
        // state.downtimeListError = action.payload;
        state.downtimeError = action.payload;
      }
    });
    builder.addCase(getDowntimeList.fulfilled, (state, action) => {
      state.downtimeListLoading = false;
      state.downtimeList = action.payload;
      // state.downtimeListError = null;
      state.downtimeError = null;
    });

    //get graph data
    builder.addCase(getDowntimeListGraph.pending, (state, action) => {
      state.downtimeGraph = null;
      // state.downtimeGraphError = null;
      state.downtimeError = null;
      state.downtimeGraphLoading = true;
    });
    builder.addCase(getDowntimeListGraph.rejected, (state, action) => {
      state.downtimeGraphLoading = false;
      state.downtimeGraph = null;

      if (action.error) {
        // state.downtimeGraphError = { error: action.error.message };
        state.downtimeError = { error: action.error.message };
      } else {
        // state.downtimeGraphError = action.payload;
        state.downtimeError = action.payload;
      }
    });
    builder.addCase(getDowntimeListGraph.fulfilled, (state, action) => {
      state.downtimeGraphLoading = false;
      state.downtimeGraph = action.payload;
      // state.downtimeGraphError = null;
      state.downtimeError = null;
    });

    //get downtime data from domain
    builder.addCase(getDowntime.pending, (state, action) => {
      state.downtime = null;
      state.downtimeError = null;
      state.downtimeLoading = true;
    });
    builder.addCase(getDowntime.rejected, (state, action) => {
      state.downtimeLoading = false;
      state.downtime = null;

      if (action.error) {
        state.downtimeError = { error: action.error.message };
      } else {
        state.downtimeError = action.payload;
      }
    });
    builder.addCase(getDowntime.fulfilled, (state, action) => {
      state.downtimeLoading = false;
      state.downtime = action.payload;
      state.downtimeError = null;
    });

    //Remove downtime data
    builder.addCase(downtimeRemove.pending, (state, action) => {
      state.downtimeRemove = null;
      // state.downtimeRemoveError = null;
      state.downtimeError = null;
      state.downtimeRemoveLoading = true;
    });
    builder.addCase(downtimeRemove.rejected, (state, action) => {
      state.downtimeRemoveLoading = false;
      state.downtimeRemove = null;

      if (action.error) {
        // state.downtimeRemoveError = { error: action.error.message };
        state.downtimeError = { error: action.error.message };
      } else {
        // state.downtimeRemoveError = action.payload;
        state.downtimeError = action.payload;
      }
    });
    builder.addCase(downtimeRemove.fulfilled, (state, action) => {
      state.downtimeRemoveLoading = false;
      state.downtimeRemove = action.payload;
      // state.downtimeRemoveError = null;
      state.downtimeError = null;
    });

    //save downtime data
    builder.addCase(downtimeSave.pending, (state, action) => {
      state.downtimeSave = null;
      // state.downtimeSaveError = null;
      state.downtimeError = null;
      state.downtimeSaveLoading = true;
    });
    builder.addCase(downtimeSave.rejected, (state, action) => {
      state.downtimeSaveLoading = false;
      state.downtimeSave = null;

      if (action.error) {
        // state.downtimeSaveError = { error: action.error.message };
        state.downtimeError = { error: action.error.message };
      } else {
        // state.downtimeSaveError = action.payload;
        state.downtimeError = action.payload;
      }
    });
    builder.addCase(downtimeSave.fulfilled, (state, action) => {
      state.downtimeSaveLoading = false;
      state.downtimeSave = action.payload;
      state.downtimeError = null;
      // state.downtimeSaveError = null;
    });
  },
});

export const { uptime, resetDowntimeData } = uptimeSlice.actions;
export default uptimeSlice.reducer;
