import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import SearchBar from "./SearchBar/SearchBar";
import Notification from "./Notification/Notification";
import headerIcon1 from "../../../assets/images/icons/headerIcon_1.svg";
import headerIcon2 from "../../../assets/images/icons/headerIcon_2.svg";
import Styles from "./Header.module.css";
import UserDropdown from "./UserDropdown/UserDropdown";
import WorkspaceDropdown from "./WorkspaceDropdown/WorkspaceDropdown";
import { DarkModeContext } from "../../../App.js";
import AlertBox1 from "../../common/AlertBox_1/AlertBox_1";
function Header(props) {
  let darkModeData = useContext(DarkModeContext);
  let profileData = useSelector((state) => {
    return state.profileReducer.profileData;
  });

  let [alertBoxData, changeAlertBoxData] = useState({
    isVisible: false,
    message: "",
    type: "success",
  });

  function showAlert(message, type) {
    changeAlertBoxData((oldState) => {
      let temp = { ...oldState };
      temp.message = message;
      temp.type = type;
      temp.isVisible = true;
      return temp;
    });
  }

  return (
    <div className={Styles.container}>
      <AlertBox1
        changeAlertState={changeAlertBoxData}
        message={alertBoxData.message}
        isVisible={alertBoxData.isVisible}
        type={alertBoxData.type}
      />
      {props.type == "settings" ? (
        <p
          style={{
            fontSize: "1.875rem",
            fontWeight: 700,
            margin: 0,
            color: "var(--black)",
            marginLeft: "0.5em",
          }}
        >
          My Settings
        </p>
      ) : (
        <SearchBar />
      )}

      <div className={Styles.headerElements}>
        <img
          src={headerIcon1}
          alt="icon"
          className={Styles.headerIcon}
          onClick={() => {
            if (darkModeData?.changeDarkMode) {
              darkModeData.changeDarkMode(!darkModeData.isDarkModeEnabled);
            }
          }}
        />

        {/* <img src={headerIcon2} alt="icon" className={Styles.headerIcon} /> */}
        <div className={Styles.headerElement} id="notificationDropdown">
          <Notification
            headerStyles={Styles}
            profileData={profileData}
            showAlert={showAlert}
          />
        </div>

        {props.type == "settings" ? (
          ""
        ) : (
          <div className={Styles.headerElement} id="workspaceDropdown">
            <WorkspaceDropdown profileData={profileData} />
          </div>
        )}

        <div className={Styles.headerElement} id="userDropdown">
          <UserDropdown profileData={profileData} />
        </div>
      </div>
    </div>
  );
}

export default Header;
