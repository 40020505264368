import React, { useEffect, useState } from "react";
import Styles from "./IpReverse.module.css";
import locationMarker from "../../../../../assets/images/icons/locationMarker.svg";
import locationIcon from "../../../../../assets/images/icons/locationIcon.svg";
import worldIcon from "../../../../../assets/images/icons/worldIcon.svg";
import proxyIcon from "../../../../../assets/images/icons/networkIcon.svg";
import providerIcon from "../../../../../assets/images/icons/providerIcon.svg";
import connectionTypeIcon from "../../../../../assets/images/icons/targetIcon.svg";
import databaseIcon from "../../../../../assets/images/icons/databaseIcon.svg";
import marker from "../../../../../assets/images/icons/markerIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { checkIp } from "../../../../../reducers/ipResolveReducer";
import LoadingElement2 from "../../../../common/LoadinElement2/LoadingElement2";
import axios from "axios";
function IpReverse(props) {
  const dispatch = useDispatch();
  let [userIp, changeUserIp] = useState(null);
  let ipFetchLoading = useSelector(
    (state) => state.ipResolveReducer.checkIpLoading
  );
  let checkIpDetails = useSelector(
    (state) => state.ipResolveReducer.checkIpData
  );

  let ipFetchErrors = useSelector(
    (state) => state.ipResolveReducer.ipResolveError
  );

  useEffect(() => {
    if (ipFetchErrors) {
      props.changeAlertBoxData({
        isVisible: true,
        message: "Something went wrong while fetching ip details. Try Again",
        type: "danger",
      });
    }
  }, [ipFetchErrors]);

  useEffect(() => {
    getUserIp();
  }, []);

  useEffect(() => {
    if (userIp) {
      dispatch(checkIp(userIp));
    }
  }, [userIp]);

  function getUserIp() {
    axios
      .get("https://api.ipify.org/?format=json")
      .then((data) => {
        changeUserIp(data.data.ip);
      })
      .catch((err) => console.log(err));
  }
  function getUserInput() {
    return document.getElementById("ipToSearch").value;
  }

  function fetchIpDetails() {
    let userInput = getUserInput();

    if (userInput) {
      dispatch(checkIp(userInput));
    }
  }

  function loadingUi() {
    return (
      <div className={classnames(Styles.body, Styles.loadingBody)}>
        <div className={Styles.infoBox}>
          <img src={locationIcon} className={Styles.infoImgBox} alt="icon" />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>Country:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
        <div className={Styles.infoBox}>
          <img src={worldIcon} className={Styles.infoImgBox} alt="icon" />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>Continent:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
        <div className={Styles.infoBox}>
          <img src={proxyIcon} className={Styles.infoImgBox} alt="icon" />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>Proxy:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
        <div className={Styles.infoBox}>
          <img src={providerIcon} className={Styles.infoImgBox} alt="icon" />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>Provider:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
        <div className={Styles.infoBox}>
          <img
            src={connectionTypeIcon}
            className={Styles.infoImgBox}
            alt="icon"
          />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>Connection Type:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
        <div className={Styles.infoBox}>
          <img src={databaseIcon} className={Styles.infoImgBox} alt="icon" />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>ASN:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
        <div className={Styles.infoBox}>
          <img src={marker} className={Styles.infoImgBox} alt="icon" />
          <p className={Styles.infoText}>
            <span className={Styles.infoTextHead}>Location:</span>{" "}
            <LoadingElement2 />
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.inputContainer}>
        <input
          type="text"
          placeholder={userIp ? userIp : ""}
          className={Styles.searchBox}
          id="ipToSearch"
          onKeyDown={(eventOb) => {
            if (eventOb.key && eventOb.key == "Enter") {
              fetchIpDetails();
            }
          }}
        />
        <img
          src={locationMarker}
          className={Styles.searchIcon}
          alt="marker"
          onClick={() => {
            fetchIpDetails();
          }}
        />
      </div>
      {ipFetchLoading ? (
        loadingUi()
      ) : checkIpDetails ? (
        <div className={Styles.body}>
          <div className={Styles.infoBox}>
            <img src={locationIcon} className={Styles.infoImgBox} alt="icon" />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>Country:</span>{" "}
              {checkIpDetails.country}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <img src={worldIcon} className={Styles.infoImgBox} alt="icon" />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>Continent:</span>{" "}
              {checkIpDetails.continent}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <img src={proxyIcon} className={Styles.infoImgBox} alt="icon" />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>Proxy:</span>{" "}
              {checkIpDetails.proxy}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <img src={providerIcon} className={Styles.infoImgBox} alt="icon" />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>Provider:</span>{" "}
              {checkIpDetails.provider}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <img
              src={connectionTypeIcon}
              className={Styles.infoImgBox}
              alt="icon"
            />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>Connection Type:</span>{" "}
              {checkIpDetails.type}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <img src={databaseIcon} className={Styles.infoImgBox} alt="icon" />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>ASN:</span>{" "}
              {checkIpDetails.asn}
            </p>
          </div>
          <div className={Styles.infoBox}>
            <img src={marker} className={Styles.infoImgBox} alt="icon" />
            <p className={Styles.infoText}>
              <span className={Styles.infoTextHead}>Location:</span>{" "}
              <a
                href={`https://maps.google.com/?q=${checkIpDetails.latitude},${checkIpDetails.longitude}`}
                target="_blank"
                rel="noreferrer"
              >
                https://maps.google.com/?q={checkIpDetails.latitude},
                {checkIpDetails.longitude}
              </a>
            </p>
          </div>
        </div>
      ) : (
        // TODO: NEED A DEFAULT UI
        ""
      )}
    </div>
  );
}

export default IpReverse;
