import React, { Fragment } from "react";
import Styles from "./ErrorContainer.module.css";
import errorIcon from "../../assets/images/icons/exclamation.svg";
import classNames from "classnames";
function ErrorContainer(props) {

  function renderUi() {
    if (props.type == "style1") {
      return (
        <Fragment>
          <img src={errorIcon} className={Styles.errorIcon} alt="error" />
          <p className={Styles.errorText}>{props.state.error}</p>
        </Fragment>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }
  return (
    <div
      className={classNames(
        Styles.container,
        props.state.error ? Styles.containerActive : ""
      )}
    >
      {renderUi()}
    </div>
  );
}

export default ErrorContainer;
