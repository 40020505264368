import React from "react";
import Styles from "./SigningIn.module.css";
import rocketImg from "../../assets/images/other_images/Rocket.png";
function SigningIn(props) {
  return (
    <div className={Styles.container}>
      <div className={Styles.signingTextBox}>
        <p className={Styles.signingText1}>Signing in...</p>
        <div className={Styles.signingInfoWrapper}>
          <p className={Styles.signingInfoText1}>Do you know?</p>
          <p className={Styles.signingInfoText2}>
            Botza is your personal magic wand, that increases functionality of
            Slack and MS Teams by 10x
          </p>
        </div>
      </div>
      <div className={Styles.signingImg}>
        <img src={rocketImg} alt="rocket" className={Styles.rocketImg} />
      </div>
    </div>
  );
}

export default SigningIn;
