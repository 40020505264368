import React, { Fragment, useEffect, useState } from "react";
import Styles from "./LoginForm.module.css";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import slack_logo from "../../../assets/images/logos/slack_logo.svg";
import break_line from "../../../assets/images/other_images/break_line.svg";
import break_line_2 from "../../../assets/images/other_images/break_line_2.svg";
import ErrorContainer from "../../common/ErrorContainer";
import Input from "../../Elements/Input";
import FormButton from "../../Elements/FormButton";
import utils from "../../../utils";
import loadingGif from "../../../assets/images/gifs/loading_gif_1.gif";
import {
  slackRedirect,
  slackAuthPoll,
  signInUsingSlack,
} from "../../../reducers/loginReducer";

function LoginForm__Email(props) {
  let { errorState, changeErrorState, formState, changeFormState, clearState } =
    props.data;

  let [isSlackRedirectLoading, changeSlackReLoading] = useState(false);
  let [processAuth, changeAuth] = useState(false);
  let [openedWindow,changeOpenedWindow]=useState(null)
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let slackRedirectResult = useSelector(
    (state) => state.loginReducer.slackRedirect
  );
  let slackRedirectLoading = useSelector(
    (state) => state.loginReducer.slackRedirectLoading
  );
  let slackConnectConfirmData=useSelector((state)=>state.loginReducer.slackConnectConfirmation);
  let slackConnectConfirmationError=useSelector((state)=>state.loginReducer.slackConnectConfirmationError);
  let slackAuth = useSelector((state) => state.loginReducer.slackAuth);

  let slackSignin = useSelector((state) => state.loginReducer.slackSignin);
  //TODO: HANDLE LOGIN ERRORS

  useEffect(() => {
    if (slackSignin && slackSignin?.token) {
      changeSlackReLoading(false);
      utils.setVariable("onboarding", slackSignin?.onboarding);
      utils.setToken(slackSignin.token);
      utils.removeVariable("slack_redirect_code");
      if (slackSignin?.onboarding) {
        navigate("/welcome");
      } else {
        navigate("/launch");
      }
    }
  }, [slackSignin]);

  useEffect(()=>{
    
    if(slackConnectConfirmData){
      
      if(openedWindow){
       
        openedWindow.close();
        changeOpenedWindow(null)
      }
     
    }
  },[slackConnectConfirmData])

  useEffect(()=>{
    if(slackConnectConfirmationError){
      changeAuth(false);
      changeErrorState((oldState) => {
        let temp = { ...oldState };
        temp.error = "Something went wrong, Please try again";
        return temp;
      });
      if(openedWindow){
       
        openedWindow.close();
        changeOpenedWindow(null)
      }
   
    }
  },[slackConnectConfirmationError])

  useEffect(() => {
    if (slackRedirectResult?.url && slackRedirectResult?.code) {
      utils.setVariable("slack_redirect_code", slackRedirectResult.code);
      changeAuth(true);
      let windowOpened=window.open(slackRedirectResult.url, "_blank");

      changeOpenedWindow(windowOpened)
    }
  }, [slackRedirectResult]);

  useEffect(() => {
    if (processAuth) {
      let code = utils.fetchVariable("slack_redirect_code");
      if (code) {
        dispatch(slackAuthPoll(code));
      } else {
        changeAuth(false);
      }
    }
  }, [processAuth]);

  useEffect(() => {
    if (slackAuth) {
      let code = utils.fetchVariable("slack_redirect_code");
      if (slackAuth?.status == "success") {
        if (code) {
          dispatch(signInUsingSlack(code));
        }
        changeAuth(false);
      } else {
        if (code && processAuth) {
          setTimeout(() => {
            dispatch(slackAuthPoll(code));
          }, 5000);
        }
      }
    }
  }, [slackAuth]);

  useEffect(() => {
    if (slackRedirectLoading) {
      changeSlackReLoading(true);
    }
  }, [slackRedirectLoading]);
  function signInWithSlack() {
    dispatch(slackRedirect());
  }

  function proceedToPassword() {
    let userInput = document.getElementById("userEmail").value;

    if (
      userInput &&
      !utils.isStringEmpty(userInput) &&
      utils.checkEmail(userInput)
    ) {
      changeFormState((oldState) => {
        let temp = { ...oldState };
        temp.type = "password";
        temp.data = {
          email: userInput,
        };
        return temp;
      });
    } else {
      changeErrorState((oldState) => {
        let temp = { ...oldState };
        temp.error = "invalid email";
        return temp;
      });
    }
  }
  return (
    <Fragment>
      <div className={Styles.loginForm__section}>
        <ErrorContainer type="style1" state={errorState} />
        <Input
          type="text"
          placeholder="Email"
          id="userEmail"
          keyPressHandler={(e) => {
            if (e.key == "Enter") {
              proceedToPassword();
            }
          }}
          handler={(e) => {
            clearState();

            // changeInputData((oldState) => {
            //   let temp = { ...oldState };
            //   temp.email = e.target.value;
            //   return temp;
            // });
          }}
        />
        <FormButton
          type="style1"
          handler={() => {
            proceedToPassword();
          }}
        >
          Next
        </FormButton>
      </div>
      <div className={Styles.breakContainer}>
        <img src={break_line_2} className={Styles.breakLine} alt="breakLine" />
        <p>Or</p>
        <img src={break_line} className={Styles.breakLine} alt="breakLine" />
      </div>
      <div className={Styles.buttonWrapper}>
        <FormButton
          type="style2"
          handler={() => {
            if (!isSlackRedirectLoading) {
              signInWithSlack();
            }
          }}
        >
          <img src={slack_logo} alt="slack" className={Styles.slackLogo} /> Sign
          in with Slack
        </FormButton>
        {isSlackRedirectLoading ? (
          <img src={loadingGif} className={Styles.loadingGif} alt="logginin" />
        ) : null}
      </div>
    </Fragment>
  );
}

export default LoginForm__Email;
