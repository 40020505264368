import React from "react";
import Styles from "./Sidebar.module.css";
import Logo from "../../../assets/images/logos/botza_logo_1.svg";
import classnames from "classnames";
import DashboardIcon from "../../../assets/images/icons/DashboardIcon_menu.svg";
import WebmasterIcon from "../../../assets/images/icons/WebmasterIcon_menu.svg";
import TaskIcon from "../../../assets/images/icons/TaskIcon_menu.svg";
import BugIcon from "../../../assets/images/icons/BugIcon_menu.svg";
import SettingsIcon from "../../../assets/images/icons/SettingsIcon_menu.svg";

import DashboardIconActive from "../../../assets/images/icons/DashboardActiveIcon_menu.svg";
import WebmasterIconActive from "../../../assets/images/icons/WebmasterActiveIcon_menu.svg";
import TaskIconActive from "../../../assets/images/icons/TaskActiveIcon_menu.svg";
import BugIconActive from "../../../assets/images/icons/BugActiveIcon_menu.svg";
import SettingsIconActive from "../../../assets/images/icons/SettingsActiveIcon_menu.svg";
import { useLocation, useNavigate } from "react-router-dom";

function Sidebar(props) {
  let location = useLocation();
  let navigate = useNavigate();
  function getMenuItems() {
    return [
      {
        name: "Dashboard",
        icon: DashboardIcon,
        activeIcon: DashboardIconActive,
        url: "/dashboard",
      },

      {
        name: "Task Panel",
        icon: TaskIcon,
        activeIcon: TaskIconActive,
        url: "/task-panel",
      },
      {
        name: "Bug Panel",
        icon: BugIcon,
        activeIcon: BugIconActive,
        url: "/bug-panel",
      },
      {
        name: "Webmaster",
        icon: WebmasterIcon,
        activeIcon: WebmasterIconActive,
        url: "/webmaster",
      },
      {
        name: "Settings",
        icon: SettingsIcon,
        activeIcon: SettingsIconActive,
        url: "/settings",
      },
    ];
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.logoContainer}>
        <img src={Logo} className={Styles.logo} alt="Botza" />
      </div>

      <div className={Styles.sidebarMenuContainer}>
        {getMenuItems().map((menuItemObject, index) => {
          return (
            <div
              key={index}
              className={Styles.sidebarMenuItemContainer}
              onClick={() => {
                navigate(menuItemObject.url);
              }}
            >
              {location.pathname == menuItemObject.url ? (
                <div className={Styles.activebar}></div>
              ) : (
                ""
              )}

              <img
                src={menuItemObject.icon}
                className={classnames(
                  Styles.sidebarMenuItem,
                  Styles.sidebarMenuItemInactive,
                  location.pathname == menuItemObject.url
                    ? Styles.sideMenuItemHidden
                    : ""
                )}
                alt={menuItemObject.name}
              />
              <img
                src={menuItemObject.activeIcon}
                className={classnames(
                  Styles.sidebarMenuItem,
                  Styles.sidebarMenuItemActive,
                  location.pathname == menuItemObject.url
                    ? Styles.sidebarMenuItem_active
                    : ""
                )}
                alt={menuItemObject.name}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Sidebar;
