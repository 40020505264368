import "./App.css";
import { useState, createContext, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  redirect,
  useLocation,
} from "react-router-dom";
import Layout from "./components/Login/Layout";
import LoggedInLayout from "./components/LoggedInComponents/Layout.js";
import PrivateRoute from "./components/PrivateRoutes/PrivateRoute";

export const DarkModeContext = createContext();

function App() {
  let [isDarkModeEnabled, changeDarkMode] = useState(false);

  const appColorVariables = isDarkModeEnabled
    ? {
        "--purple": "#725aff",
        "--white": "rgba(23, 23, 23, 0.70)",
        backgroundColor: "#121212",
        "--lightPurple": "#9381ff",
        "--palePurple": "rgba(207, 200, 249, 0.5)",
        "--black": "#FFF",
        "--lightBlack": "#979EA8",
        "--lightGrey": "rgba(58, 58, 58, 0.1)",
        "--formColor ": "#418de81a",
        "--grey": "#979EA8",
        "--formGrey": "#121212",
        "--purpleShadow": "rgba(114, 90, 255, 0.5)",
        "--blue": "#007ff3",
        "--newGrey": "#979EA8",
        "--errorRed": "#ff1e1e",
        "--green": "#11a371",
        "--greenAlert": "#018a5b",
        "--shadowColor": "rgba(87, 100, 118, 0.2)",
        "--mainBackground": "rgba(47, 47, 47, 0.35)",
        "--shadowPurple": "#725aff66",
        "--alertGreen": "#64b769",
        "--alertRed": "#ff1e1e ",
        "--alertInfo": " #006dff",
        "--alertWarning": "#fd871f",
        "--formInputColor_1": "rgba(65, 141, 232, 0.10)",
        "--orange": "#E57140",
        "--darkBlue": "#3551E0",
        "--lightGreen": "#536C3C",
      }
    : {
        "--purple": "#725aff",
        backgroundColor: "transparent",
        "--white": "#ffffff",
        "--lightPurple": "#9381ff",
        "--palePurple": "rgba(207, 200, 249, 0.5)",
        "--black": "#3a3a3a",
        "--lightBlack": "#3a3a3ab2",
        "--lightGrey": "rgba(58, 58, 58, 0.1)",
        "--formColor": "#418de81a",
        "--grey": "#3a3a3a66",
        "--formGrey": "#f7f7f7",
        "--purpleShadow": "rgba(114, 90, 255, 0.5)",
        "--blue": "#007ff3",
        "--newGrey": "#979ea8",
        "--errorRed": "#ff1e1e",
        "--green": "#11a371",
        "--greenAlert": "#018a5b",
        "--shadowColor": "rgba(87, 100, 118, 0.2)",
        "--mainBackground": "rgba(248, 248, 248, 0.5)",
        "--shadowPurple": "#725aff66",
        "--alertGreen": "#64b769",
        "--alertRed": "#ff1e1e ",
        "--alertInfo": " #006dff",
        "--alertWarning": "#fd871f",
        "--formInputColor_1": "rgba(65, 141, 232, 0.10)",
        "--orange": "#E57140",
        "--darkBlue": "#3551E0",
        "--lightGreen": "#536C3C",
      };
  return (
    <div className="App" style={appColorVariables}>
      <DarkModeContext.Provider value={{ isDarkModeEnabled, changeDarkMode }}>
        <Router>
          <Routes>
            <Route path="/login" element={<Layout page="login" />} />
            <Route path="/auth-redirect" element={<Layout page="authRedirect" />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/welcome" element={<Layout page="welcome" />} />
              <Route path="/connect" element={<Layout page="connect" />} />
              <Route path="/launch" element={<Layout page="launch" />} />
              <Route
                path="/dashboard"
                element={<LoggedInLayout type="dashboard" />}
              />
              <Route
                path="/task-panel"
                element={<LoggedInLayout type="task-panel" />}
              />
              <Route
                path="/bug-panel"
                element={<LoggedInLayout type="bug-panel" />}
              />
              <Route
                path="/webmaster"
                element={<LoggedInLayout type="webmaster" />}
              />
              <Route
                path="/settings"
                element={<LoggedInLayout type="settings" />}
              />
              <Route
                path="/"
                Component={() => {
                  return <Navigate to="/dashboard" />;
                }}
              />
            </Route>
          </Routes>
        </Router>
      </DarkModeContext.Provider>
    </div>
  );
}

export default App;
