import React, { useState } from "react";
import utils from "../../../../utils";
import Styles from "./WorkspaceDropdown.module.css";
import DropdownMenu from "../../../common/DropdownMenu/DropdownMenu";
import defaultWorkspaceIcon from "../../../../assets/images/icons/workspaceIcon.svg";

function WorkspaceDropdown(props) {
  let [isDropdownVisible, updateDropdownState] = useState(false);

  function getCurrentWorkspaceIcon() {
    if (props.profileData && props.profileData.teams) {
      let teamOb = utils.fetchTeamDataFromArray(
        props.profileData.teams,
        utils.fetchVariable("current_team")
      );

      if (teamOb.icon_url) {
        return teamOb.icon_url;
      } else {
        return defaultWorkspaceIcon;
      }
    } else {
      return defaultWorkspaceIcon;
    }
  }
  return (
    <>
      <img
        src={getCurrentWorkspaceIcon()}
        alt="workspace"
        className={Styles.headerIcon2}
        onClick={() => {
          isDropdownVisible
            ? updateDropdownState(false)
            : updateDropdownState(true);
        }}
      />
      <DropdownMenu
        isActive={isDropdownVisible}
        updateDropdownState={updateDropdownState}
        id="workspaceDropdown"
        styles={{
          right: 0,
        }}
      >
        <div
          style={{
            color: "var(--black)",
            cursor: "default",
            textTransform: "uppercase",
            marginBottom: "0.5em",
            display: "flex",
            alignItems: "center",
            gap: "3px",
            minWidth: "20em",
          }}
        >
          <div
            style={{
              height: "1px",
              background: "var(--grey)",
              width: "3%",
              content: "",
            }}
          ></div>
          workspaces
          <div
            style={{
              height: "1px",
              background: "var(--grey)",
              width: "100%",
              content: "",
            }}
          ></div>
        </div>
        {props.profileData && props.profileData.teams
          ? props.profileData.teams.map((workspaceOb, index) => {
              return (
                <div
                  className={Styles.dropdownElement}
                  key={index}
                  onClick={() => {
                    if (workspaceOb.team_id) {
                      utils.setVariable("current_team", workspaceOb.team_id);
                      window.location.reload();
                    }
                  }}
                >
                  <img
                    src={
                      workspaceOb.icon_url
                        ? workspaceOb.icon_url
                        : defaultWorkspaceIcon
                    }
                    className={Styles.menuIcon}
                    alt="My Settings"
                  />{" "}
                  <div className={Styles.workspaceDetails}>
                    <p className={Styles.workspaceName}>
                      {workspaceOb.team_name}
                    </p>
                    <p className={Styles.workspaceUrl}>
                      {workspaceOb.team_domain}.slack.com
                    </p>
                  </div>
                </div>
              );
            })
          : ""}
      </DropdownMenu>
    </>
  );
}

export default WorkspaceDropdown;
