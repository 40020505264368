import React from "react";
import Styles from "./Login.module.css";
import rocketImg from "../../assets/images/other_images/Rocket.png";
import LoginForm from "./LoginForm/LoginForm";
function Login(props) {
  return (
    <div className={Styles.loginPageContainer}>
      <div className={Styles.loginPageDisplay}>
        <p className={Styles.loginPageDisplay__text}>Login to</p>
        <p className={Styles.loginPageDisplay__text}>
          Explore the <span className={Styles.gradientText}>Magic</span>
        </p>
        <img src={rocketImg} alt="rocket" className={Styles.rocketImg} />
      </div>

      <div className={Styles.loginPageForm}>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
