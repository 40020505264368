import React, { useState, useEffect } from "react";
import Styles from "./Graph.module.css";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getGraphData } from "../../../../reducers/taskReducer";
import { getBugGraphData } from "../../../../reducers/bugReducer";
import classnames from "classnames";
function Graph(props) {
  const dispatch = useDispatch();

  let graphData = useSelector((state) => state.taskReducer.graphData);
  let graphDataLoading = useSelector(
    (state) => state.taskReducer.graphDataLoading
  );

  let bugGraphData = useSelector((state) => state.bugReducer.graphData);
  let bugGraphDataLoading = useSelector(
    (state) => state.bugReducer.graphDataLoading
  );

  useEffect(() => {
    if (props.type == "task") {
      dispatch(getGraphData());
    } else if (props.type == "bug") {
      dispatch(getBugGraphData());
    }
  }, [props.type]);

  let bugStyles = {
    new: {
      graphStrokeColorStart: "rgba(24, 187, 187, 1)",
      graphStrokeColorStop: "rgba(24, 187, 187, 0)",
    },
    resolved: {
      graphStrokeColorStart: "rgba(53, 81, 224, 1)",
      graphStrokeColorStop: "rgba(53, 81, 224, 0)",
    },
    priority: {
      graphStrokeColorStart: "rgba(206, 86, 19, 1)",
      graphStrokeColorStop: "rgba(206, 86, 19, 0)",
    },
  };

  let taskStyles = {
    new: {
      graphStrokeColorStart: "rgba(44, 174, 130, 1)",
      graphStrokeColorStop: "rgba(44, 174, 130, 0)",
    },
    completed: {
      graphStrokeColorStart: "rgba(65, 141, 232, 1)",
      graphStrokeColorStop: "rgba(65, 141, 232, 0)",
    },
    priority: {
      graphStrokeColorStart: "rgba(249, 89, 131, 1)",
      graphStrokeColorStop: "rgba(249, 89, 131, 0)",
    },
  };

  let dummyData = [
    { name: "Mon", new: 0, completed: 0, priority: 0 },
    { name: "Tue", new: 0, completed: 0, priority: 0 },
    { name: "Wed", new: 0, completed: 0, priority: 0 },
    { name: "Thu", new: 0, completed: 0, priority: 0 },
    { name: "Fri", new: 0, completed: 0, priority: 0 },
    { name: "Sat", new: 0, completed: 0, priority: 0 },
    { name: "Sun", new: 0, completed: 0, priority: 0 },
  ];
  return (
    <div
      className={classnames(
        Styles.container,
        graphDataLoading || bugGraphDataLoading ? Styles.loadingContainer : ""
      )}
    >
      <div className={Styles.headerContainer}>
        <p className={Styles.headerText}>Weekly Activity</p>
        <div className={Styles.labelWrapper}>
          <div className={Styles.labelBox}>
            <div
              className={Styles.labelMarker}
              style={{
                background:
                  props.type == "task"
                    ? taskStyles.new.graphStrokeColorStart
                    : bugStyles.new.graphStrokeColorStart,
              }}
            ></div>
            <p className={Styles.labelText}>new</p>
          </div>
          <div className={Styles.labelBox}>
            <div
              className={Styles.labelMarker}
              style={{
                background:
                  props.type == "task"
                    ? taskStyles.completed.graphStrokeColorStart
                    : bugStyles.resolved.graphStrokeColorStart,
              }}
            ></div>
            <p className={Styles.labelText}>
              {props.type == "task" ? "completed" : "resolved"}
            </p>
          </div>
          <div className={Styles.labelBox}>
            <div
              className={Styles.labelMarker}
              style={{
                background:
                  props.type == "task"
                    ? taskStyles.priority.graphStrokeColorStart
                    : bugStyles.priority.graphStrokeColorStart,
              }}
            ></div>
            <p className={Styles.labelText}>
              {" "}
              {props.type == "task" ? "high priority" : "high severity"}
            </p>
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <ResponsiveContainer>
          <AreaChart
            data={
              graphDataLoading || bugGraphDataLoading
                ? dummyData
                : props.type == "task"
                ? graphData
                : bugGraphData
            }
            margin={{ top: 0, right: 0, left: -20, bottom: 20 }}
          >
            <defs>
              <linearGradient id="panelData" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="2%"
                  stopColor={
                    props.type == "bug"
                      ? bugStyles.new.graphStrokeColorStart
                      : taskStyles.new.graphStrokeColorStart
                  }
                  stopOpacity={0.2}
                />
                <stop
                  offset="50%"
                  stopColor={
                    props.type == "bug"
                      ? bugStyles.new.graphStrokeColorStop
                      : taskStyles.new.graphStrokeColorStop
                  }
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient
                id="completedPanelData"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="2%"
                  stopColor={
                    props.type == "bug"
                      ? bugStyles.resolved.graphStrokeColorStart
                      : taskStyles.completed.graphStrokeColorStart
                  }
                  stopOpacity={0.2}
                />
                <stop
                  offset="50%"
                  stopColor={
                    props.type == "bug"
                      ? bugStyles.resolved.graphStrokeColorStop
                      : taskStyles.completed.graphStrokeColorStop
                  }
                  stopOpacity={0}
                />
              </linearGradient>
              <linearGradient
                id="priorityPanelData"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="2%"
                  stopColor={
                    props.type == "bug"
                      ? bugStyles.priority.graphStrokeColorStart
                      : taskStyles.priority.graphStrokeColorStart
                  }
                  stopOpacity={0.2}
                />
                <stop
                  offset="50%"
                  stopColor={
                    props.type == "bug"
                      ? bugStyles.priority.graphStrokeColorStop
                      : taskStyles.priority.graphStrokeColorStop
                  }
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            <XAxis
              dataKey="name"
              axisLine={false}
              style={{
                fontFamily: "inherit",
                fill: "var(--newGrey)",
                fontWeight: 600,
                fontSize: "1rem",
              }}
              padding={{ left: 10 }}
              tickMargin={20}
            />
            <YAxis
              tickSize={5}
              tickCount={3}
              minTickGap={5}
              axisLine={false}
              tickMargin={20}
              style={{
                fontFamily: "inherit",
                fill: "var(--newGrey)",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            />
            <CartesianGrid strokeDasharray="5 8" vertical={false} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="new"
              stroke={
                props.type == "bug"
                  ? bugStyles.new.graphStrokeColorStart
                  : taskStyles.new.graphStrokeColorStart
              }
              fillOpacity={1}
              fill="url(#panelData)"
            />
            <Area
              type="monotone"
              dataKey="completed"
              stroke={
                props.type == "bug"
                  ? bugStyles.resolved.graphStrokeColorStart
                  : taskStyles.completed.graphStrokeColorStart
              }
              fillOpacity={1}
              fill="url(#completedPanelData)"
            />
            <Area
              type="monotone"
              dataKey="priority"
              stroke={
                props.type == "bug"
                  ? bugStyles.priority.graphStrokeColorStart
                  : taskStyles.priority.graphStrokeColorStart
              }
              fillOpacity={1}
              fill="url(#priorityPanelData)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default Graph;
