import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ipResolverApi from "../api/IpResolverApi";

let initialState = {
  ipResolveError: null,
  checkIpData: null,
  checkIpLoading: false,
  savedIps: [],
  savedIpsLoading: false,
  recentIps: [],
  recentIpsLoading: false,
};

export const checkIp = createAsyncThunk(
  "ipResolve/checkIp",
  async (ip, { rejectWithValue }) => {
    const response = await ipResolverApi.checkIp(ip);
    if (response.data.status == "success") {
      return response.data.result;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const fetchSavedIp = createAsyncThunk(
  "ipResolve/fetchSavedIp",
  async (data = null, { rejectWithValue }) => {
    const response = await ipResolverApi.fetchSavedIps();
    if (response.data.status == "success") {
      return response.data.result;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const fetchRecentIp = createAsyncThunk(
  "ipResolve/fetchRecentIp",
  async (data = null, { rejectWithValue }) => {
    const response = await ipResolverApi.fetchRecentIps();
    if (response.data.status == "success") {
      return response.data.result;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const ipResolveSlice = createSlice({
  name: "ipResolve",
  initialState,

  extraReducers: (builder) => {
    //Login using email/pass
    builder.addCase(checkIp.pending, (state, action) => {
      state.checkIpLoading = true;
      state.checkIpData = null;
      state.ipResolveError = null;
    });
    builder.addCase(checkIp.rejected, (state, action) => {
      state.checkIpLoading = false;
      state.checkIpData = null;
      if (action.payload) {
        state.ipResolveError = action.payload;
      } else if (action.error) {
        state.ipResolveError = { error: action.error.message };
      }
    });
    builder.addCase(checkIp.fulfilled, (state, action) => {
      state.checkIpLoading = false;
      state.checkIpData = action.payload;
      state.ipResolveError = null;
    });

    builder.addCase(fetchSavedIp.pending, (state, action) => {
      state.savedIpsLoading = true;
      state.savedIps = null;
      state.ipResolveError = null;
    });
    builder.addCase(fetchSavedIp.rejected, (state, action) => {
      state.savedIpsLoading = false;
      state.savedIps = null;
      if (action.payload) {
        state.ipResolveError = action.payload;
      } else if (action.error) {
        state.ipResolveError = { error: action.error.message };
      }
    });
    builder.addCase(fetchSavedIp.fulfilled, (state, action) => {
      state.savedIpsLoading = false;
      state.savedIps = action.payload;
      state.ipResolveError = null;
    });

    builder.addCase(fetchRecentIp.pending, (state, action) => {
      state.recentIpsLoading = true;
      state.recentIps = null;
      state.ipResolveError = null;
    });
    builder.addCase(fetchRecentIp.rejected, (state, action) => {
      state.recentIpsLoading = false;
      state.recentIps = null;
      if (action.payload) {
        state.ipResolveError = action.payload;
      } else if (action.error) {
        state.ipResolveError = { error: action.error.message };
      }
    });
    builder.addCase(fetchRecentIp.fulfilled, (state, action) => {
      state.recentIpsLoading = false;
      state.recentIps = action.payload;
      state.ipResolveError = null;
    });
  },
});

export const { ipResolve } = ipResolveSlice.actions;
export default ipResolveSlice.reducer;
