import React, { useEffect, useState } from "react";
import Styles from "./MyTeamWidget.module.css";
import userIconDefault from "../../../../../assets/images/icons/userIconDefault.svg";
import phoneIcon from "../../../../../assets/images/icons/phoneIcon.svg";
import mailIcon from "../../../../../assets/images/icons/mailIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getMembers } from "../../../../../reducers/teamReducer";
import LoadingElement2 from "../../../../common/LoadinElement2/LoadingElement2";
import ScrollableComponent from "../../../../common/ScrollableComponent";
function MyTeamWidget(props) {
  const dispatch = useDispatch();
  const membersData = useSelector((state) => state.teamReducer.getMembersData);
  const membersDataLoading = useSelector(
    (state) => state.teamReducer.getMembersLoading
  );
  const membersDataError = useSelector((state) => state.teamReducer.teamErrors);
  const [members, changeMembers] = useState([]);
  useEffect(() => {
    if (membersData) changeMembers(membersData);
  }, [membersData]);

  useEffect(() => {
    dispatch(getMembers());
  }, []);

  useEffect(() => {
    if (membersDataError) {
   
      props.changeAlertBoxData({
        isVisible: true,
        message: "Something went wrong while fetching team data. Try again",
        type: "danger",
      });
    }
  }, [membersDataError]);

  function loadingUI() {
    return (
      <div className={Styles.loadingContainer}>
        <div className={Styles.memberInfoText}>
          <p className={Styles.memberName}>
            <LoadingElement2 styles={{ width: "12em" }} />
          </p>
          <p className={Styles.memberDesignation}>
            <LoadingElement2 styles={{ width: "8em" }} />
          </p>
        </div>
        <div className={Styles.memberInfoText}>
          <p className={Styles.memberName}>
            <LoadingElement2 styles={{ width: "12em" }} />
          </p>
          <p className={Styles.memberDesignation}>
            <LoadingElement2 styles={{ width: "8em" }} />
          </p>
        </div>
        <div className={Styles.memberInfoText}>
          <p className={Styles.memberName}>
            <LoadingElement2 styles={{ width: "12em" }} />
          </p>
          <p className={Styles.memberDesignation}>
            <LoadingElement2 styles={{ width: "8em" }} />
          </p>
        </div>
        <div className={Styles.memberInfoText}>
          <p className={Styles.memberName}>
            <LoadingElement2 styles={{ width: "12em" }} />
          </p>
          <p className={Styles.memberDesignation}>
            <LoadingElement2 styles={{ width: "8em" }} />
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <p className={Styles.heading}>my team</p>
      </div>
      <div className={Styles.body}>
        {membersDataLoading
          ? loadingUI()
          : members && members.length > 0
          ? members.map((val, index) => {
              return (
                <div className={Styles.memberInfoBox}>
                  <div className={Styles.memberImageBox}>
                    <img
                      src={val.avatar ?? userIconDefault}
                      alt="img"
                      className={Styles.userIcon}
                    />
                    <div className={Styles.toolTip}>
                      {val.phoneNumber ? (
                        <p>
                          <img
                            src={phoneIcon}
                            alt="phone"
                            className={Styles.tooltipIcon}
                          />
                          {val.phoneNumber}
                        </p>
                      ) : (
                        ""
                      )}
                      {val.email ? (
                        <p>
                          <img
                            src={mailIcon}
                            alt="phone"
                            className={Styles.tooltipIcon}
                          />
                          {val.email}
                        </p>
                      ) : (
                        ""
                      )}

                      {val.bio ? <p>{val.bio}</p> : ""}
                    </div>
                    {val.online ? <div className={Styles.onlineDot}></div> : ""}
                  </div>
                  <div className={Styles.memberInfoText}>
                    <p className={Styles.memberName}>
                      {val.name ? val.name : "Unnamed"}
                    </p>
                    <p className={Styles.memberDesignation}>
                      {val.designation}
                    </p>
                  </div>
                </div>
              );
            })
          : "No team mates"}
      </div>
    </div>
  );
}

export default MyTeamWidget;
