import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Styles from "./SigningIn.module.css";
import { useDispatch, useSelector } from "react-redux";
import { slackConnectConfirm } from "../../reducers/loginReducer";
function AuthRedirect(props) {
  let dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let code = searchParams.get("code");
    let state = searchParams.get("state");
    let error = searchParams.get("error");
    dispatch(slackConnectConfirm({ code, state, error }));
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.signingTextBox}>
        <p className={Styles.signingText1}>Please wait...</p>
        <div className={Styles.signingInfoWrapper}>
          <p className={Styles.signingInfoText1}>Do you know?</p>
          <p className={Styles.signingInfoText2}>
            Botza is your personal magic wand, that increases functionality of
            Slack and MS Teams by 10x
          </p>
        </div>
      </div>
    </div>
  );
}

export default AuthRedirect;
