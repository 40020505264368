import React, { useEffect, useState, useContext } from "react";
import Styles from "./Dashboard.module.css";
import { useSelector } from "react-redux";
import TaskBugWidget from "./Widgets/TaskBugWidget/TaskBugWidget";
import UptimeWidget from "./Widgets/UptimeWidget/UptimeWidget";
import MyTeamWidget from "./Widgets/MyTeamWidget/MyTeamWidget";
import IpReverse from "./Widgets/IpReverse/IpReverse";
import SiteRankWidget from "./Widgets/SiteRankWidget/SiteRankWidget";
import Banner from "./Banner/Banner";
import CalenderWidget from "./Widgets/CalenderWidget/CalenderWidget";
import AlertBox1 from "../../common/AlertBox_1/AlertBox_1";

function Dashboard(props) {
  let profileData = useSelector((state) => {
    return state.profileReducer.profileData;
  });

  let [alertBoxData, changeAlertBoxData] = useState({
    isVisible: false,
    message: "",
    type: "success",
  });

  //TODO: HANDLE ALL ERRORS HERE AND CHECK IF TOKEN IS INVALID OR NOT
  return (
    <div className={Styles.container}>
      <AlertBox1
        changeAlertState={changeAlertBoxData}
        message={alertBoxData.message}
        isVisible={alertBoxData.isVisible}
        type={alertBoxData.type}
      />
      <div className={Styles.dashboardColumn1}>
        <div className={Styles.userGreetingBox}>
          <p className={Styles.userGreetingBox_text1}>
            hi,{" "}
            <span>
              {profileData && profileData.name ? profileData.name : "user"}
            </span>{" "}
          </p>
          <p className={Styles.userGreetingBox_text2}>
            Welcome back , here's your activity today
          </p>
        </div>
        <div className={Styles.taskBugWidget}>
          <TaskBugWidget changeAlertBoxData={changeAlertBoxData} />
        </div>
        <div className={Styles.uptimeWidget}>
          <UptimeWidget changeAlertBoxData={changeAlertBoxData} />
        </div>
        <div>
          <div className={Styles.teamWidget}>
            <MyTeamWidget changeAlertBoxData={changeAlertBoxData} />
          </div>
          <div className={Styles.adWidget}>
            <Banner />
          </div>
        </div>

        <div className={Styles.ipReverseWidget}>
          <IpReverse changeAlertBoxData={changeAlertBoxData} />
        </div>

        {/* <div className={Styles.websiteRank}>
          <SiteRankWidget />
        </div> */}
      </div>

      <div className={Styles.dashboardColumn}>
        <div className={Styles.calenderWidget}>
          <CalenderWidget changeAlertBoxData={changeAlertBoxData} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
