import { login } from "../reducers/loginReducer";
import { config } from "./config";
import axios from "axios";
let loginApi = {};

loginApi.loginUsingEmailPass = (email, password) => {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
  return axios.post(`${config.url}/auth/token`, formData);
};

loginApi.sendMagicPin = (email) => {
  let formData = new FormData();
  formData.append("email", email);
  return axios.post(`${config.url}/auth/send-magic-code`, formData);
};

loginApi.confirmMagicPin = (confirmCode, pin) => {
  let formData = new FormData();
  formData.append("code", confirmCode);
  formData.append("pin", pin);
  return axios.post(`${config.url}/auth/verify-magic-code`, formData);
};

loginApi.slackRedirect = () => {
  return axios.get(`${config.url}/auth/slack-redirect`);
};

loginApi.slackAuthPoll = (code) => {
  let formData = new FormData();
  formData.append("code", code);
  return axios.post(`${config.url}/auth/slack-authcheck`, formData);
};

loginApi.signInUsingSlack = (code) => {
  let formData = new FormData();
  formData.append("code", code);
  return axios.post(`${config.url}/auth/slack-signin`, formData);
};

loginApi.slackConnect = () => {
  return axios.get(`${config.url}/auth/slack-install`);
};

loginApi.slackConnectConfirmation=({code,state,error})=>{
  let formData = new FormData();
  if(code){
    formData.append('code',code);
  }
  if(state){
    formData.append('state',state);
  }
  if(error){
    formData.append('error',error);
  }
  return axios.post(`${config.url}/auth/slack`, formData);
}
export default loginApi;
