import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let profileApi = {};

profileApi.getProfile = () => {
  return axios.get(`${config.url}/me/profile`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

profileApi.updateProfile = (data) => {
  let formData = new FormData();
  data.forEach((dataOb) => {
    formData.append(dataOb.key, dataOb.value);
  });
  return axios.post(`${config.url}/me/update`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

profileApi.getNotificationSettings = (data) => {
  return axios.get(`${config.url}/me/notifications-settings`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

profileApi.updateNotificationSettings = (data) => {
  let formData = new FormData();
  data.forEach((dataOb) => {
    formData.append(dataOb.key, dataOb.value);
  });
  return axios.post(`${config.url}/me/notifications-settings`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

profileApi.getNotifications = (data) => {
  return axios.get(`${config.url}/me/notifications`, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

export default profileApi;
