let utils = {};

utils.isStringEmpty = (str) => {
  if (str.length == 0) {
    return true;
  } else {
    return false;
  }
};

utils.isNull = (str) => {
  if (str == null) {
    return true;
  } else {
    return false;
  }
};

utils.checkEmail = (email) => {
  let emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(emailRegex);
};

utils.setToken = (token) => {
  localStorage.setItem("botza_token", token);
};

utils.fetchToken = () => {
  return localStorage.getItem("botza_token");
};

utils.removeToken = () => {
  return localStorage.removeItem("botza_token");
};

utils.setVariable = (name, value) => {
  localStorage.setItem(name, value);
};

utils.removeVariable = (name, value) => {
  localStorage.removeItem(name);
};

utils.fetchVariable = (name) => {
  return localStorage.getItem(name);
};

utils.fetchTeamDataFromArray = (teamArr, teamId) => {
  let currentTeamOb = {};
  teamArr.forEach((teamOb) => {
    if (teamOb.team_id == teamId) {
      currentTeamOb = teamOb;
    }
  });
  return currentTeamOb;
};

utils.logoutUser = () => {
  let variableNames = [
    "botza_token",
    "current_team",
    "slack_connect_code",
    "onboarding",
    "slack_redirect_code",
  ];
  variableNames.forEach((varName) => {
    utils.removeVariable(varName);
  });
};

utils.processText = (text, utilityFunctions) => {
  let linkifyText = utils.linkify(text);
  let taggedUserParsedText = utils.searchForTaggedUsers(
    linkifyText,
    utilityFunctions?.getMemberObFromUserid
  );

  return taggedUserParsedText;
};

utils.searchForTaggedUsers = (text, getMemberObFromUserid = null) => {
  if (text && getMemberObFromUserid) {
    let tagUserTextRegex = "";
    tagUserTextRegex = /<@([a-zA-Z0-9]+)>/g;

    const taggedUserIdParsedText = text.replace(
      tagUserTextRegex,
      function (matchedText) {
        // url = url.replace(/\|.*/g, "");
        // url = url.replace(/[<>]/g, "");
        let regex = /<@([^>]+)>/g;
        const matches = [];
        let match;

        while ((match = regex.exec(matchedText)) !== null) {
          matches.push(match[1]);
        }
        let memberOb = getMemberObFromUserid(matches[0]);
        if (memberOb) {
          // return `<img src="${memberOb?.avatar}" alt="user" style="width: 1.5em; border-radius: 5em; height: 1.5em;">`;
          return `<span style="
          background-color: lightblue;
          border-radius: 3px;
          padding: 3px;
          font-size:0.7rem;
          color: #0680b3;
          cursor: pointer;
      ">@${memberOb.name}</span>`;
        } else {
          return matchedText;
        }
      }
    );

    return taggedUserIdParsedText;
  } else {
    return null;
  }
};

utils.linkify = (text) => {
  // Regular expression to match URLs
  if (text) {
    let urlRegex = "";
    urlRegex = /<(https?:\/\/[^\s]+)/g;
    // if (text.search("|") > -1) {
    //   urlRegex = /<([^>|]+)\|([^>]+)>/g;
    // } else {

    // }

    // Replace URLs with anchor tags
    const linkedText = text.replace(urlRegex, function (url) {
      url = url.replace(/\|.*/g, "");
      url = url.replace(/[<>]/g, "");
      return `<a href="${url}" target="_blank">${url.replace("[<|>]", "")}</a>`;
    });

    return linkedText;
  } else {
    return null;
  }
};

utils.capitalize = (title) => {
  return title
    ? title[0].toUpperCase() + title.slice(1).toLowerCase()
    : "Botza";
};

utils.convertUnit = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  if (bytes === 0) return "0 Byte";

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(100 * (bytes / Math.pow(1024, i))) / 100 + " " + sizes[i];
};
export default utils;
