import React from "react";
import Styles from "./SiteRankWidget.module.css";
import SearchIcon from "../../../../../assets/images/icons/searchIcon.svg";
import uptrendIcon from "../../../../../assets/images/icons/uptrendIcon.svg";

import downtrendIcon from "../../../../../assets/images/icons/downtrendIcon.svg";

function SiteRankWidget(props) {
  return (
    <div className={Styles.container}>
      <div className={Styles.textContainer}>
        <input className={Styles.searchBox} type="text" />
        <img src={SearchIcon} className={Styles.searchIcon} alt="" />
      </div>
      <div className={Styles.body}>
        <div className={Styles.section}>
          <p className={Styles.sectionHead}>Alexa</p>
          <div className={Styles.rankBox}>
            <p className={Styles.rankText}>256</p>
            <img src={uptrendIcon} className={Styles.trendIcon} alt="trend" />
          </div>
        </div>
        <div className={Styles.section}>
          <p className={Styles.sectionHead}>Similar Web</p>
          <div className={Styles.rankBox}>
            <p className={Styles.rankText}>256</p>
            <img src={downtrendIcon} className={Styles.trendIcon} alt="trend" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteRankWidget;
