import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let ipResolverApi = {};

ipResolverApi.checkIp = (ip) => {
  const formData = new FormData();
  formData.append("ip", ip);

  return axios.post(`${config.url}/webmaster/ip`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

ipResolverApi.fetchSavedIps = () => {
  const formData = new FormData();

  return axios.post(`${config.url}/webmaster/ip-saved`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

ipResolverApi.fetchRecentIps = () => {
  const formData = new FormData();

  return axios.post(`${config.url}/webmaster/ip-recent`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

export default ipResolverApi;
