import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../reducers/loginReducer";
import profileReducer from "../reducers/profileReducer";
import taskReducer from "../reducers/taskReducer";
import bugReducer from "../reducers/bugReducer";
import uptimeReducer from "../reducers/uptimeReducer";
import teamReducer from "../reducers/teamReducer";
import ipResolveReducer from "../reducers/ipResolveReducer";
import calenderReducer from "../reducers/calenderReducer";
const store = configureStore({
  reducer: {
    loginReducer,
    profileReducer,
    taskReducer,
    bugReducer,
    uptimeReducer,
    teamReducer,
    ipResolveReducer,
    calenderReducer,
  },
});

export default store;
