import React, { useState, useEffect, Fragment, useContext } from "react";
import Styles from "./UptimeMonitor.module.css";
import successAlertImage from "../../../../assets/images/icons/uptimeMonitorInfoSuccessImage.svg";
import dangerAlertImage from "../../../../assets/images/icons/uptimeMonitorInfoAlertImage.svg";
import uptrend from "../../../../assets/images/icons/uptrendIcon.svg";
import downtrend from "../../../../assets/images/icons/downtrendIcon.svg";
import DataTable from "../../../common/DataTable/DataTable";
import DropdownMenu from "../../../common/DropdownMenu/DropdownMenu";
import sortIcon from "../../../../assets/images/icons/sortIcon.svg";
import downArrow from "../../../../assets/images/icons/dropdown_arrow_1.svg";
import downArrow_1 from "../../../../assets/images/icons/downArrow_1.svg";
import uptrendIcon from "../../../../assets/images/icons/uptrendIcon_2.svg";
import downTrendIcon from "../../../../assets/images/icons/downtrendIcon_2.svg";
import FormButton from "../../../Elements/FormButton";
import noDataGraphUi from "../../../../assets/images/icons/uptimeNoDataImage.svg";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
} from "recharts";
import { useSelector, useDispatch } from "react-redux";
import {
  getDowntimeList,
  getDowntime,
  getDowntimeListGraph,
  downtimeSave,
} from "../../../../reducers/uptimeReducer";
import LoadingElement from "../../../common/LoadingElement/LoadingElement";
import { AlertContext } from "../../Layout";
function UptimeMonitor(props) {
  let dispatch = useDispatch();
  let alertContext = useContext(AlertContext);
  let [uptimeDataCategory, changeUptimeDataCategory] = useState({
    status: false,
    selected: 1,
  });
  let [searchDomain, changeSearchDomain] = useState("");
  let [showResults, changeShowResult] = useState(false);
  let [durationDropdown, changeDurationDropdown] = useState({
    status: false,
    selected: 1,
  });

  const dummyGraphData = [
    {
      pingTime: "0.3",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.4",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.5",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },

    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-01 09:15:02",
    },
    {
      pingTime: "0.7",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-02 09:15:02",
    },
    {
      pingTime: "0.4",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "1",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.1",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.5",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.1",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.6",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
    {
      pingTime: "0.2",
      pingResult: "1",
      statusCode: "200",
      reqTime: "92",
      date: "2023-06-07 09:15:02",
    },
  ];

  let downtimeList = useSelector((state) => state.uptimeReducer.downtimeList);
  let downtimeListLoading = useSelector(
    (state) => state.uptimeReducer.downtimeListLoading
  );

  let downtimeCheckResult = useSelector(
    (state) => state.uptimeReducer.downtime
  );
  let downtimeResultLoading = useSelector(
    (state) => state.uptimeReducer.downtimeLoading
  );

  let downtimeGraph = useSelector((state) => state.uptimeReducer.downtimeGraph);
  let downtimeGraphLoading = useSelector(
    (state) => state.uptimeReducer.downtimeGraphLoading
  );

  let downtimeSaveResult = useSelector(
    (state) => state.uptimeReducer.downtimeSave
  );
  let downtimeSaveLoading = useSelector(
    (state) => state.uptimeReducer.downtimeSaveLoading
  );

  let downtimeError = useSelector((state) => state.uptimeReducer.downtimeError);

  useEffect(() => {
    dispatch(getDowntimeList(null));
  }, []);

  useEffect(() => {
    if (downtimeCheckResult?.domain_id) {
      dispatch(getDowntimeListGraph({ id: downtimeCheckResult.domain_id }));
    } else {
      if (downtimeCheckResult) {
        changeShowResult(true);
      } else {
        changeShowResult(false);
      }
    }
  }, [downtimeCheckResult]);

  useEffect(() => {
    if (downtimeResultLoading) changeShowResult(true);
  }, [downtimeResultLoading]);

  useEffect(() => {
    if (downtimeSaveResult?.domain) {
      if (alertContext?.showAlert) {
        alertContext.showAlert("Domain successfully saved", "success");
      }
      dispatch(getDowntime(downtimeSaveResult.domain));
    }
  }, [downtimeSaveResult]);

  useEffect(() => {
    if (downtimeError) {
     
      alertContext?.showAlert("Something went wrong.Try Again.", "danger");
    }
  }, [downtimeError]);

  function closeUptimeDataCategoryDropdown() {
    changeUptimeDataCategory({
      status: false,
      selected: uptimeDataCategory.selected,
    });
  }
  function closeDurationDropdown() {
    changeDurationDropdown({
      status: false,
      selected: durationDropdown.selected,
    });
  }

  let uptimeDataCategories = [
    {
      id: 1,
      title: <p className={Styles.uptimeDataCategoryDropdownItem}>All Sites</p>,
    },
    {
      id: 2,
      title: (
        <p className={Styles.uptimeDataCategoryDropdownItem}>
          All <span style={{ color: "#018A5B" }}>Offline</span>
        </p>
      ),
    },
    {
      id: 3,
      title: (
        <p className={Styles.uptimeDataCategoryDropdownItem}>
          All <span style={{ color: "#FF1E1E" }}>Offline</span>
        </p>
      ),
    },
  ];

  function fetchTitleForUptimeCategory(id) {
    let title = null;
    uptimeDataCategories.forEach((catOb) => {
      if (id == catOb.id) {
        title = catOb.title;
      }
    });
    return title;
  }

  let graphStyles = {
    green: "rgba(44, 174, 130, 1)",
    red: "rgba(249, 89, 131, 1)",
  };

  let dummyData = [
    { name: "Mon", new: 50, completed: 0, priority: 0 },
    { name: "Tue", new: 1, completed: 10, priority: 0 },
    { name: "Wed", new: 5, completed: 0, priority: 0 },
    { name: "Thu", new: 20, completed: 0, priority: 0 },
    { name: "Fri", new: 3, completed: 1, priority: 11 },
    { name: "Sat", new: 5, completed: 1, priority: 0 },
    { name: "Sun", new: 1, completed: 0, priority: 0 },
  ];

  function getTableRowData() {
    let dataArr = [];
    downtimeList &&
      downtimeList.forEach((downtimeInfoOb) => {
        let dataToPush = null;
        if (false) {
          dataToPush = [
            <div
              style={{
                width: "2.5em",
                height: "2.5em",
                borderRadius: "20em",
                background: "var(--lightGrey)",
              }}
            ></div>,
            <div
              style={{
                width: "4.75em",
                height: "0.625em",
                borderRadius: "0.5em",
                background: "var(--lightGrey)",
              }}
            ></div>,
            <div
              style={{
                width: "5.0625em",
                height: "0.625em",
                borderRadius: "0.5em",
                background: "var(--lightGrey)",
              }}
            ></div>,
            <div
              style={{
                width: "5.5em",
                height: "0.5em",
                borderRadius: "10em",
                background: "var(--lightGrey)",
              }}
            ></div>,
            <div
              style={{
                width: "30.375em",
                height: "0.5em",
                borderRadius: "0.5em",
                background: "var(--lightGrey)",
              }}
            ></div>,
          ];
        } else {
          dataToPush = [
            <img
              src={
                downtimeInfoOb?.monitor?.status == "online"
                  ? uptrendIcon
                  : downTrendIcon
              }
              alt="trend"
              className={Styles.trendIcon}
            />,
            <div className={Styles.tableTextWrapper}>
              <p>Devza</p>
              <p>www.devza.com</p>
            </div>,
            <div className={Styles.tableTextWrapper}>
              <p>100%</p>
              <p>0 min dowtime</p>
            </div>,
            <div className={Styles.tableTextWrapper}>
              <p>232ms</p>
              <p>avg respose</p>
            </div>,
            <div className={Styles.graphContainer}>
              <ResponsiveContainer>
                <AreaChart
                  data={dummyData}
                  margin={{ top: 0, right: 0, left: -20, bottom: 20 }}
                >
                  <defs>
                    <linearGradient id="newData" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="2%"
                        stopColor={graphStyles.green}
                        stopOpacity={0.2}
                      />
                      <stop
                        offset="50%"
                        stopColor={graphStyles.green}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey="name"
                    axisLine={false}
                    hide={true}
                    style={{
                      fontFamily: "inherit",
                      fill: "var(--newGrey)",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                    padding={{ left: 10 }}
                    tickMargin={20}
                  />
                  <YAxis
                    hide={true}
                    tickSize={5}
                    tickCount={3}
                    minTickGap={5}
                    axisLine={false}
                    tickMargin={20}
                    style={{
                      fontFamily: "inherit",
                      fill: "var(--newGrey)",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  />

                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="new"
                    stroke={graphStyles.green}
                    fillOpacity={0}
                    fill="url(#newData)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>,
          ];
        }
        dataArr.push({ valOb: downtimeInfoOb, rowData: dataToPush });
      });
    return dataArr;
  }

  let durationArr = [
    {
      id: 1,
      title: "1 Week",
    },
    {
      id: 2,
      title: "1 Month",
    },
    {
      id: 3,
      title: "1 Months",
    },
    {
      id: 4,
      title: "1 Year",
    },
  ];

  function getTicksArray(graphData) {
    let highestNumber = 0;

    graphData.forEach((dataOb) => {
      if (parseInt(highestNumber) < parseInt(dataOb.pingTime)) {
        highestNumber = dataOb.pingTime;
      }
    });
    highestNumber = parseInt(highestNumber);
    return [0, highestNumber, highestNumber + 1];
  }

  function fetchDomainDetails(domain) {
    dispatch(getDowntime(searchDomain));
  }

  function resultLoadingUI() {
    return (
      <Fragment>
        <div className={Styles.resultInfoRow}>
          <div
            style={{
              width: "4.5em",
              height: "1.5em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
        </div>
        <div
          className={Styles.resultInfoRow}
          style={{ marginTop: "3.75em", marginBottom: "2.5em" }}
        >
          <div
            style={{
              width: "3.5em",
              height: "1em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
          <div
            style={{
              width: "3.5em",
              height: "1em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>

          <div
            style={{
              width: "3.5em",
              height: "1em",
              borderRadius: "20em",
              background: "var(--lightGrey)",
            }}
          ></div>
        </div>
      </Fragment>
    );
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.searchBox}>
        <input
          type="text"
          placeholder="Enter Website address"
          defaultValue={searchDomain}
          onChange={(e) => {
            changeSearchDomain(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key == "Enter") {
              fetchDomainDetails(searchDomain);
            }
          }}
        />
        <button
          onClick={() => {
            fetchDomainDetails(searchDomain);
          }}
        >
          check uptime
        </button>
      </div>
      <div className={Styles.header}>
        {showResults ? (
          <div className={Styles.resultWrapper}>
            <div className={Styles.graphSection}>
              {downtimeGraphLoading || downtimeResultLoading ? (
                <LoadingElement styles={{ width: "100%" }} />
              ) : (
                ""
              )}
              {downtimeCheckResult && !downtimeCheckResult.domain_id ? (
                <div className={Styles.noDataUiWrapper}>
                  <img src={noDataGraphUi} alt="no data" />
                  <p>No Data available</p>
                </div>
              ) : (
                ""
              )}

              <ResponsiveContainer>
                <AreaChart
                  data={
                    downtimeCheckResult?.domain_id
                      ? downtimeGraph?.results
                      : dummyGraphData
                  }
                  margin={{ top: 0, right: 0, left: -20, bottom: 20 }}
                >
                  <defs>
                    <linearGradient id="uptimeData" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="0%"
                        stopColor={"#725AFF"}
                        stopOpacity={0.7}
                      />
                      <stop
                        offset="90%"
                        stopColor={"#725AFF"}
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis
                    hide={true}
                    dataKey="date"
                    axisLine={false}
                    style={{
                      fontFamily: "inherit",
                      fill: "var(--newGrey)",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                    padding={{ left: 10 }}
                    tickMargin={20}
                  />
                  <YAxis
                    hide={true}
                    tickSize={3}
                    minTickGap={0}
                    axisLine={false}
                    tickMargin={0}
                    ticks={getTicksArray(dummyGraphData)}
                    style={{
                      fontFamily: "inherit",
                      fill: "var(--newGrey)",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  />

                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="pingTime"
                    stroke={"#00000"}
                    fillOpacity={1}
                    fill="url(#uptimeData)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className={Styles.resultInfo}>
              {downtimeResultLoading ? (
                resultLoadingUI()
              ) : (
                <Fragment>
                  {" "}
                  <div className={Styles.resultInfoRow}>
                    <div className={Styles.resultDomain}>
                      {/* <p>devza</p> */}
                      {/* TODO: use domain name returned by api after its fixed */}
                      <p>{searchDomain}</p>
                    </div>
                    {downtimeCheckResult?.domain_id ? (
                      ""
                    ) : (
                      <FormButton
                        handler={() => {
                          if (searchDomain) {
                            dispatch(downtimeSave(searchDomain));
                          }
                        }}
                        type="style1"
                        styles={{
                          width: "fit-content",
                          padding: "0.56em",
                          borderRadius: "0.625em",
                          marginLeft: "auto",
                        }}
                      >
                        Save
                      </FormButton>
                    )}

                    {/* <button className={Styles.saveButton}>Save</button> */}
                  </div>
                  <div
                    className={Styles.resultInfoRow}
                    style={{ marginTop: "3.75em", marginBottom: "2.5em" }}
                  >
                    <div className={Styles.resultStatus}>
                      {downtimeCheckResult?.pingResult ? (
                        <p style={{ color: "var(--green)" }}>online</p>
                      ) : (
                        <p style={{ color: "var(--errorRed)" }}>offline</p>
                      )}

                      <p>status</p>
                    </div>
                    {downtimeGraph?.uptime &&
                    downtimeGraph.domain == searchDomain ? (
                      <div className={Styles.resultDowntime}>
                        <p>{downtimeGraph.uptime}%</p>
                        <p>uptime</p>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className={Styles.resultResponse}>
                      {downtimeCheckResult?.reqTime ? (
                        <p>{downtimeCheckResult.reqTime}ms</p>
                      ) : (
                        ""
                      )}

                      <p>avg response</p>
                    </div>
                  </div>
                  {/* <div className={Styles.resultInfoRow}>
              <div className={Styles.resultDetails}>
                <p>Domain was down on sunday,21th Aug 2021</p>
                <div className={Styles.resultDetailsBox}>
                  <div className={Styles.resultDetailRow}>
                    <p>• 11:13:21 - 13:23:34</p>
                    <p>2hour 13min 12s</p>
                  </div>
                  <div className={Styles.resultDetailRow}>
                    <p>• 11:13:21 - 13:23:34</p>
                    <p>2hour 13min 12s</p>
                  </div>
                  <div className={Styles.resultDetailRow}>
                    <p>• 11:13:21 - 13:23:34</p>
                    <p>2hour 13min 12s</p>
                  </div>
                  <div className={Styles.resultDetailRow}>
                    <p>• 11:13:21 - 13:23:34</p>
                    <p>2hour 13min 12s</p>
                  </div>
                </div>
              </div>
            </div> */}
                </Fragment>
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className={Styles.infoBoxWrapper}>
          <div className={Styles.infoBox}>
            <img src={successAlertImage} alt="success" />
            <div className={Styles.infoSection}>
              <p>Wohoo! All website(s) are Online</p>
              <p>As of Sep 24, 2021 12:34PM</p>
              <div className={Styles.infoSectionTrendBox}>
                <p>94.35%</p>
                <img src={uptrend} alt="uptrend" />
                <p>Uptime</p>
              </div>
            </div>
          </div>
          <div className={Styles.infoBox}>
            <img src={dangerAlertImage} alt="success" />
            <div className={Styles.infoSection}>
              <p>Wohoo! All website(s) are Online</p>
              <p>As of Sep 24, 2021 12:34PM</p>
              <div className={Styles.infoSectionTrendBox}>
                <p>94.35%</p>
                <img src={downtrend} alt="downtrend" />
                <p>Uptime</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.body} id="uptimeBody">
        <div className={Styles.filterWrapper}>
          <div className={Styles.uptimeDataCategoryWrapper} id="uptimeDataType">
            {fetchTitleForUptimeCategory(uptimeDataCategory.selected)}
            <p>10</p>
            <img
              src={downArrow}
              alt="dropdown"
              onClick={() => {
                changeUptimeDataCategory({
                  status: true,
                  selected: uptimeDataCategory.selected,
                });
              }}
            />
            <DropdownMenu
              isActive={uptimeDataCategory.status}
              updateDropdownState={closeUptimeDataCategoryDropdown}
              id="uptimeDataType"
              styles={{ top: "2.5em" }}
            >
              {uptimeDataCategories.map((dataOb, key) => {
                if (uptimeDataCategory.selected != dataOb.id) {
                  return (
                    <div
                      onClick={() => {
                        changeUptimeDataCategory({
                          selected: dataOb.id,
                          status: true,
                        });
                      }}
                    >
                      {dataOb.title}
                    </div>
                  );
                }
              })}
            </DropdownMenu>
          </div>
          <div
            className={Styles.otherFiltersWrapper}
            id="uptime_durationWrapper"
          >
            <div className={Styles.filterBox}>
              <img src={sortIcon} alt="sort" className={Styles.sortIcon} />
            </div>
            <div
              className={Styles.filterBox}
              onClick={() => {
                changeDurationDropdown({
                  status: true,
                  selected: durationDropdown.selected,
                });
              }}
            >
              <p>24 hrs</p>
              <img src={downArrow_1} alt="arrow" />
            </div>
            <DropdownMenu
              isActive={durationDropdown.status}
              updateDropdownState={closeDurationDropdown}
              id="uptime_durationWrapper"
              styles={{ top: "2.5em", minWidth: "10em" }}
            >
              {durationArr &&
                durationArr.map((val, id) => {
                  return (
                    <p className={Styles.durationDropdownItem} key={id}>
                      {val.title}
                    </p>
                  );
                })}
            </DropdownMenu>
          </div>
        </div>
        <div className={Styles.dataTableWrapper}>
          <DataTable
            rowData={getTableRowData()}
            // loadingData={
            //   props.type == "task" ? taskListLoading : bugListLoading
            // }
            // showInfoModalHandler={showInfoModalHandler}
            type={"uptime"}
            parentId={"uptimeBody"}
          />
        </div>
      </div>
    </div>
  );
}

export default UptimeMonitor;
