import React from "react";
import Styles from "./SearchBar.module.css";
import searchIcon from "../../../../assets/images/icons/searchIcon.svg";
function SearchBar(props) {
  return (
    <div className={Styles.container}>
      <img src={searchIcon} alt="" className={Styles.searchIcon} />
      <input
        type="text"
        placeholder="Search tasks,bugs,..."
        className={Styles.searchTextBox}
      />
    </div>
  );
}

export default SearchBar;
