import React, { useState, useEffect, useContext } from "react";
import Styles from "../Settings.module.css";
import FormButton from "../../../Elements/FormButton";
import ToggleButton from "../../../Elements/ToggleButton";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../../../reducers/profileReducer";
import { AlertContext } from "../../Layout";
function Notification(props) {
  let dispatch = useDispatch();
  let alert = useContext(AlertContext);
  let [toggleState, changeToggle] = useState({
    task_updates: 0,
    bug_updates: 0,
    webmaster_updates: 0,
    activity_updates: 0,
    email_daily_reports: 0,
    email_weekly_reports: 0,
  });

  useEffect(() => {
    dispatch(getNotificationSettings());
  }, []);

  let notificationSetting = useSelector(
    (state) => state.profileReducer.notificationSetting
  );
  let updateNotificationResult = useSelector(
    (state) => state.profileReducer.updateNotification
  );

  useEffect(() => {
    if (updateNotificationResult) {
      alert?.showAlert("Notification settings successully updated", "success");
    }
  }, [updateNotificationResult]);

  useEffect(() => {
    if (notificationSetting) {
      changeToggle({
        task_updates: notificationSetting?.settings?.task_updates ?? 0,
        bug_updates: notificationSetting?.settings?.bug_updates ?? 0,
        webmaster_updates:
          notificationSetting?.settings?.webmaster_updates ?? 0,
        activity_updates: notificationSetting?.settings?.activity_updates ?? 0,
        email_daily_reports:
          notificationSetting?.settings?.email_daily_reports ?? 0,
        email_weekly_reports:
          notificationSetting?.settings?.email_weekly_reports ?? 0,
      });
    }
  }, [notificationSetting]);

  let notifications = {
    browserNotifications: [
      {
        title: "task updates",
        info: "You’ll be notified for new task, new comment or any modification",
        value: "task_updates",
      },
      {
        title: "bug updates",
        info: "You’ll be notified for new bug, new comment or any modification",
        value: "bug_updates",
      },
      {
        title: "Webmaster Tools Updates",
        info: "You’ll be notified for Alerts",
        value: "webmaster_updates",
      },
      {
        title: "Activity Updates",
        info: "You’ll be notified for Calendar Alerts, @mentions",
        value: "activity_updates",
      },
    ],
    emailNotifications: [
      {
        title: "daily reports",
        info: "All day report of new task/bug, new comment, deadlines or any modification  ",
        value: "email_daily_reports",
      },
      {
        title: "Weekly Insights",
        info: "Track your performance and dive deep into weekly insights of your task/bug  ",
        value: "email_weekly_reports",
      },
    ],
  };

  function changeToggleState(value) {
    let temp = { ...toggleState };
    temp[value] = toggleState[value] == 0 ? 1 : 0;
    changeToggle(temp);
  }

  function handleUpdate() {
    let data = [];
    data = Object.keys(toggleState).map((key) => {
      return {
        key,
        value: toggleState[key] == 0 ? "off" : "on",
      };
    });

    dispatch(updateNotificationSettings(data));
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.notificationHeader}>
        <p className={Styles.headingText}>Browser Notifications</p>
        <FormButton
          handler={() => handleUpdate()}
          type="style1"
          styles={{ padding: "0.875em 0.9375em", width: "fit-content" }}
        >
          Save changes
        </FormButton>
      </div>
      <div className={Styles.notificationBody}>
        {notifications.browserNotifications.map((notificationOb, index) => {
          return (
            <div className={Styles.toggleButtonBox}>
              <div
                style={{ cursor: "pointer", width: "fit-content" }}
                onClick={() => {
                  changeToggleState(notificationOb.value);
                }}
              >
                <ToggleButton isActive={toggleState[notificationOb.value]} />
              </div>

              <div className={Styles.toggleButtonText}>
                <p>{notificationOb.title}</p>
                <p>{notificationOb.info}</p>
              </div>
            </div>
          );
        })}
        <p
          className={Styles.headingText}
          style={{ marginTop: "5em", marginBottom: "2em" }}
        >
          Email Notifications
        </p>
        {notifications.emailNotifications.map((notificationOb, index) => {
          return (
            <div className={Styles.toggleButtonBox}>
              <div
                style={{ cursor: "pointer", width: "fit-content" }}
                onClick={() => {
                  changeToggleState(notificationOb.value);
                }}
              >
                <ToggleButton isActive={toggleState[notificationOb.value]} />
              </div>

              <div className={Styles.toggleButtonText}>
                <p>{notificationOb.title}</p>
                <p>{notificationOb.info}</p>
              </div>
            </div>
          );
        })}
        <p
          className={Styles.headingText}
          style={{ marginTop: "5em", marginBottom: "2em" }}
        >
          Workspace Notifications
        </p>
        {notificationSetting?.workspace_settings.map((teamOb, index) => {
          return (
            <div className={Styles.toggleButtonBox}>
              <div
                style={{ cursor: "pointer", width: "fit-content" }}
                onClick={() => {
                  //   changeTwoFaToggle(!twoFaToggle);
                }}
              >
                <ToggleButton isActive={true} />
              </div>

              <div className={Styles.toggleButtonText}>
                <p>{teamOb.team_name}</p>
                <p>{teamOb.team_domain}.slack.com</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Notification;
