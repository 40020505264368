import React, { useState, useEffect } from "react";
import Styles from "./Notificaiton.module.css";
import DropdownMenu from "../../../common/DropdownMenu/DropdownMenu";
import headerBellIcon from "../../../../assets/images/icons/headerBellIcon.svg";
import notificationBellIcon from "../../../../assets/images/icons/notification_bell_icon.svg";
import newTaskEventIcon from "../../../../assets/images/icons/newTaskEventIcon.svg";
import completedTaskIcon from "../../../../assets/images/icons/completedIcon.svg";
import newCommentIcon from "../../../../assets/images/icons/newCommentIcon.svg";
import newAttachmentIcon from "../../../../assets/images/icons/newAttachmentIcon.svg";
import newDescriptionIcon from "../../../../assets/images/icons/newDescriptionIcon.svg";
import dueDateIcon from "../../../../assets/images/icons/dueDateIcon.svg";
import categoryIcon_notification from "../../../../assets/images/icons/categoryIcon_notification.svg";
import priorityIcon_notification from "../../../../assets/images/icons/priorityIcon_notification.svg";
import userIconNotification from "../../../../assets/images/icons/userIconNotification.svg";
import newBugEventIcon from "../../../../assets/images/icons/newBugEventIcon.svg";
import resolvedBugIcon from "../../../../assets/images/icons/resolvedBugIcon.svg";
import botzaIcon from "../../../../assets/images/logos/botza_logo_1.svg";
import clock from "../../../../assets/images/icons/clock.svg";
import { useSelector, useDispatch } from "react-redux";
import { getNotifications } from "../../../../reducers/profileReducer";
import LoadingElement2 from "../../../common/LoadinElement2/LoadingElement2";
function Notification(props) {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  let [isDropdownVisible, updateDropdownState] = useState(false);

  let notifications = useSelector(
    (state) => state.profileReducer.getNotification
  );

  let notificationsError = useSelector(
    (state) => state.profileReducer.getNotificationsError
  );

  let notificaitonsLoading = useSelector(
    (state) => state.profileReducer.getNotificationsLoading
  );

  useEffect(() => {
    if (notificationsError) {
      props.showAlert(
        "Something went wrong while fetching notifications, try reloading",
        "danger"
      );
    }
  }, [notificationsError]);

  function getTypeConfig(type) {
    switch (type) {
      //Task
      case "new_task":
        return {
          color: "var(--green)",
          icon: <img src={newTaskEventIcon} alt="notificationType" />,
          heading: "New task",
        };
      case "completed_task":
        return {
          color: "var(--blue)",
          icon: <img src={completedTaskIcon} alt="notificationType" />,
          heading: "Task Completed",
        };
      case "newComment_task":
        return {
          color: "var(--orange)",
          icon: <img src={newCommentIcon} alt="notificationType" />,
          heading: "New Comment",
        };
      case "newAttachment_task":
        return {
          color: "var(--orange)",
          icon: <img src={newAttachmentIcon} alt="notificationType" />,
          heading: "New Attachment",
        };
      case "descriptionUpdate_task":
        return {
          color: "var(--orange)",
          icon: <img src={newDescriptionIcon} alt="notificationType" />,
          heading: "Description Updated",
        };
      case "categoryUpdate_task":
        return {
          color: "var(--orange)",
          icon: <img src={categoryIcon_notification} alt="notificationType" />,
          heading: "Category Updated",
        };
      case "dueDateUpdate_task":
        return {
          color: "var(--orange)",
          icon: <img src={dueDateIcon} alt="notificationType" />,
          heading: "Due Date Updated",
        };
      case "priorityUpdate_task":
        return {
          color: "var(--orange)",
          icon: <img src={priorityIcon_notification} alt="notificationType" />,
          heading: "Priority Updated",
        };
      case "newAssigneeAdded_task":
        return {
          color: "var(--orange)",
          icon: <img src={userIconNotification} alt="notificationType" />,
          heading: "New Assignee added",
        };

      //Bug
      case "new_bug":
        return {
          color: "var(--blue)",
          icon: <img src={newBugEventIcon} alt="notificationType" />,
          heading: "New Bug",
        };
      case "resolved_bug":
        return {
          color: "var(--darkBlue)",
          icon: <img src={resolvedBugIcon} alt="notificationType" />,
          heading: "Bug Resolved",
        };
      case "newComment_bug":
        return {
          color: "var(--lightGreen)",
          icon: (
            <img
              src={newCommentIcon}
              alt="notificationType"
              style={{
                filter:
                  "invert(36%) sepia(42%) saturate(411%) hue-rotate(48deg) brightness(95%) contrast(90%)",
              }}
            />
          ),
          heading: "New Comment",
        };
      case "newAttachment_bug":
        return {
          color: "var(--lightGreen)",
          icon: (
            <img
              src={newAttachmentIcon}
              alt="notificationType"
              style={{
                filter:
                  "invert(36%) sepia(42%) saturate(411%) hue-rotate(48deg) brightness(95%) contrast(90%)",
              }}
            />
          ),
          heading: "New Attachment",
        };
      case "descriptionUpdate_bug":
        return {
          color: "var(--lightGreen)",
          icon: (
            <img
              src={newDescriptionIcon}
              alt="notificationType"
              style={{
                filter:
                  "invert(36%) sepia(42%) saturate(411%) hue-rotate(48deg) brightness(95%) contrast(90%)",
              }}
            />
          ),
          heading: "Description Updated",
        };

      case "priorityUpdate_bug":
        return {
          color: "var(--lightGreen)",
          icon: (
            <img
              src={priorityIcon_notification}
              alt="notificationType"
              style={{
                filter:
                  "invert(36%) sepia(42%) saturate(411%) hue-rotate(48deg) brightness(95%) contrast(90%)",
              }}
            />
          ),
          heading: "Severity Updated",
        };
      case "newAssigneeAdded_bug":
        return {
          color: "var(--lightGreen)",
          icon: (
            <img
              src={userIconNotification}
              alt="notificationType"
              style={{
                filter:
                  "invert(36%) sepia(42%) saturate(411%) hue-rotate(48deg) brightness(95%) contrast(90%)",
              }}
            />
          ),
          heading: "New Assignee added",
        };

      //System
      case "system":
        return {
          color: "var(--purple)",
          icon: <img src={botzaIcon} alt="notificationType" />,
          heading: `Hello ${props?.profileData?.name}`,
          type: "system",
        };

      default:
        return {};
    }
  }
  function getNotificationUI(typeConfig, data) {
    return (
      <div className={Styles.notificationBox} key={data.id}>
        {typeConfig.icon}
        <div>
          <div className={Styles.notificationBoxHeader}>
            <p style={{ color: typeConfig.color }}>{typeConfig.heading}</p>
            {typeConfig?.type != "system" ? <p>Dushyant singh</p> : ""}
          </div>
          <div className={Styles.notificationBoxBody}>
            <p>{data?.text}</p>
          </div>
        </div>
        <div className={Styles.notificationTime}>
          <img src={clock} alt="time" />
          <p>{data?.created_at}</p>
        </div>
      </div>
    );
  }

  function loadingUI() {
    return (
      <div className={Styles.notificationBox}>
        <div>
          <div className={Styles.notificationBoxHeader}>
            <LoadingElement2 styles={{ marginBottom: "1em", width: "8em" }} />
          </div>
          <div className={Styles.notificationBoxBody}>
            <LoadingElement2 styles={{ width: "10em" }} />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={Styles.container}>
      <img
        src={headerBellIcon}
        alt="icon"
        className={props.headerStyles.headerIcon}
        onClick={() => {
          updateDropdownState(true);
        }}
      />
      <DropdownMenu
        isActive={isDropdownVisible}
        updateDropdownState={updateDropdownState}
        id="notificationDropdown"
        styles={{
          right: 0,
        }}
      >
        <div className={Styles.notificationContainer}>
          <div className={Styles.notificationHeaderMain}>
            <div className={Styles.notificationHeadingMain}>
              <img src={notificationBellIcon} alt="noticiaionBell" />
              <p>notifications</p>
              <div className={Styles.notificaionCountBox}>
                <p>{notifications ? notifications.length : 0}</p>
              </div>
            </div>
            <p>Clear all</p>
          </div>
          <div className={Styles.notificationBody}>
            {notifications && notifications.length > 0
              ? notifications.map((data, index) => {
                  return getNotificationUI(
                    getTypeConfig(data?.type.toLowerCase()),
                    data
                  );
                })
              : notificaitonsLoading
              ? [1, 2].map(() => {
                  return loadingUI();
                })
              : "No notificatio"}
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
}

export default Notification;
