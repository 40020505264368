import React, { Fragment, useEffect, useState } from "react";
import Styles from "./LoginForm.module.css";
import back_arrow from "../../../assets/images/icons/back_arrow_1.svg";
import ErrorContainer from "../../common/ErrorContainer";
import FormButton from "../../Elements/FormButton";
import utils from "../../../utils";
import ReactInputVerificationCode from "react-input-verification-code";
import loadingGif from "../../../assets/images/gifs/loading_gif_1.gif";
import { useSelector, useDispatch } from "react-redux";
import { confirmMagicPin } from "../../../reducers/loginReducer";
function LoginForm__Verification(props) {
  let codeLength = 4;

  let { errorState, changeErrorState, changeFormState, clearState, formState } =
    props.data;

  let dispatch = useDispatch();

  let [verificationCode, changeVerificationCode] = useState(null);

  let confirmMagicPinLoading = useSelector((state) => {
    return state.loginReducer.confirmMagicPinLoading;
  });

  let confirmMagicPinError = useSelector((state) => {
    return state.loginReducer.confirmMagicPinError;
  });

  useEffect(() => {
    if (confirmMagicPinError) {
      changeErrorState((oldState) => {
        let temp = { ...oldState };

        temp.error = confirmMagicPinError;
        return temp;
      });
    }
  }, [confirmMagicPinError]);

  useEffect(() => {
    function listenerEvent(e) {
      if (e.key == "Enter") {
        submitData();
      }
    }
    document.addEventListener("keypress", listenerEvent);

    return () => {
      document.removeEventListener("keypress", listenerEvent);
    };
  }, []);

  function verificationCodeHandler(code) {
    if (errorState.error) {
      clearState();
    }
    let codeArr = code.split("");
    if (codeArr.length == codeLength) {
      changeVerificationCode(codeArr.join(""));
    } else {
      changeVerificationCode(null);
    }
  }

  function submitData() {
    let userInput = verificationCode;

    if (userInput && !utils.isNull(userInput)) {
      dispatch(
        confirmMagicPin({
          confirmCode: formState.data.confirmCode,
          pin: userInput,
        })
      );
    } else {
      changeErrorState((oldState) => {
        let temp = { ...oldState };
        temp.error = "invalid code";
        return temp;
      });
    }
  }
  return (
    <Fragment>
      <div className={Styles.loginForm__section}>
        <img
          src={back_arrow}
          className={Styles.backArrow}
          alt="back"
          onClick={() => {
            clearState();
            changeFormState((oldState) => {
              let temp = { ...oldState };
              temp.type = "password";
              return temp;
            });
          }}
        />
        <ErrorContainer state={errorState} type="style1" />
        <div className={Styles.loginForm__verificationBox}>
          <div className={Styles.verificationBox__text}>
            <p className={Styles.verificationBox__heading}>Verification</p>
            <p className={Styles.verificationBox__info}>
              Please enter the code we just sent you on{" "}
              <span className={Styles.verificationEmail}>
                {formState.data.email}
              </span>
            </p>
          </div>
          <div className={Styles.verificationBox__inputWrapper}>
            <div className="reactInputVerificationWrapper">
              <ReactInputVerificationCode
                autoFocus={true}
                length={codeLength}
                passwordMask={false}
                placeholder={false}
                onChange={verificationCodeHandler}
              />
            </div>

            <p className={Styles.verificationBox__resendText}>
              Didn't receive the code?{" "}
              <span className={Styles.resendButton}>resend</span>
            </p>
          </div>
        </div>
        <div className={Styles.buttonWrapper}>
          <FormButton
            type="style1"
            handler={() => {
              submitData();
            }}
          >
            Verify
          </FormButton>
          {confirmMagicPinLoading ? (
            <img
              src={loadingGif}
              className={Styles.loadingGif}
              alt="logginin"
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}

export default LoginForm__Verification;
