import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileApi from "../api/profileApi";

let initialState = {
  profileDataFetching: false,
  profileData: null,
  profileDataError: null,
  updateProfile: null,
  updateProfileLoading: false,
  notificationSetting: null,
  notificationSettingLoading: false,
  updateNotification: null,
  updateNotificationLoading: false,
  getNotification: null,
  getNotificationsLoading: false,
  getNotificationsError: null,
};

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (dataOb = null, { rejectWithValue }) => {
    const response = await profileApi.getProfile();

    if (response.data.status == "success") {
      return response.data.profile;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (data, { rejectWithValue }) => {
    const response = await profileApi.updateProfile(data);

    if (response.data.status == "success") {
      return response.data;
    } else {
   
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getNotificationSettings = createAsyncThunk(
  "profile/getNotificationSettings",
  async (data = {}, { rejectWithValue }) => {
    const response = await profileApi.getNotificationSettings();

    if (response.data.status == "success") {
      return response.data;
    } else {
   
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const updateNotificationSettings = createAsyncThunk(
  "profile/updateNotificationSettings",
  async (data, { rejectWithValue }) => {
    const response = await profileApi.updateNotificationSettings(data);

    if (response.data.status == "success") {
      return response.data;
    } else {
      
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getNotifications = createAsyncThunk(
  "profile/getNotifications",
  async (data, { rejectWithValue }) => {
    const response = await profileApi.getNotifications(data);

    if (response.data.status == "success") {
      return response.data.notifications;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState,

  extraReducers: (builder) => {
    //Login using email/pass
    builder.addCase(getProfile.pending, (state, action) => {
      state.profileDataFetching = true;
      state.profileData = null;
      state.profileDataError = null;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.profileDataFetching = false;
      state.profileData = null;
      if (action.error) {
        state.profileDataError = { error: action.error.message };
      } else {
        state.profileDataError = action.payload;
      }
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profileDataFetching = false;
      state.profileData = action.payload;
      state.profileDataError = null;
    });
    builder.addCase(updateProfile.pending, (state, action) => {
      state.updateProfileLoading = true;
      state.updateProfile = null;
      state.profileDataError = null;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updateProfileLoading = false;
      state.updateProfile = null;
      if (action.payload) {
        state.profileDataError = action.payload;
      } else {
        state.profileDataError = { error: action.error?.message };
      }
      // if (action.error) {
      //   state.profileDataError = { error: action.error.message };
      // } else {
      //   state.profileDataError = action.payload;
      // }
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updateProfileLoading = false;
      state.updateProfile = action.payload;
      state.profileDataError = null;
    });
    builder.addCase(getNotificationSettings.pending, (state, action) => {
      state.notificationSettingLoading = true;
      state.notificationSetting = null;
      state.profileDataError = null;
    });
    builder.addCase(getNotificationSettings.rejected, (state, action) => {
      state.notificationSettingLoading = false;
      state.notificationSetting = null;
      if (action.error) {
        state.profileDataError = { error: action.error.message };
      } else {
        state.profileDataError = action.payload;
      }
    });
    builder.addCase(getNotificationSettings.fulfilled, (state, action) => {
      state.notificationSettingLoading = false;
      state.notificationSetting = action.payload;
      state.profileDataError = null;
    });
    builder.addCase(updateNotificationSettings.pending, (state, action) => {
      state.updateNotificationLoading = true;
      state.updateNotification = null;
      state.profileDataError = null;
    });
    builder.addCase(updateNotificationSettings.rejected, (state, action) => {
      state.updateNotificationLoading = false;
      state.updateNotification = null;
      if (action.error) {
        state.profileDataError = { error: action.error.message };
      } else {
        state.profileDataError = action.payload;
      }
    });
    builder.addCase(updateNotificationSettings.fulfilled, (state, action) => {
      state.updateNotificationLoading = false;
      state.updateNotification = action.payload;
      state.profileDataError = null;
    });

    builder.addCase(getNotifications.pending, (state, action) => {
      state.getNotificationsLoading = true;
      state.getNotification = null;
      state.getNotificationsError = null;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.getNotificationsLoading = false;
      state.getNotification = null;
      if (action.error) {
        state.getNotificationsError = { error: action.error.message };
      } else {
        state.getNotificationsError = action.payload;
      }
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.getNotificationsLoading = false;
      state.getNotification = action.payload;
      state.getNotificationsError = null;
    });
  },
});

export const { profile } = profileSlice.actions;
export default profileSlice.reducer;
