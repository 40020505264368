import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let taskApi = {};

taskApi.getGraphData = (chartTime) => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("timezone", "UTC");
  formData.append("chartTime", chartTime);
  return axios.post(`${config.url}/tasks/graph`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.getStats = () => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("timezone", "UTC");
  return axios.post(`${config.url}/tasks/stats`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.getList = (data) => {
  const formData = new FormData();
  if (data) {
    data.forEach((ob) => {
      formData.append(ob.key, ob.value);
    });
  }

  formData.append("limit", 10);
  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/list`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.getCategories = () => {
  const formData = new FormData();

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/categories`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.getTags = () => {
  const formData = new FormData();

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/tags`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.addtask = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/create`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.editTask = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/update`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.pinTask = (task_id) => {
  const formData = new FormData();

  formData.append("task_id", task_id);

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/pin`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.detachTask = (task_id) => {
  const formData = new FormData();

  formData.append("task_id", task_id);

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/detach`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.deleteTask = (task_id) => {
  const formData = new FormData();

  formData.append("task_id", task_id);

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/tasks/delete`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.addComment = (task_id, commentText) => {
  const formData = new FormData();

  formData.append("task_id", task_id);

  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("comment_text", commentText);
  return axios.post(`${config.url}/tasks/add-comment`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.removeComment = (task_id, commentId) => {
  const formData = new FormData();

  formData.append("task_id", task_id);

  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("comment_id", commentId);
  return axios.post(`${config.url}/tasks/remove-comment`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

taskApi.addAttachment = (task_id, file) => {
  const formData = new FormData();

  formData.append("task_id", task_id);
  file.forEach((fileInfo) => {
    formData.append("file[]", fileInfo);
  });

  formData.append("team_id", utils.fetchVariable("current_team"));
  return axios.post(`${config.url}/tasks/attachment`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

export default taskApi;
