import React, { useState, useRef, useEffect, useContext } from "react";
import Styles from "./Settings.module.css";
import classnames from "classnames";
import Profile from "./Profile/Profile";
import Workspace from "./Workspace/Workspace";
import Integrations from "./Integrations/Integrations";
import Notification from "./Notifications/Notification";
function Settings(props) {
  const [activeNav, changeNav] = useState(0);
  let bodyRef = useRef(null);

  useEffect(() => {
    if (bodyRef?.current) {
      let top = bodyRef.current?.getBoundingClientRect()?.top;
      let heightOfViewPort = window.innerHeight;
      bodyRef.current.style.height = `${heightOfViewPort - top}px`;
    }
  }, [bodyRef]);

  let navItems = [
    {
      id: 0,
      title: "profile",
      component: <Profile />,
    },
    {
      id: 1,
      title: "workspace",
      component: <Workspace />,
    },
    {
      id: 2,
      title: "integrations",
      component: <Integrations />,
    },
    {
      id: 3,
      title: "notification",
      component: <Notification />,
    },
  ];

  function fetchComponent(id) {
    let component = null;
    navItems.forEach((val) => {
      if (val.id == id) {
        component = val.component;
      }
    });
    return component;
  }
  return (
    <div className={Styles.container}>
      <div className={Styles.nav}>
        {navItems &&
          navItems.map((val, id) => {
            return (
              <div
                onClick={() => {
                  changeNav(val.id);
                }}
                className={classnames(
                  Styles.navItemBox,
                  activeNav == val.id ? Styles.navItemBoxActive : ""
                )}
              >
                <p>{val.title}</p>
                <div className={Styles.highlightNav}></div>
              </div>
            );
          })}
      </div>
      <div className={Styles.mainBody} ref={bodyRef} id="settingsBody">
        {fetchComponent(activeNav)}
      </div>
    </div>
  );
}

export default Settings;
