import React, { useState, useEffect } from "react";
import Styles from "./LoginForm.module.css";
import LoginFormEmail from "./LoginForm__Email";
import LoginFormPassword from "./LoginForm__Password";
import LoginFormVerification from "./LoginForm__Verification";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import utils from "../../../utils";
function LoginForm(props) {
  let navigate = useNavigate();
  const [formState, changeFormState] = useState({
    type: "email",
    data: null,
  });

  const [errorState, changeErrorState] = useState({
    error: "",
  });

  let loginError = useSelector((state) => {
    return state.loginReducer.loginError;
  });
  const loginData = useSelector((state) => {
    return state.loginReducer.loginData;
  });
  const sendMagicPinData = useSelector((state) => {
    return state.loginReducer.sendMagicPinData;
  });

  useEffect(() => {
    if (sendMagicPinData && sendMagicPinData.confirm_code) {
      changeFormState((oldState) => {
        let temp = { ...oldState };
        temp.type = "verification";
        temp.data = {
          ...temp.data,
          confirmCode: sendMagicPinData.confirm_code,
        };

        return temp;
      });
    }
  }, [sendMagicPinData]);

  //Temporary redirect until login flow is cleard
  useEffect(() => {
    if (loginData && loginData.token) {
      let token = loginData.token;
      utils.setToken(token);
      navigate("/launch", { replace: "true" });
    }
  }, [loginData]);

  useEffect(() => {
    if (loginError) {
      changeErrorState((oldState) => {
        let temp = { ...oldState };

        temp.error = loginError;
        return temp;
      });
    }
  }, [loginError]);

  function clearState() {
    if (errorState.error) {
      changeErrorState((oldState) => {
        let temp = { ...oldState };
        temp.error = "";
        return temp;
      });
    }
  }

  function renderUi() {
    let propsToPass = {
      errorState,
      changeErrorState,
      formState,
      changeFormState,
      clearState,
    };
    switch (formState.type) {
      case "email":
        return <LoginFormEmail data={propsToPass} />;
        break;
      case "password":
        return <LoginFormPassword data={propsToPass} />;
        break;
      case "verification":
        return <LoginFormVerification data={propsToPass} />;
        break;
      default:
        return <LoginFormEmail data={propsToPass} />;
    }
  }
  return <div className={Styles.loginFormContainer}>{renderUi()}</div>;
}

export default LoginForm;
