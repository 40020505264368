import React, { useEffect } from "react";
import Styles from "./WelcomePage.module.css";
import FormButton from "../../Elements/FormButton";
import Drawing from "../../../assets/images/other_images/Drawing.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTeamList } from "../../../reducers/teamReducer";
function WelcomePage(props) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let teamList = useSelector((state) => state.teamReducer.teamList);

  useEffect(() => {
    dispatch(getTeamList());
  }, []);

  return (
    <div className={Styles.welcomeContainer}>
      <div className={Styles.welcomeContainer__section}>
        <div className={Styles.welcomeTextContainer}>
          <p className={Styles.welcomeHeading}>Hey!</p>
          <p className={Styles.welcomeInfoText1}>
            Welcome to <span className={Styles.specialText}>Botza!</span>
          </p>
          <p className={Styles.welcomeInfoText2}>
            Let’s setup your personal wand, that works like magic for all your
            work.
          </p>
        </div>
        <FormButton
          type="style1"
          handler={() => {
            if (teamList && teamList.length > 0) {
              navigate("/connect?teams=1");
            } else {
              navigate("/connect");
            }
          }}
        >
          Let's go
        </FormButton>
      </div>
      <img src={Drawing} alt="drawing" className={Styles.drawingImg} />
    </div>
  );
}

export default WelcomePage;
