import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import teamApi from "../api/teamApi";

let initialState = {
  teamErrors: null,
  getMembersLoading: false,
  getMembersData: null,
  getChannelLoading: false,
  getChannel: null,
  getTeam: null,
  getTeamLoading: false,
  getTeamError: null,
  connectTeam: null,
  connectTeamLoading: false,
  connectTeamError: null,
  teamListError: null,
  teamList: null,
  teamListLoading: false,
  createDefaultTeamLoading: false,
  createDefaultTeam: null,
  createTeamError: null,
  disconnectTeam: null,
  disconnectTeamLoading: false,
};

export const getMembers = createAsyncThunk(
  "team/getMembers",
  async (dataOb = null, { rejectWithValue }) => {
    const response = await teamApi.getMembers();

    if (response.data.status == "success") {
      return response.data.members;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getChannels = createAsyncThunk(
  "team/getChannel",
  async (dataOb = null, { rejectWithValue }) => {
    const response = await teamApi.getChannel();

    if (response.data.status == "success") {
      return response.data.channels;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTeamList = createAsyncThunk(
  "team/getTeamList",
  async (dataOb = null, { rejectWithValue }) => {
    const response = await teamApi.getTeamList();

    if (response.data.status == "success") {
      return response.data.teams;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getTeam = createAsyncThunk(
  "team/getTeam",
  async (dataOb = null, { rejectWithValue }) => {
    const response = await teamApi.getTeams();

    if (response.data.status == "success") {
      return response.data.teams;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const connectTeam = createAsyncThunk(
  "team/connectTeam",
  async (team_id, { rejectWithValue }) => {
    const response = await teamApi.connectTeam(team_id);

    if (response.data.status == "success") {
      return response.data.results;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const disconnectTeam = createAsyncThunk(
  "team/disconnectTeam",
  async (team_id, { rejectWithValue }) => {
    const response = await teamApi.disconnectTeam(team_id);

    if (response.data.status == "success") {
      return response.data.results;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const createDefaultTeam = createAsyncThunk(
  "team/createDefaultTeam",
  async (data = {}, { rejectWithValue }) => {
    const response = await teamApi.createDefaultTeam();

    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const teamSlice = createSlice({
  name: "team",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getMembers.pending, (state, action) => {
      state.getMembersLoading = true;
      state.getMembersData = null;
      state.teamErrors = null;
    });
    builder.addCase(getMembers.rejected, (state, action) => {
      state.getMembersLoading = false;
      state.getMembersData = null;

      if (action.payload) {
        state.teamErrors = action.payload;
      } else if (action.error) {
        state.teamErrors = { error: action.error.message };
      }
    });
    builder.addCase(getMembers.fulfilled, (state, action) => {
      state.getMembersLoading = false;
      state.getMembersData = action.payload;
      state.teamErrors = null;
    });

    //get channel
    builder.addCase(getChannels.pending, (state, action) => {
      state.getChannelLoading = true;
      state.getChannel = null;
      state.teamErrors = null;
    });
    builder.addCase(getChannels.rejected, (state, action) => {
      state.getChannelLoading = false;
      state.getChannel = null;

      if (action.payload) {
        state.teamErrors = action.payload;
      } else if (action.error) {
        state.teamErrors = { error: action.error.message };
      }
    });
    builder.addCase(getChannels.fulfilled, (state, action) => {
      state.getChannelLoading = false;
      state.getChannel = action.payload;
      state.teamErrors = null;
    });

    builder.addCase(getTeam.pending, (state, action) => {
      state.getTeamLoading = true;
      state.getTeam = null;
      state.getTeamError = null;
    });
    builder.addCase(getTeam.rejected, (state, action) => {
      state.getTeamLoading = false;
      state.getTeam = null;

      if (action.payload) {
        state.getTeamError = action.payload;
      } else if (action.error) {
        state.getTeamError = { error: action.error.message };
      }
    });
    builder.addCase(getTeam.fulfilled, (state, action) => {
      state.getTeamLoading = false;
      state.getTeam = action.payload;
      state.getTeamError = null;
    });

    builder.addCase(connectTeam.pending, (state, action) => {
      state.connectTeamLoading = true;
      state.connectTeam = null;
      state.connectTeamError = null;
    });
    builder.addCase(connectTeam.rejected, (state, action) => {
      state.connectTeamLoading = false;
      state.connectTeam = null;

      if (action.payload) {
        state.connectTeamError = action.payload;
      } else if (action.error) {
        state.connectTeamError = { error: action.error.message };
      }
    });
    builder.addCase(connectTeam.fulfilled, (state, action) => {
      state.connectTeamLoading = false;
      state.connectTeam = action.payload;
      state.connectTeamError = null;
    });

    builder.addCase(getTeamList.pending, (state, action) => {
      state.teamListLoading = true;
      state.teamList = null;
      state.teamListError = null;
    });
    builder.addCase(getTeamList.rejected, (state, action) => {
      state.teamListLoading = false;
      state.teamList = null;

      if (action.payload) {
        state.teamListError = action.payload;
      } else if (action.error) {
        state.teamListError = { error: action.error.message };
      }
    });
    builder.addCase(getTeamList.fulfilled, (state, action) => {
      state.teamListLoading = false;
      state.teamList = action.payload;
      state.teamListError = null;
    });

    builder.addCase(createDefaultTeam.pending, (state, action) => {
      state.createDefaultTeamLoading = true;
      state.createDefaultTeam = null;
      state.createTeamError = null;
    });
    builder.addCase(createDefaultTeam.rejected, (state, action) => {
      state.createDefaultTeamLoading = false;
      state.createDefaultTeam = null;

      if (action.payload) {
        state.createTeamError = action.payload;
      } else if (action.error) {
        state.createTeamError = { error: action.error.message };
      }
    });
    builder.addCase(createDefaultTeam.fulfilled, (state, action) => {
      state.createDefaultTeamLoading = false;
      state.createDefaultTeam = action.payload;
      state.createTeamError = null;
    });

    builder.addCase(disconnectTeam.pending, (state, action) => {
      state.disconnectTeamLoading = true;
      state.disconnectTeam = null;
      state.teamErrors = null;
    });
    builder.addCase(disconnectTeam.rejected, (state, action) => {
      state.disconnectTeamLoading = false;
      state.disconnectTeam = null;

      if (action.payload) {
        state.teamErrors = action.payload;
      } else if (action.error) {
        state.teamErrors = { error: action.error.message };
      }
    });
    builder.addCase(disconnectTeam.fulfilled, (state, action) => {
      state.disconnectTeamLoading = false;
      state.disconnectTeam = action.payload;
      state.teamErrors = null;
    });
  },
});

export const { team } = teamSlice.actions;
export default teamSlice.reducer;
