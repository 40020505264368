import React from "react";
import Styles from "./LoadingElement2.module.css";
function LoadingElement2(props) {
  return (
    <div className={Styles.container} style={{ ...props.styles }}>
      <div className={Styles.loadingElement}></div>
    </div>
  );
}

export default LoadingElement2;
