import React from "react";
import Styles from "./ToggleButton.module.css";
import classNames from "classnames";
function ToggleButton(props) {
  return (
    <div
      className={classNames(
        Styles.toggleButtonContainer,
        props.isActive ? Styles.toggleContainerActive : ""
      )}
    >
      <div
        className={classNames(
          Styles.toggleSwitch,
          props.isActive ? Styles.toggleSwitchActive : ""
        )}
      ></div>
    </div>
  );
}

export default ToggleButton;
