import { config } from "./config";
import axios from "axios";
import utils from "../utils";

let bugApi = {};

bugApi.getGraphData = (chartTime) => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("timezone", "UTC");
  formData.append("chartTime", chartTime);
  return axios.post(`${config.url}/bugs/graph`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.getStats = () => {
  const formData = new FormData();
  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("timezone", "UTC");
  return axios.post(`${config.url}/bugs/stats`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.getList = (data) => {
  const formData = new FormData();

  if (data) {
    data.forEach((ob) => {
      formData.append(ob.key, ob.value);
    });
  }
  formData.append("limit", 10);
  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/list`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.getCategories = () => {
  const formData = new FormData();

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/categories`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.getTags = () => {
  const formData = new FormData();

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/tags`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.addBug = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/create`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.editBug = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/update`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.pinBug = (bug_id) => {
  const formData = new FormData();

  formData.append("bug_id", bug_id);

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/pin`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.detachBug = (bug_id) => {
  const formData = new FormData();

  formData.append("bug_id", bug_id);

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/detach`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.deleteBug = (bug_id) => {
  const formData = new FormData();

  formData.append("bug_id", bug_id);

  formData.append("team_id", utils.fetchVariable("current_team"));

  return axios.post(`${config.url}/bugs/delete`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.addComment = (bug_id, commentText) => {
  const formData = new FormData();

  formData.append("bug_id", bug_id);

  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("comment_text", commentText);
  return axios.post(`${config.url}/bugs/add-comment`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.removeComment = (bug_id, commentId) => {
  const formData = new FormData();

  formData.append("bug_id", bug_id);

  formData.append("team_id", utils.fetchVariable("current_team"));
  formData.append("comment_id", commentId);
  return axios.post(`${config.url}/bugs/remove-comment`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

bugApi.addAttachment = (bug_id, file) => {
  const formData = new FormData();

  formData.append("bug_id", bug_id);
  file.forEach((fileInfo) => {
    formData.append("file[]", fileInfo);
  });

  formData.append("team_id", utils.fetchVariable("current_team"));
  return axios.post(`${config.url}/bugs/attachment`, formData, {
    headers: {
      BotzaToken: utils.fetchToken(),
    },
  });
};

export default bugApi;
