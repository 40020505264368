import React from "react";
import Styles from "../Settings.module.css";
import FormButton from "../../../Elements/FormButton";
import whatsappInactive from "../../../../assets/images/icons/whatsappInactive.svg";
import teamsInactive from "../../../../assets/images/icons/teamsInactive.svg";
import telegramInactive from "../../../../assets/images/icons/telegramInactive.svg";
import slackActive from "../../../../assets/images/icons/slackActive.svg";
function Integrations(props) {
  return (
    <div>
      <div className={Styles.integrationHeader}>
        <p>Available integrations</p>
        {/* <FormButton
          type="style1"
          styles={{ width: "fit-content", padding: "0.8em 1.5em" }}
        >
          Save changes
        </FormButton> */}
      </div>
      <div className={Styles.integrationsBody}>
        <div className={Styles.activeIntegrationBox}>
          <img src={slackActive} alt="slack" />
          <p>
            You have authorized Botza application to integrate with Slack
            workspaces.
          </p>
        </div>
        <p>Releasing soon</p>
        <div className={Styles.releasingSoonWrapper}>
          <img src={teamsInactive} alt="teams" />
          <img src={whatsappInactive} alt="whatsapp" />
          <img src={telegramInactive} alt="telegram" />
        </div>
      </div>
    </div>
  );
}

export default Integrations;
