import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calenderApi from "../api/calenderApi";

let initialState = {
  calenderError: null,
  calenderDataLoading: false,
  calenderData: null,
  knowledgeBaseData: null,
  knowledgeBaseLoading: false,
};

export const getCalenderData = createAsyncThunk(
  "calender/getCalenderData",
  async (date, { rejectWithValue }) => {
    const response = await calenderApi.getCalenderData(date);
    if (response.data.status == "success") {
      return response.data;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

export const getKnowledgeBase = createAsyncThunk(
  "calender/getKnowledgeBase",
  async (data = null, { rejectWithValue }) => {
    const response = await calenderApi.getKnowledgeBase();
    if (response.data.status == "success") {
      return response.data.result;
    } else {
      return rejectWithValue({ error: response.data.error });
    }
  }
);

const calenderSlice = createSlice({
  name: "calender",
  initialState,

  extraReducers: (builder) => {
    //Login using email/pass
    builder.addCase(getCalenderData.pending, (state, action) => {
      state.calenderDataLoading = true;
      state.calenderData = null;
      state.calenderError = null;
    });
    builder.addCase(getCalenderData.rejected, (state, action) => {
      state.calenderDataLoading = false;
      state.calenderData = null;

      if (action.payload) {
        state.calenderError = action.payload;
      } else if (action.error) {
        state.calenderError = { error: action.error.message };
      }
    });
    builder.addCase(getCalenderData.fulfilled, (state, action) => {
      state.calenderDataLoading = false;
      state.calenderData = action.payload;
      state.calenderError = null;
    });

    //knowledgebase
    builder.addCase(getKnowledgeBase.pending, (state, action) => {
      state.knowledgeBaseLoading = true;
      state.knowledgeBaseData = null;
      state.calenderError = null;
    });
    builder.addCase(getKnowledgeBase.rejected, (state, action) => {
      state.knowledgeBaseLoading = false;
      state.knowledgeBaseData = null;

      if (action.payload) {
        state.calenderError = action.payload;
      } else if (action.error) {
        state.calenderError = { error: action.error.message };
      }
    });
    builder.addCase(getKnowledgeBase.fulfilled, (state, action) => {
      state.knowledgeBaseLoading = false;
      state.knowledgeBaseData = action.payload;
      state.calenderError = null;
    });
  },
});

export const { calender } = calenderSlice.actions;
export default calenderSlice.reducer;
